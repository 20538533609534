import React, { useEffect } from "react";
import {
    Button,
    Datagrid,
    FunctionField,
    List,
    ReferenceField,
    TextField,
    useListContext,
    useListFilterContext,
    useTranslate,
    useRefresh,
    useNotify,
    useUnselectAll,
    useUpdateMany,
    useResourceContext,
    useGetIdentity,
    BulkExportButton,
} from "react-admin";
import OutboxIcon from "@mui/icons-material/Outbox";

const DealUnstashButton = () => {
    const { selectedIds } = useListContext();
    const { identity } = useGetIdentity();
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(resource);
    const [updateMany, { isLoading }] = useUpdateMany(
        resource,
        { ids: selectedIds, data: { dealInput: { deal_stash: false, update_userid: identity?.id } } },
        {
            onSuccess: () => {
                refresh();
                notify("notification.unstash");
                unselectAll();
            },
            onError: error => notify("notification.unstashFail", { type: "warning" }),
        },
    );

    return (
        <Button label="common.unstash" disabled={isLoading} onClick={() => updateMany()}>
            <OutboxIcon />
        </Button>
    );
};

const DealStashListBulkActions = () => {
    return (
        <>
            <BulkExportButton />
            <DealUnstashButton />
        </>
    );
};

const ListContent = () => {
    const { data, isLoading, refetch, sort, setSort, perPage, setPerPage } = useListContext();
    const { filterValues, setFilters, displayedFilters } = useListFilterContext();
    useEffect(() => {
        if (isLoading) return;
        if (filterValues && !filterValues.deal_stash) {
            setFilters({ ...filterValues, deal_stash: true }, displayedFilters);
        }
        if (perPage === 500) {
            setPerPage(25);
        }
        if (sort.field === "deal_status_order") {
            setSort({
                field: "id",
                order: "DESC",
            });
        }
        refetch();
    }, [isLoading, filterValues, data]);
    if (isLoading || perPage === 500 || !filterValues.deal_stash || !data) return null;

    return (
        <Datagrid rowClick="show" bulkActionButtons={<DealStashListBulkActions />}>
            <TextField label="common.index" source="id" textAlign="center" sortable={false} />
            <ReferenceField label="crm.clientName" source="client_id" reference="crm/client" textAlign="center">
                <TextField source="client_name" />
            </ReferenceField>
            <TextField label="crm.dealName" source="deal_title" textAlign="center" />
            <FunctionField
                label="crm.crmStatus"
                source="deal_status"
                textAlign="center"
                render={(record: any) => record.deal_status.code_name}
            />
            <FunctionField
                label="crm.budget"
                source="budget"
                textAlign="center"
                render={(record: any) => {
                    return Number(record.budget).toLocaleString("ko-KR", {
                        notation: "compact",
                        style: "currency",
                        currency: "KRW",
                        currencyDisplay: "narrowSymbol",
                        minimumSignificantDigits: 3,
                    });
                }}
            />
        </Datagrid>
    );
};

export const DealStashList = ({ filters, actions, filterDefaultValues, perPage, pagination, exporter }: any) => {
    const translate = useTranslate();
    return (
        <List
            pagination={pagination}
            perPage={perPage}
            sort={{ field: "id", order: "DESC" }}
            filterDefaultValues={filterDefaultValues}
            filters={filters}
            actions={actions}
            title={translate("title.crm_deal")}
            exporter={exporter}
            empty={false}
        >
            <ListContent />
        </List>
    );
};
