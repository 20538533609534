import { Box } from "@mui/material";
import { useTranslate } from "react-admin";
import { TypographWithIcon } from "../TypographWithIcon";

export const ChartBox = ({ height = 500, titleIcon, titleText, chart }: any) => {
    const translate = useTranslate();
    return (
        <>
            <TypographWithIcon sx={{ padding: "0 10px" }} variant="h3" icon={titleIcon} text={translate(titleText)} />
            <Box sx={{ display: "flex", height, width: "99%", alignItems: "center", justifyContent: "center" }}>
                {chart}
            </Box>
        </>
    );
};
