import { CorpAdminAccountApprovalList } from "./accounts/CorpAdminAccountApprovalList";
import { CorpAdminAccountPaymentList } from "./accounts/CorpAdminAccountPaymentList";
import { CorpAdminAccountsList } from "./accounts/CorpAdminAccountsList";
import { CorpAdminBillsDetailList } from "./bills/CorpAdminBillsDetailList";
import { CorpAdminBillsList } from "./bills/CorpAdminBillsList";
import { CorpAdminNoticeCreate } from "./notice/CorpAdminNoticeCreate";
import { CorpAdminNoticeEdit } from "./notice/CorpAdminNoticeEdit";
import { CorpAdminNoticeList } from "./notice/CorpAdminNoticeList";
import { CorpAdminNoticeShow } from "./notice/CorpAdminNoticeShow";
import { CorpAdminQnaAnswerEdit } from "./qna/CorpAdminQnaAnswerEdit";
import { CorpAdminQnaCreate } from "./qna/CorpAdminQnaCreate";
import { CorpAdminQnaEdit } from "./qna/CorpAdminQnaEdit";
import { CorpAdminQnaList } from "./qna/CorpAdminQnaList";
import { CorpAdminQnaShow } from "./qna/CorpAdminQnaShow";

const getCorpAdminNoticeResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin_mgmt?.access && { create: CorpAdminNoticeCreate, edit: CorpAdminNoticeEdit }),
        list: CorpAdminNoticeList,
        show: CorpAdminNoticeShow,
    };
};

const getCorpAdminQnaResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin?.access && {
            create: CorpAdminQnaCreate,
            edit: CorpAdminQnaEdit,
        }),
        ...(!!permissions.corp_admin_mgmt?.access && { edit: CorpAdminQnaAnswerEdit }),
        list: CorpAdminQnaList,
        show: CorpAdminQnaShow,
    };
};

const getCorpAdminAccountsResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin?.access && { list: CorpAdminAccountsList }),
    };
};

const getCorpAdminAccountPaymentResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin?.access && { list: CorpAdminAccountPaymentList }),
    };
};

const getCorpAdminAccountApprovalResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin?.access && { list: CorpAdminAccountApprovalList }),
    };
};

const getCorpAdminBillsResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin?.access && { list: CorpAdminBillsList }),
    };
};

const getCorpAdminBillsDetailResourceProps = (permissions: any) => {
    return {
        ...(!!permissions.corp_admin?.access && { list: CorpAdminBillsDetailList }),
    };
};

export {
    getCorpAdminNoticeResourceProps,
    getCorpAdminQnaResourceProps,
    getCorpAdminAccountsResourceProps,
    getCorpAdminAccountPaymentResourceProps,
    getCorpAdminAccountApprovalResourceProps,
    getCorpAdminBillsResourceProps,
    getCorpAdminBillsDetailResourceProps,
};
