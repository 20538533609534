import { useMediaQuery, Theme, DialogContent, Dialog, Box, Grid, TextField } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import {
    List,
    TextField as RaTextField,
    TopToolbar,
    Datagrid,
    useTranslate,
    FilterButton,
    SimpleList,
    Button,
    useRedirect,
    SelectInput,
} from "react-admin";
import { TypographWithIcon } from "@src/components/TypographWithIcon";
import { useState } from "react";

const noticeFilters = [
    <SelectInput
        source="roaming"
        label="충전내역 구분"
        // TODO, 기본값 에버온 충전내역
        // emptyValue={}
        choices={[
            { id: "everon", name: "에버온" },
            { id: "roaming", name: "로밍" },
        ]}
        // defaultValue={"Y"}
        alwaysOn
    />,
    // TODO, 날짜 조회 필터 추가 필요
];

const ListActions = () => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <Button
                label={"title.corp_admin_accounts_list"}
                children={<PeopleIcon />}
                onClick={() => {
                    redirect("/corp_admin/accounts");
                }}
            />
            <FilterButton />
        </TopToolbar>
    );
};

export const CorpAdminAccountPaymentList = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <List
                title={translate("title.corp_admin_account_payment", { name: "아무개" })}
                filters={noticeFilters}
                sort={{ field: "id", order: "DESC" }}
                actions={<ListActions />}
                empty={false}
                // defaultFilter={{ user_id: "" }}
            >
                {isXSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.writer}
                        tertiaryText={record => new Date(+record.create_at).toISOString()}
                        linkType="show"
                    />
                ) : (
                    <Datagrid
                        rowClick={(e: any) => {
                            setOpen(true);
                            return "";
                        }}
                        bulkActionButtons={false}
                    >
                        <RaTextField source="id" label={"common.idx"} sortable={false} textAlign="center" />
                        <RaTextField
                            source="create_userid"
                            label={"common.payment_date"}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={"common.reservation_number"}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField source="id" label={"common.station_name"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.station_id"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.status"} sortable={false} textAlign="center" />
                        <RaTextField
                            source="id"
                            label={"common.charge_amount_kw"}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField source="id" label={"common.charge_fee_won"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.usage_fee_won"} sortable={false} textAlign="center" />
                        <RaTextField
                            source="id"
                            label={"common.cancellation_fee"}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField source="id" label={"common.payment_card"} sortable={false} textAlign="center" />
                    </Datagrid>
                )}
            </List>
            {/* 상세 이용/결제 정보 */}
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                sx={{
                    ".MuiDialogContent-root": {
                        padding: 0,
                    },
                }}
            >
                <DialogContent>
                    <DetailUsagePopup handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    );
};

const DetailUsagePopup = ({ handleClose }: any) => {
    const translate = useTranslate();

    return (
        <Box sx={{ display: "flex", flexDirection: "column", padding: 4 }}>
            <TypographWithIcon
                variant="h3"
                icon={<BatteryChargingFullIcon color="disabled" />}
                text={translate("corp_admin.charge_history_details")}
            />
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.reservation_date")}
                        name="name"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.usage_date")}
                        name="department"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.reservation_time_total")}
                        name="employee_number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.usage_time_total")}
                        name="email"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.station_name")}
                        name="employee_number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.charge_date")}
                        name="email"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.charge_amount")}
                        name="employee_number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.charge_time_total")}
                        name="email"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.charge_fee")}
                        name="employee_number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.cancellation_fee")}
                        name="email"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={1}>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.usage_fee")}
                        name="employee_number"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField
                        fullWidth
                        label={translate("common.real_fee")}
                        name="email"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
