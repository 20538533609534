import EvStationIcon from "@mui/icons-material/EvStation";
import { ChartBox } from "@src/components/charts/ChartBox";
import { BarChart } from "@src/components/charts/BarChart";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { useDataProvider } from "react-admin";
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

export const ChargerQuantityChart = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data } = await dataProvider.callExternalApi("/analytics/chargerQuantity", null, "GET");
            const annualData = data
                .filter((_: any, i: number) => i >= data.length - 12)
                .map(({ yymm, ac_over_cnt, dc_over_cnt }: any) => {
                    const year = yymm.substring(2, 4);
                    const month = yymm.substring(4);
                    const date = `${year}-${month}`;

                    return {
                        date,
                        완속: ac_over_cnt,
                        급속: dc_over_cnt,
                    };
                });
            setData(annualData);
        };
        setChartData();
    }, []);

    return (
        <ChartBox
            titleIcon={<EvStationIcon color="disabled" fontSize="large" />}
            titleText="ceo.charger_quantity"
            chart={
                data.length > 0 ? (
                    <BarChart
                        data={data}
                        keys={["급속", "완속"]}
                        colors={["#e8a838", "#f1e15b"]}
                        axisRight={{
                            format: (v: any) => `${Math.abs(v / 1000)}k`,
                            tickValues: 8,
                        }}
                        axisBottom={{
                            legendPosition: "middle",
                            legendOffset: 50,
                            tickSize: 0,
                            tickPadding: 12,
                            tickRotation: 40,
                        }}
                        tooltip={({ id, value, color }: any) => {
                            return <ChartTooltip id={id} color={color} content={`${value.toLocaleString()}대`} />;
                        }}
                    />
                ) : (
                    <CircularProgress />
                )
            }
        />
    );
};
