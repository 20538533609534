/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import { AppBar, UserMenu, useRedirect, ToggleThemeButton, useTheme, Logout, usePermissions } from "react-admin";
import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
// import SettingsIcon from "@mui/icons-material/Settings";
import { darkTheme, lightTheme } from "./themes";
import { BookmarkMenu } from "./menu/everon/BookmarkMenu";

// const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
//     const translate = useTranslate();
//     return (
//         <MenuItemLink
//             ref={ref}
//             to="/configuration"
//             primaryText={translate("common.configuration")}
//             leftIcon={<SettingsIcon />}
//             onClick={props.onClick}
//             sidebarIsOpen
//         />
//     );
// });

const CustomUserMenu = () => {
    const { isLoading, permissions } = usePermissions();

    if (isLoading) return null;

    return (
        <UserMenu>
            {/* <ConfigurationMenu /> */}
            {/* {permissions.groupware?.access && <BookmarkMenu />} */}
            <Logout />
        </UserMenu>
    );
};

const CustomAppBar = () => {
    const redirect = useRedirect();
    const [theme] = useTheme();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

    return (
        <AppBar elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h4"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
                id="react-admin-title"
            />
            {!isXSmall && (
                <>
                    <Box
                        component="img"
                        src={theme?.palette?.mode === "dark" ? "/assets/dark_logo.png" : "/assets/light_logo.png"}
                        style={{
                            height: 20,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            redirect("/");
                        }}
                    />
                    <span style={{ margin: "0 1em" }} />
                </>
            )}
            <ToggleThemeButton lightTheme={lightTheme} darkTheme={darkTheme} />
        </AppBar>
    );
};

export default CustomAppBar;
