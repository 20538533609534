import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Theme, useMediaQuery, Typography, Paper } from "@mui/material";
import { RichTextInputWithImageAttachment } from "@src/components/inputs/RichTextInputWithImageAttachment";
import { Form, required, SelectInput, TextInput, Toolbar, useDataProvider, useRecordContext } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)({
    padding: "1rem 1rem 0.5rem 1rem",
    fontSize: "1.5rem",
    color: "#005caa",
});

const StyledPaper = styled(Paper)({
    padding: "1rem",
});

export const VocInputs = ({ handleSubmit }: any) => {
    const record = useRecordContext();
    const [customerName, setCustomerName] = useState(record?.customerName ? record.customerName : "");
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    return (
        <Box display="flex">
            <Box flex="1" mb={isSmall ? "80px" : 0}>
                <Form onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <StyledTypography>고객 정보</StyledTypography>
                            <StyledPaper>
                                <Grid container columnSpacing={{ sm: 0, md: 2 }}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <CustomerTypeSelectInput />
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={{ sm: 0, md: 2 }}>
                                    <Grid item xs={8} sm={3} md={2}>
                                        <TextInput
                                            source="customerName"
                                            label="voc.customerName"
                                            validate={[required()]}
                                            onChange={e => setCustomerName(e.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={2} md={2} pl={isSmall ? "1rem" : 0}>
                                        <CustomerInboundCountTextInput customerName={customerName} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <TextInput source="inboundContact" label="common.contact" fullWidth />
                                    </Grid>
                                </Grid>
                            </StyledPaper>
                            <StyledTypography>VOC 정보</StyledTypography>
                            <StyledPaper>
                                <Grid container columnSpacing={{ sm: 0, md: 2 }}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <VocInboundChannel1SelectInput />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <VocInboundChannel2SelectInput />
                                    </Grid>
                                </Grid>
                                <Grid container columnSpacing={{ sm: 0, md: 2 }}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <VocRequestCategory1SelectInput />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <VocRequestCategory2SelectInput />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <VocRequestCategory3SelectInput />
                                    </Grid>
                                </Grid>
                            </StyledPaper>
                            <StyledTypography>VOC 상세</StyledTypography>
                            <StyledPaper>
                                <Grid container columnSpacing={{ sm: 0, md: 2 }}>
                                    <Grid item xs={12} sm={10} md={6}>
                                        <TextInput
                                            source="requestTitle"
                                            label="common.title"
                                            validate={[required()]}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                                <Box width="100%">
                                    <RichTextInputWithImageAttachment
                                        label="voc.requestDescription"
                                        name="requestDescription"
                                        source="requestDescription"
                                        validate={[required()]}
                                        setEditor={null}
                                        handleChange={null}
                                    />
                                </Box>
                            </StyledPaper>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const CustomerInboundCountTextInput = ({ customerName }: { customerName: string }) => {
    const dataProvider = useDataProvider();
    const [inboundCnt, setInboundCnt] = useState<number>(0);
    const { setValue } = useFormContext();

    useEffect(() => {
        const getInboundCnt = async () => {
            if (customerName === "") {
                setInboundCnt(0);
                return;
            }
            const { data } = await dataProvider.getList("voc", {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: "id", order: "ASC" },
                filter: { customerName: customerName },
            });
            setInboundCnt(data.length);
            setValue("customerInboundCount", data.length);
        };
        getInboundCnt();
    }, [customerName]);

    return (
        <TextInput
            source="customerInboundCount"
            label="voc.customerInboundCnt"
            sx={{ width: "80px", display: "flex", textAlign: "center" }}
            disabled
            fullWidth
            value={inboundCnt}
        />
    );
};

const CustomerTypeSelectInput = () => {
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: "VOC_CUSTOMER_TYPE" },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    return (
        <SelectInput
            defaultValue=""
            label={"voc.customerType"}
            source="customerType"
            choices={choices}
            validate={[required()]}
            sx={{ mt: 0, display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};

const VocInboundChannel1SelectInput = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);
    const { setValue } = useFormContext();
    const [curOption, setCurOption] = useState<string>(record ? record.requestCategory2 : "");

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: "VOC_INBOUND_CHANNEL" },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    return (
        <SelectInput
            defaultValue=""
            label={"voc.inboundChannel1"}
            source={"inboundChannelCategory1"}
            choices={choices}
            validate={[required()]}
            sx={{ mt: 0, display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
            onChange={(event: any) => {
                if (event.target.value !== curOption) {
                    setValue("inboundChannelCategory2", "");
                    setCurOption(event.target.value);
                }
            }}
        />
    );
};

const VocInboundChannel2SelectInput = () => {
    const category1 = useWatch({ name: "inboundChannelCategory1" });
    let isDisabled = true;
    if (category1 != null && category1 != "") isDisabled = false;

    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const initChoices = async () => {
            if (isDisabled) return;
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: typeof category1 === "string" ? category1 : category1.code },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, [isDisabled, category1]);

    return (
        <SelectInput
            defaultValue=""
            label={"voc.inboundChannel2"}
            source="inboundChannelCategory2"
            choices={choices}
            validate={[required()]}
            disabled={isDisabled}
            sx={{ mt: 0, display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};

const VocRequestCategory1SelectInput = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);
    const [curOption, setCurOption] = useState<string>(record ? record.requestCategory1 : "");
    const { setValue } = useFormContext();

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: "VOC_REQUEST_TYPE" },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    return (
        <SelectInput
            defaultValue=""
            label={"voc.requestCategory1"}
            source="requestCategory1"
            choices={choices}
            validate={[required()]}
            sx={{ mt: 0, display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
            onChange={(event: any) => {
                if (event.target.value !== curOption) {
                    setValue("requestCategory2", "");
                    setValue("requestCategory3", "");
                    setCurOption(event.target.value);
                }
            }}
        />
    );
};

const VocRequestCategory2SelectInput = () => {
    const record = useRecordContext();
    const category1 = useWatch({ name: "requestCategory1" });
    const { setValue } = useFormContext();
    const dataProvider = useDataProvider();
    const [curOption, setCurOption] = useState<string>(record ? record.requestCategory2 : "");
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);
    let isDisabled = true;
    if (category1 && category1 != "") isDisabled = false;

    useEffect(() => {
        if (isDisabled) return;
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: typeof category1 === "string" ? category1 : category1.code },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, [isDisabled, category1]);

    return (
        <SelectInput
            defaultValue=""
            label={"voc.requestCategory2"}
            source="requestCategory2"
            choices={choices}
            validate={[required()]}
            disabled={isDisabled}
            sx={{ mt: 0, display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
            onChange={(event: any) => {
                if (event.target.value !== curOption) {
                    setValue("requestCategory3", "");
                    setCurOption(event.target.value);
                }
            }}
        />
    );
};

const VocRequestCategory3SelectInput = () => {
    const category1 = useWatch({ name: "requestCategory1" });
    const category2 = useWatch({ name: "requestCategory2" });
    let isDisabled = true;
    if (category1 && category1 != "" && category2 && category2 != "") isDisabled = false;

    const dataProvider = useDataProvider();
    const [choices, setChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        if (isDisabled) return;
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: typeof category2 === "string" ? category2 : category2.code },
            });
            setChoices(
                data.map(item => {
                    return {
                        id: item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, [isDisabled, category1, category2]);

    return (
        <SelectInput
            defaultValue=""
            label={"voc.requestCategory3"}
            source="requestCategory3"
            choices={choices}
            validate={[required()]}
            disabled={isDisabled}
            sx={{ mt: 0, display: "flex" }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};
