import { Grid } from "@mui/material";
import { Title, useTranslate } from "react-admin";
// import { DealsChart } from "./DealsChart";
import { ChargeAmountsChart } from "./ChargeAmountsChart";
import { IncomeChart } from "./IncomeChart";
import { ChargerStatusChart } from "./ChargerStatusChart";
import { ChargerQuantityChart } from "./ChargerQuantityChart";
import { CorpChargeAmountChart } from "./CorpChargeAmountChart";
import { AccountsChart } from "./AccountsChart";
import { ChargerManufacturerList } from "./ChargerManufacturerList";

const CeoScreen = () => {
    const translate = useTranslate();
    return (
        <>
            <Title title={translate("title.ceo_screen")} />
            <Grid container spacing={4} mt={1}>
                {/* 월별 거래 통계 */}
                {/* <Grid item xs={12} md={6} lg={3}>
                    <DealsChart />
                </Grid> */}
                {/* 월별 충전량 */}
                <Grid item xs={12} md={6}>
                    <ChargeAmountsChart />
                </Grid>
                {/* 월별 충전 금액 */}
                <Grid item xs={12} md={6}>
                    <IncomeChart />
                </Grid>
                {/* 충전기 수 */}
                <Grid item xs={12} md={6}>
                    <ChargerQuantityChart />
                </Grid>
                {/* 충전기 상태 */}
                <Grid item xs={12} md={6}>
                    <ChargerStatusChart />
                </Grid>
                {/* 회원 현황 */}
                <Grid item xs={12} md={6}>
                    <AccountsChart />
                </Grid>
                {/* 법인별 충전량 */}
                <Grid item xs={12} md={6}>
                    <CorpChargeAmountChart />
                </Grid>
                {/* 제조사별 충전기 */}
                <Grid item xs={12} lg={12}>
                    <ChargerManufacturerList />
                </Grid>
            </Grid>
        </>
    );
};

export default CeoScreen;
