import { useKeycloak } from "@react-keycloak/web";
import jwt_decode from "jwt-decode";
import setLocales from "./configuration/setLocales";

let roles: any = null;

export const useAuthProvider = () => {
    const { keycloak } = useKeycloak();
    const { translate } = setLocales;
    return {
        login: () => keycloak.login(),
        checkError: () => Promise.resolve(),
        checkAuth: () => {
            return keycloak.authenticated && keycloak.token
                ? Promise.resolve()
                : Promise.reject(translate("authProvider.check_auth"));
        },
        logout: () => {
            return keycloak.logout();
        },
        getIdentity: () => {
            if (keycloak.token) {
                const decoded: any = jwt_decode(keycloak.token);
                const id = decoded.preferred_username.split("@")[0];
                const fullName = decoded.name;
                return Promise.resolve({ id, fullName });
            }
            return Promise.reject(translate("authProvider.getIdentity"));
        },
        getPermissions: () => {
            if (roles === null && keycloak.token) {
                roles = {};
                const decoded: any = jwt_decode(keycloak.token);
                decoded.realm_access.roles.forEach((role: string) => {
                    const delimiterIdx = role.lastIndexOf("_");
                    if (!role.startsWith("_") && delimiterIdx !== -1) {
                        const view = role.substring(0, delimiterIdx);
                        const action = role.substring(delimiterIdx + 1);
                        if (roles[view] == null) {
                            roles[view] = {};
                        }
                        roles[view][action] = true;
                    } else if (role === "admin") {
                        roles.admin = true;
                    }
                });
            }
            if (roles !== null) {
                return Promise.resolve(roles);
            }
            return Promise.reject();
        },
    };
};
