import { Box, Card, CardHeader, CardContent, Typography } from "@mui/material";
import { useListContext, RecordContextProvider, useTranslate } from "react-admin";
import { ExternalLinkField } from "@src/components/fields";
import { BookmarkField } from "@src/components/fields/BookmarkField";
import { format } from "date-fns";

const MobileBookmarkGrid = () => {
    const { data, isLoading } = useListContext<any>();
    const translate = useTranslate();

    if (isLoading || !data || data?.length === 0) {
        return null;
    }
    return (
        <Box margin="0.5em">
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Card sx={{ margin: "0.5rem 0" }}>
                        <CardHeader
                            title={
                                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <ExternalLinkField label="common.title" source="title" textAlign="center" />
                                    <span style={{ margin: "0 5px" }} />
                                    <BookmarkField />
                                </Box>
                            }
                            titleTypographyProps={{ variant: "body1" }}
                        />
                        <CardContent sx={{ pt: 0 }}>
                            <Typography variant="body2">
                                {translate(`common.date`)}
                                {": "}
                                {format(new Date(+record.create_at), "yyyy-MM-dd")}
                            </Typography>
                        </CardContent>
                    </Card>
                </RecordContextProvider>
            ))}
        </Box>
    );
};

MobileBookmarkGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileBookmarkGrid;
