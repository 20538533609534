import React, { useState } from "react";
import {
    TextInput,
    SelectInput,
    useGetList,
    required,
    Form,
    Toolbar,
    useRecordContext,
    FileInput,
    FileField,
    RaRecord,
} from "react-admin";
import { Box, Card, CardContent, Theme, useMediaQuery } from "@mui/material";
import { ClientAvatar } from "./ClientAvatar";
import { KakaoAddressInput } from "@components/kakao/KakaoComponent";
import { useWatch } from "react-hook-form";
import { isObject } from "lodash";
import { dataURLtoFile } from "@src/utils/file";

export const ClientInputs = () => {
    const record = useRecordContext();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [clientType, setClientType] = useState(record && record.client_type ? record.client_type.code : "");

    const handleSelect = (type: string) => {
        setClientType(type);
    };

    return (
        <Box display="flex">
            <Box flex="1" mb={isSmall ? "80px" : 0}>
                <Form>
                    <Card>
                        <CardContent>
                            {isSmall && (
                                <Box flex="1" mt={1} mb={2}>
                                    <ClientAvatarInInputs clientType={clientType} />
                                </Box>
                            )}
                            <Box display="flex">
                                {!isSmall && (
                                    <Box mr={2}>
                                        <ClientAvatarInInputs clientType={clientType} />
                                    </Box>
                                )}
                                <Box flex="1" mt={-1}>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <ClientTypeSelectInput onSelect={handleSelect} />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <TextInput
                                            label="crm.clientName"
                                            validate={[required()]}
                                            source="client_name"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <KakaoAddressInput />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <TextInput label={"common.addressDetail"} source="address_detail" fullWidth />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <TextInput label={"common.tel_number"} source="tel_number" fullWidth />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <TextInput label={"common.bigo_note"} source="bigo_note" fullWidth />
                                    </Box>
                                    <Box width={isSmall ? "95%" : 430}>
                                        <FileInput
                                            source="thumbnail"
                                            label="crm.addLogo"
                                            accept="image/*"
                                            maxSize={5000000}
                                            labelSingle="ra.input.image.upload_single"
                                            format={(data: any) => {
                                                // record -> input
                                                // https://marmelab.com/react-admin/Inputs.html#transforming-input-value-tofrom-record
                                                if (!data) return;
                                                if (isObject(data)) {
                                                    return data;
                                                } else {
                                                    const { filename, base64 } = JSON.parse(data);
                                                    const file = dataURLtoFile(base64, filename);
                                                    return {
                                                        rawFile: file,
                                                        src: URL.createObjectURL(file),
                                                        title: filename,
                                                    };
                                                }
                                            }}
                                        >
                                            <FileField source="src" title="title" target="_blank" />
                                        </FileInput>
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};

const ClientAvatarInInputs = ({ clientType }: { clientType: string }) => {
    const { thumbnail }: any = useWatch();
    return <ClientAvatar isInput clientType={clientType} watch={thumbnail} />;
};

const ClientTypeSelectInput = ({ onSelect }: any): any => {
    const { data, isLoading } = useGetList("CommonCode", {
        sort: { field: "codeOrder", order: "ASC" },
        filter: { parentCode: "CRM_CLIENT_TYPE" },
    });
    if (isLoading || !data) return null;
    const clientChoices = data.map(item => {
        return {
            id: item.code,
            name: item.code_name,
        };
    });

    return (
        <SelectInput
            label={"crm.clientType"}
            source="client_type"
            choices={clientChoices}
            validate={[required()]}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement> | RaRecord) => {
                onSelect(event.target.value);
            }}
            format={(code: string | { code: string }) => {
                if (typeof code === "string") {
                    return code;
                }
                return code.code;
            }}
        />
    );
};
