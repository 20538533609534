import * as React from "react";
import { useListContext, usePermissions } from "react-admin";
import { Box } from "@mui/material";

import { Note, permissionMapping } from "./Note";
import { NewNote } from "./NewNote";

export type NoteReferences = "crm/contact" | "crm/deal" | "voc";

export const NotesIterator = ({
    showStatus,
    statusTooltip,
    reference,
}: {
    showStatus?: boolean;
    statusTooltip?: string;
    reference: NoteReferences;
}) => {
    const { data, isLoading, refetch } = useListContext();
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (isLoadingForPemission) return null;
    if (isLoading) return null;
    return (
        <>
            {permissions[permissionMapping[reference]]?.create && (
                <NewNote showStatus={showStatus} reference={reference} />
            )}
            <Box mt="0.5em">
                {data.map((record, index) => (
                    <Note
                        key={index}
                        record={record}
                        showStatus={showStatus}
                        refetch={refetch}
                        statusTooltip={statusTooltip}
                        reference={reference}
                    />
                ))}
            </Box>
        </>
    );
};
