import * as React from "react";
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    useListContext,
    ExportButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    useTranslate,
    RecordContextProvider,
    FilterButton,
    TextInput,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    SelectInput,
    useGetList,
    usePermissions,
} from "react-admin";
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    useMediaQuery,
    Theme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ContactAvatar } from "./ContactAvatar";
import { Status } from "@src/components/notes/Status";
import { CrmContact } from "../types";
import { crmContactExporter } from "@src/utils/exporters";

const ContactListContent = () => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const translate = useTranslate();
    const { data: contacts, isLoading, onToggleItem, selectedIds } = useListContext<CrmContact>();
    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    if (contacts.length === 0) return null;
    return (
        <>
            <BulkActionsToolbar>
                <BulkDeleteButton />
                <BulkExportButton />
            </BulkActionsToolbar>
            <List sx={contacts.length === 0 ? { border: "none" } : {}}>
                {contacts.map(contact => {
                    return (
                        <RecordContextProvider value={contact} key={contact.id}>
                            <ListItem button component={Link} to={`/crm/contact/${contact.id}/show`}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selectedIds.includes(contact.id)}
                                        tabIndex={-1}
                                        disableRipple
                                        onClick={e => {
                                            e.stopPropagation();
                                            onToggleItem(contact.id);
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemAvatar>
                                    <ContactAvatar />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={contact.contact_name}
                                    secondary={
                                        <>
                                            {contact.job_title} at{" "}
                                            <ReferenceField source="client_id" reference="crm/client" link={false}>
                                                <TextField source="client_name" />
                                            </ReferenceField>
                                            {contact.nb_notes && `- ${contact.nb_notes} notes `}
                                        </>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {contact.hasNote && (
                                        <Typography variant="body2" color="textSecondary">
                                            {isDesktop && translate("crm.last_emotion_status")}{" "}
                                            <Status
                                                status={contact.last_emotion_status.code}
                                                statusTooltip="crmContact"
                                            />
                                        </Typography>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                        </RecordContextProvider>
                    );
                })}
            </List>
        </>
    );
};

const ContactListActions = () => {
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (isLoadingForPemission) return null;
    return (
        <TopToolbar>
            <FilterButton />
            <ExportButton />
            {permissions.crm?.create && (
                <CreateButton variant="contained" label={"crm.addContact"} sx={{ marginLeft: 2 }} />
            )}
        </TopToolbar>
    );
};

const StatusSelectInput = ({ label, source }: any) => {
    const { data, isLoading } = useGetList("CommonCode", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "codeOrder", order: "ASC" },
        filter: { parentCode: "NOTE_STATUS" },
    });
    if (isLoading || !data) return null;
    const choices = data.map(item => {
        return { id: item.parent_code + "." + item.code, name: item.code_name };
    });
    return <SelectInput label={label} source={source} choices={choices} />;
};

const defaultFilters = [
    <TextInput label="common.name" source="contact_name" alwaysOn />,
    <StatusSelectInput source="last_emotion_status" label="crm.last_emotion_status" />,
];

export const ContactList = () => {
    const { identity } = useGetIdentity();
    const translate = useTranslate();
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (isLoadingForPemission || !permissions.crm?.access) return null;
    return identity ? (
        <RaList
            actions={<ContactListActions />}
            perPage={25}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            filters={defaultFilters}
            sort={{ field: "create_at", order: "DESC" }}
            title={translate("title.crm_contact")}
            exporter={crmContactExporter}
            empty={false}
        >
            <ContactListContent />
        </RaList>
    ) : null;
};
