import React, { useState } from "react";
import {
    Button,
    CreateButton,
    ExportButton,
    FilterButton,
    TextInput,
    TopToolbar,
    useGetIdentity,
    Pagination,
    useRemoveFromStore,
    usePermissions,
} from "react-admin";
import { DealCreate } from "./DealCreate";
import { DealShow } from "./DealShow";
import { OnlyMineInput } from "./OnlyMineInput";
import { matchPath } from "react-router";
import { useLocation } from "react-router";
import { DealEdit } from "./DealEdit";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { DealStashList } from "./DealStashList";
import { DealCardList } from "./DealCardList";
import { crmDealExporter } from "@src/utils/exporters";

export const DealList = () => {
    const [isCardList, setIsCardList] = useState(true);
    const { identity } = useGetIdentity();
    const location = useLocation();
    const matchCreate = matchPath("/crm/deal/create", location.pathname);
    const matchEdit = matchPath("/crm/deal/:id", location.pathname);
    const matchShow = matchPath("/crm/deal/:id/show", location.pathname);
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (!identity) return null;
    if (isLoadingForPemission || !permissions.crm?.access) return null;
    return (
        <>
            {isCardList ? (
                <DealCardList
                    pagination={false}
                    perPage={isCardList ? 500 : 25}
                    filters={filters}
                    filterDefaultValues={{ deal_stash: !isCardList }}
                    actions={<DealListActions isCardList={isCardList} setIsCardList={setIsCardList} />}
                    exporter={crmDealExporter}
                />
            ) : (
                <DealStashList
                    pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
                    perPage={isCardList ? 500 : 25}
                    filters={filters}
                    filterDefaultValues={{ deal_stash: !isCardList }}
                    actions={<DealListActions isCardList={isCardList} setIsCardList={setIsCardList} />}
                    exporter={crmDealExporter}
                />
            )}
            <DealCreate open={!!matchCreate} />
            <DealEdit open={!!matchEdit} id={matchEdit?.params.id} />
            <DealShow open={!!matchShow} id={matchShow?.params.id} />
        </>
    );
};

const DealListActions = ({
    isCardList,
    setIsCardList,
}: {
    isCardList: boolean;
    setIsCardList: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    const removeKey = useRemoveFromStore();
    if (isLoadingForPemission) return null;
    return (
        <TopToolbar>
            <FilterButton />
            <ExportButton />
            <Button
                label={isCardList ? "common.stashList" : "crm.dealCardList"}
                children={isCardList ? <ListIcon /> : <ViewComfyIcon />}
                onClick={() => {
                    removeKey("CrmDeal.listParams");
                    setIsCardList(!isCardList);
                }}
            />
            {permissions.crm?.create && <CreateButton variant="contained" label="crm.addDeal" sx={{ marginLeft: 2 }} />}
        </TopToolbar>
    );
};

const filters = [
    <TextInput label="crm.dealName" source="deal_title" alwaysOn />,
    <OnlyMineInput label="crm.seeOnlyMine" />,
];
