import * as React from "react";
import { useState, useContext } from "react";
import {
    Identifier,
    useListContext,
    DataProviderContext,
    useGetIdentity,
    useDeepCompareEffect,
    useListFilterContext,
} from "react-admin";
import { Box } from "@mui/material";
import { DragDropContext, OnDragEndResponder } from "react-beautiful-dnd";
import { Voc } from "./types";
import { VocCard } from "./VocCard";
import { CardColumn } from "@src/components/dnd/CardColumn";
import { CommonCodeDesc } from "@src/types";

export interface RecordMap {
    [id: number]: Voc;
    [id: string]: Voc;
}
interface VocByColumn {
    [vocStatus: string]: Identifier[];
}

const getInitialVoc: (vocStatus: CommonCodeDesc[]) => VocByColumn = vocStatus => {
    return vocStatus.reduce((obj, { parentCode, code }) => ({ ...obj, [`${parentCode}.${code}`]: [] }), {});
};

const getVocByColumn = (vocStatus: CommonCodeDesc[], data: Voc[]): VocByColumn => {
    // group vocs by column
    const columns = data.reduce(
        (acc, record) => {
            const status = `${record.vocStatus.parentCode}.${record.vocStatus.code}`;
            acc[status].push(record);
            return acc;
        },
        vocStatus.reduce((obj, { parentCode, code }) => ({ ...obj, [`${parentCode}.${code}`]: [] }), {} as any),
    );
    vocStatus.forEach(({ parentCode, code }) => {
        const status = `${parentCode}.${code}`;
        columns[status] = columns[status].map((voc: Voc) => voc.id);
    });
    return columns;
};

const indexById = (data: Voc[]): RecordMap => data.reduce((obj, record) => ({ ...obj, [record.id]: record }), {});

export const VocCardListContent = ({ vocStatus, setShowInfo }: { vocStatus: CommonCodeDesc[]; setShowInfo: any }) => {
    const { data: unorderedVoc, isLoading, refetch, perPage, setPerPage } = useListContext<Voc>();
    const { filterValues, setFilters, displayedFilters } = useListFilterContext();
    const [data, setData] = useState<RecordMap>(isLoading ? {} : indexById(unorderedVoc));
    const [vocs, setVoc] = useState<VocByColumn>(
        isLoading ? getInitialVoc(vocStatus) : getVocByColumn(vocStatus, unorderedVoc),
    );
    // we use the raw dataProvider to avoid too many updates to the Redux store after updates (which would create junk)
    const dataProvider = useContext(DataProviderContext);
    const { identity } = useGetIdentity();
    // update vocs by columns when the dataProvider response updates
    useDeepCompareEffect(() => {
        if (isLoading) return;
        if (filterValues && filterValues.vocStash) {
            setFilters({ ...filterValues, vocStash: false }, displayedFilters);
        }
        if (![20, 40, 60, 80, 100].includes(perPage)) {
            setPerPage(20);
        }
        const notStashedVoc = unorderedVoc.filter(item => item.vocStash == false);
        setVoc(getVocByColumn(vocStatus, notStashedVoc));
        setData(indexById(notStashedVoc));
    }, [vocStatus, perPage, unorderedVoc, isLoading]);

    if (isLoading || ![20, 40, 60, 80, 100].includes(perPage) || filterValues.vocStash) return null;

    const onDragEnd: OnDragEndResponder = async result => {
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // moving voc inside the same column
            const column = Array.from(vocs[source.droppableId]); // [4, 7, 23, 5] array of ids
            const sourceVoc = data[column[source.index]];
            dataProvider
                .update("voc", {
                    id: sourceVoc.id,
                    data: {
                        vocStatus: source.droppableId,
                    },
                    previousData: sourceVoc,
                })
                .then(() => {
                    refetch();
                });
        } else {
            // moving voc across columns
            const sourceColumn = Array.from(vocs[source.droppableId]);
            const destinationColumn = Array.from(vocs[destination.droppableId]);
            const sourceVoc = data[sourceColumn[source.index]];

            // update local state
            sourceColumn.splice(source.index, 1);
            destinationColumn.splice(destination.index, 0, draggableId);
            setVoc({
                ...vocs,
                [source.droppableId]: sourceColumn,
                [destination.droppableId]: destinationColumn,
            });

            // update backend
            // 신규 문의로 이동 시 부서 설정 초기화
            if (destination.droppableId === "VOC_STATUS.VOC_STATUS_NEW") {
                dataProvider
                    .update("voc", {
                        id: sourceVoc?.id,
                        data: {
                            responseDepartment: "VOC_ASSIGN_DEPT.DEPT_00",
                            vocStatus: "VOC_STATUS.VOC_STATUS_NEW",
                            updateUserid: `${identity?.id}(${identity?.fullName})`,
                        },
                        previousData: sourceVoc,
                    })
                    .then(() => {
                        refetch();
                    });
            } else {
                dataProvider
                    .update("voc", {
                        id: sourceVoc.id,
                        data: {
                            vocStatus: destination.droppableId,
                        },
                        previousData: sourceVoc,
                    })
                    .then(() => {
                        refetch();
                    });
            }
        }
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box display="flex">
                {vocStatus.map(({ parentCode, code, codeDescription }) => (
                    <CardColumn
                        title={codeDescription}
                        status={`${parentCode}.${code}`}
                        ids={vocs[`${parentCode}.${code}`]}
                        data={data}
                        key={`${parentCode}.${code}`}
                        card={<VocCard />}
                        setShowInfo={setShowInfo}
                    />
                ))}
            </Box>
        </DragDropContext>
    );
};
