import * as React from "react";
import {
    ShowBase,
    TextField,
    ReferenceField,
    ReferenceManyField,
    useRecordContext,
    useRedirect,
    Identifier,
    useTranslate,
    IconButtonWithTooltip,
    useGetIdentity,
    useNotify,
    useDelete,
    useResourceContext,
    FunctionField,
    useUpdate,
    RaRecord,
    usePermissions,
} from "react-admin";
import { Box, Dialog, DialogContent, Typography, Divider, useMediaQuery, Theme, Grid } from "@mui/material";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

import { NotesIterator } from "@src/components/notes";
import EditIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import OutboxIcon from "@mui/icons-material/Outbox";
import { ClientAvatar } from "../clients/ClientAvatar";
import { ContactList } from "./ContactList";
import { CrmClient } from "../types";

export const DealShow = ({ open, id }: { open: boolean; id?: Identifier }) => {
    const redirect = useRedirect();
    const handleClose = () => {
        redirect("/crm/deal");
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            sx={{
                ".MuiDialog-paper": {
                    position: "absolute",
                },
            }}
        >
            <DialogContent>
                {!!id ? (
                    <ShowBase id={id}>
                        <DealShowContent id={id} />
                    </ShowBase>
                ) : null}
            </DialogContent>
        </Dialog>
    );
};

const DealShowContent = ({ id }: { id?: Identifier }) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const { permissions, isLoading } = usePermissions();
    const redirect = useRedirect();
    const record = useRecordContext();
    const resource = useResourceContext();
    const translate = useTranslate();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const [updateDeal] = useUpdate(
        resource,
        {
            id: record?.id,
            data: {
                dealInput: {
                    deal_stash: !record?.deal_stash,
                    update_userid: `${identity?.id}(${identity?.fullName})`,
                },
            },
            previousData: record,
        },
        {
            mutationMode: "pessimistic",
            onSuccess: ({ deal_stash }: RaRecord) => {
                notify(deal_stash ? "notification.stash" : "notification.unstash", { type: "info" });
                redirect("list", resource);
            },
        },
    );
    const [deleteDeal] = useDelete(
        resource,
        {
            id: id as Identifier,
            previousData: record,
        },
        {
            mutationMode: "undoable",
            onSuccess: () => {
                notify("ra.notification.deleted", { type: "info", undoable: true });
                redirect("list", resource);
            },
        },
    );

    const handleMenuToggleStash = () => {
        updateDeal();
    };

    const handleDelete = () => {
        deleteDeal();
    };
    if (!record || !identity) return null;

    return (
        <Box display="flex">
            {!isSmall && (
                <Box width={100} display="flex" flexDirection="column" alignItems="center">
                    <ReferenceField source="client_id" reference="crm/client" link="show">
                        <ClientAvatar sx={{ mt: 2, mb: 2 }} />
                    </ReferenceField>
                    <ReferenceField source="client_id" reference="crm/client" link="show">
                        <TextField source="client_name" align="center" component="div" />
                    </ReferenceField>
                </Box>
            )}
            <Box ml={isSmall ? 0 : 2} flex="1">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h3">{record.deal_title}</Typography>
                    <Box minWidth={150} display="flex" justifyContent="end">
                        {permissions.crm?.update && (
                            <IconButtonWithTooltip
                                label={translate(record.deal_stash ? "common.unstash" : "common.stash")}
                                size="small"
                                onClick={handleMenuToggleStash}
                            >
                                {record.deal_stash ? <OutboxIcon /> : <MoveToInboxIcon />}
                            </IconButtonWithTooltip>
                        )}
                        {permissions.crm?.update && (
                            <IconButtonWithTooltip
                                label={translate("crm.editDeal")}
                                size="small"
                                onClick={() => {
                                    redirect(`/crm/deal/${id}`);
                                }}
                            >
                                <EditIcon />
                            </IconButtonWithTooltip>
                        )}
                        {permissions.crm?.delete && record.deal_status.code === "DS_OPPOTUNITY" && (
                            <IconButtonWithTooltip
                                label={translate("crm.deleteDeal")}
                                size="small"
                                onClick={handleDelete}
                            >
                                <TrashIcon />
                            </IconButtonWithTooltip>
                        )}
                    </Box>
                </Box>

                {isSmall && (
                    <Box display="flex" mt={2}>
                        <Box display="flex" flexDirection="column">
                            <Typography color="textSecondary" variant="body2">
                                {translate("crm.clientName")}
                            </Typography>
                            <Typography variant="subtitle1">
                                <ReferenceField source="client_id" reference="crm/client" link="show">
                                    <TextField source="client_name" align="center" component="div" />
                                </ReferenceField>
                            </Typography>
                        </Box>
                    </Box>
                )}

                <Grid container spacing={2} mt={2}>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("common.createAt")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {format(new Date(Number(record.create_at)), "PP", { locale: ko })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("common.type")}
                        </Typography>
                        <Typography variant="subtitle1">
                            <ReferenceField source="client_id" reference="crm/client" link="show">
                                <FunctionField
                                    source="client_type"
                                    render={({ client_type }: Partial<CrmClient>) => {
                                        if (client_type) {
                                            return client_type.codeName;
                                        } else {
                                            return "";
                                        }
                                    }}
                                />
                            </ReferenceField>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("crm.budget")}
                        </Typography>
                        <Typography variant="subtitle1">
                            {Number(record.budget).toLocaleString("ko-KR", {
                                notation: "compact",
                                style: "currency",
                                currency: "KRW",
                                currencyDisplay: "narrowSymbol",
                                minimumSignificantDigits: 3,
                            })}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("crm.crmStatus")}
                        </Typography>
                        <Typography variant="subtitle1">{record.deal_status.codeName}</Typography>
                    </Grid>
                </Grid>

                <Box mt={2} mb={2}>
                    <Box display="flex" mr={5} flexDirection="column" minHeight={48}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("common.contacts")}
                        </Typography>
                        <ContactList />
                    </Box>
                </Box>

                <Box mt={2} mb={2} style={{ whiteSpace: "pre-line" }}>
                    <Typography color="textSecondary" variant="body2">
                        {translate("common.description")}
                    </Typography>
                    <Typography>{record.deal_description}</Typography>
                </Box>

                <Divider />

                <Box mt={2}>
                    <ReferenceManyField
                        target="deal_id"
                        reference="crm/deal_note"
                        sort={{ field: "create_at", order: "DESC" }}
                    >
                        <NotesIterator reference="crm/deal" />
                    </ReferenceManyField>
                </Box>
            </Box>
        </Box>
    );
};
