import * as React from "react";
import { MenuItemLink, useTranslate } from "react-admin";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

const VOCMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <MenuItemLink
                to="/voc"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.voc_list")}
                leftIcon={<RecordVoiceOverIcon />}
                dense={dense}
            />
        </>
    );
};

export default VOCMenuItems;
