import * as React from "react";
import {
    TopToolbar,
    Title,
    useTranslate,
    useGetIdentity,
    Button,
    useRedirect,
    Form,
    TextInput,
    FileField,
    required,
    regex,
    email,
    Toolbar,
    RadioButtonGroupInput,
    SelectInput,
} from "react-admin";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Box, Card, CardContent, Dialog, DialogContent, Grid, TextField, Theme, useMediaQuery } from "@mui/material";
import { KakaoAddressInput } from "@src/components/kakao/KakaoComponent";
import KeyIcon from "@mui/icons-material/Key";
import { TypographWithIcon } from "@src/components/TypographWithIcon";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useState } from "react";

const CorpInfoInputs = () => {
    const translate = useTranslate();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const isUpperMedium = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box display="flex">
                <Box flex="1" mb={isSmall ? "80px" : 0}>
                    <Form>
                        <Card>
                            <CardContent>
                                <TypographWithIcon
                                    icon={<BusinessIcon color="disabled" />}
                                    variant="h4"
                                    text={translate("common.basic_info")}
                                />
                                <Box width={isUpperMedium ? "85%" : "100%"}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.corp_name"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.corp_ceo_name"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.corp_number"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                // 업종
                                                label={"common.corp_sector"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                // 업태
                                                label={"common.corp_sector2"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.id"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.manager_name"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Button
                                                variant="contained"
                                                label="common.edit_password"
                                                children={<KeyIcon />}
                                                sx={{ mt: isSmall ? 0 : "20px" }}
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.manager_tel_number"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.manager_phone"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.fax_tel_number"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.manager_email"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <RadioButtonGroupInput
                                                label="common.sms_yn"
                                                source=""
                                                choices={[
                                                    { id: "Y", name: "수신" },
                                                    { id: "N", name: "수신안함" },
                                                ]}
                                                translateChoice={false}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <RadioButtonGroupInput
                                                label="common.email_yn"
                                                source=""
                                                choices={[
                                                    { id: "Y", name: "수신" },
                                                    { id: "N", name: "수신안함" },
                                                ]}
                                                translateChoice={false}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* 주소 */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <KakaoAddressInput />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextInput
                                                label={"common.addressDetail"}
                                                source="address_detail"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>

                                <TypographWithIcon
                                    icon={<PointOfSaleIcon color="disabled" />}
                                    variant="h4"
                                    sx={{ mt: 4 }}
                                    text={translate("common.payment_info")}
                                />
                                <Box width={isUpperMedium ? "85%" : "100%"}>
                                    {/* 청구지 주소 */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <KakaoAddressInput label="common.bills_address" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextInput
                                                label={"common.bills_detail_address"}
                                                source="address_detail"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.bills_email"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.payment_method"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.payment_yn"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <SelectInput
                                                label={"common.separate_adjust_payment_method"}
                                                source="contact_name"
                                                choices={[
                                                    { id: "Y", name: "수동결제" },
                                                    { id: "N", name: "자동결제" },
                                                ]}
                                                // validate={[required()]}
                                                helperText={"(매월 15일 자동결제/납기만료일)"}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <RadioButtonGroupInput
                                                label="common.cancellation_fee_free_yn"
                                                source=""
                                                choices={[
                                                    { id: "Y", name: "면제" },
                                                    { id: "N", name: "부과" },
                                                ]}
                                                translateChoice={false}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <RadioButtonGroupInput
                                                label="common.account_number_edit_yn"
                                                source=""
                                                choices={[
                                                    { id: "Y", name: "가능" },
                                                    { id: "N", name: "불가능" },
                                                ]}
                                                translateChoice={false}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <TypographWithIcon
                                    icon={<CreditCardIcon color="disabled" />}
                                    variant="h4"
                                    sx={{ mt: 4 }}
                                    text={translate("common.card_info")}
                                />
                                <Box width={isUpperMedium ? "85%" : "100%"}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput
                                                label={"common.card_info"}
                                                source="contact_name"
                                                // validate={[required()]}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={2}>
                                            <Button
                                                variant="contained"
                                                label="ra.action.update"
                                                children={<AddCardIcon />}
                                                sx={{ mt: isSmall ? 0 : "20px" }}
                                                onClick={() => {
                                                    // redirect("list", "/cardIssuance");
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                            <Toolbar />
                        </Card>
                    </Form>
                </Box>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="xs"
                sx={{
                    ".MuiDialogContent-root": {
                        padding: 0,
                    },
                }}
            >
                <DialogContent>
                    <PasswordResetPopup handleClose={handleClose} />
                </DialogContent>
            </Dialog>
        </>
    );
};

const PasswordResetPopup = ({ handleClose }: any) => {
    const translate = useTranslate();
    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "120px" }}>
            <TextField
                label={translate("common.new_password")}
                name="password"
                helperText={translate("helper_text.enter_new_password")}
            />
            <Button
                variant="contained"
                label="ra.action.update"
                children={<KeyIcon />}
                onClick={() => {
                    alert("Edit Password");
                    handleClose(true);
                }}
            />
        </Box>
    );
};

export const CorpAdminCorpInfoEdit = () => {
    const redirect = useRedirect();
    const { identity } = useGetIdentity();

    if (!identity) return null;

    return (
        <>
            <Title title={"title.corp_admin_corp_info"} />
            <TopToolbar>
                <Button
                    label={"title.corp_admin_accounts_list"}
                    children={<PeopleIcon />}
                    onClick={() => {
                        redirect("/corp_admin/accounts");
                    }}
                />
            </TopToolbar>
            <CorpInfoInputs />
        </>
    );
};
