import * as React from "react";
import { MenuItemLink, usePermissions, useTranslate } from "react-admin";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const CorpAdminAccountsMenuItems = ({ dense }: { dense: boolean }) => {
    const { isLoading } = usePermissions();
    const translate = useTranslate();

    if (isLoading) return null;
    return (
        <>
            <MenuItemLink
                to="/corp_admin/corp_info"
                state={{ _scrollToTop: true }}
                primaryText={translate(`title.corp_admin_corp_info`)}
                dense={dense}
                leftIcon={<BusinessIcon />}
            />
            <MenuItemLink
                to="/corp_admin/accounts"
                state={{ _scrollToTop: true }}
                primaryText={translate(`title.corp_admin_accounts_list`)}
                dense={dense}
                leftIcon={<PeopleIcon />}
            />
            {/* <MenuItemLink
                to="/corp_admin/cards"
                state={{ _scrollToTop: true }}
                primaryText={translate(`title.corp_admin_cards_list`)}
                dense={dense}
                leftIcon={<CreditCardIcon />}
            /> */}
        </>
    );
};

export default CorpAdminAccountsMenuItems;
