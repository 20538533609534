import React from "react";
import { Button } from "react-admin";

const ButtonField = ({
    label,
    btnText = "",
    onClick,
    icon = null,
    variant = "text",
    fullWidth = true,
    sx = {},
}: any) => {
    return (
        <Button
            label={btnText != "" ? btnText : label}
            variant={variant}
            size={icon ? "large" : "small"}
            onClick={onClick}
            children={icon ? icon : null}
            fullWidth={fullWidth}
            sx={{ ...sx }}
        />
    );
};

export default ButtonField;
