import * as React from "react";
import { MenuItemLink, usePermissions, useTranslate } from "react-admin";
import CampaignIcon from "@mui/icons-material/Campaign";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

const CorpAdminNoticeMenuItems = ({ dense }: { dense: boolean }) => {
    const { isLoading } = usePermissions();
    const translate = useTranslate();

    if (isLoading) return null;
    return (
        <>
            <MenuItemLink
                to="/corp_admin/notice"
                state={{ _scrollToTop: true }}
                primaryText={translate(`title.corp_admin_notice`)}
                dense={dense}
                leftIcon={<CampaignIcon />}
            />
            <MenuItemLink
                to="/corp_admin/qna"
                state={{ _scrollToTop: true }}
                primaryText={translate(`title.corp_admin_qna`)}
                dense={dense}
                leftIcon={<QuestionAnswerIcon />}
            />
        </>
    );
};

export default CorpAdminNoticeMenuItems;
