import { DefaultTheme } from "@mui/private-theming";

const defaultDarkBg = "#191919";
const defaultLightBg = "#fcfcfe";

const everonBlue = "#115CA0";
const everonLightBlue = "#97e3d5";
const appBarColor = "#031220";
export const fontFamily =
    "Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif";

export const getNivoTheme = (theme: any) => {
    const mode = theme?.palette?.mode;
    const color = mode == "dark" ? "#efefef" : "#41444a";
    return {
        textColor: color,
        background: mode == "dark" ? defaultDarkBg : defaultLightBg,
        axis: {
            fontSize: "14px",
            tickColor: color,
            ticks: {
                line: {
                    stroke: "#555555",
                },
                text: {
                    fill: color,
                },
            },
            legend: {
                text: {
                    fill: color,
                },
            },
        },
        tooltip: {
            container: {
                color: color,
                border: mode == "dark" ? "1px solid #191919" : "1px solid #efefe",
                background: mode == "dark" ? "#191919" : "#fff",
            },
        },
        legends: {
            title: {
                text: color,
            },
            text: {
                fill: color,
            },
        },
    };
};

const commonProps = {
    shape: {
        borderRadius: 10,
    },
    typography: {
        fontFamily,
        fontSize: 14,
        h1: {
            fontSize: "2rem",
        },
        h2: {
            fontSize: "1.5rem",
        },
        h3: {
            fontSize: "1.17rem",
        },
        h4: {
            fontSize: "1.00rem",
        },
        h5: {
            fontSize: "0.83rem",
        },
        h6: {
            fontSize: "0.67rem",
        },
    },
    sidebar: {
        width: 200,
    },
};

const commonComponentsProps = {
    MuiDrawer: {
        styleOverrides: {
            root: {
                "& .RaSidebar-paperAnchorLeft": {
                    border: "none",
                },
            },
        },
    },
    MuiToolbar: {
        styleOverrides: {
            root: {
                "&.RaAppBar-toolbar": {
                    border: "none",
                    color: defaultLightBg,
                },
            },
        },
    },
    MuiAppBar: {
        styleOverrides: {
            colorSecondary: {
                color: "#fff",
                backgroundColor: appBarColor,
                border: "none",
            },
        },
    },
    MuiLinearProgress: {
        styleOverrides: {
            colorPrimary: {
                backgroundColor: "#f5f5f5",
            },
            barColorPrimary: {
                backgroundColor: "#d7d7d7",
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
        styleOverrides: {
            root: {
                "&:hover:active::after": {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: "block",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "currentColor",
                    opacity: 0.3,
                    borderRadius: "inherit",
                },
            },
        },
    },
    MuiSnackbarContent: {
        styleOverrides: {
            root: {
                border: "none",
            },
        },
    },
    MuiTextField: {
        defaultProps: {
            variant: "standard",
        },
    },
    RaLoadingIndicator: {
        styleOverrides: {
            root: {
                minWidth: "50px",
            },
        },
    },
    RaSelectInput: {
        styleOverrides: {
            root: {
                marginTop: 0,
            },
        },
    },
};

export const lightTheme: DefaultTheme = {
    ...commonProps,
    palette: {
        primary: {
            main: everonBlue,
        },
        secondary: {
            main: everonLightBlue,
        },
        background: {
            default: defaultLightBg,
        },
        mode: "light" as const,
    },
    components: {
        ...commonComponentsProps,
        RaFileInput: {
            styleOverrides: {
                root: {
                    "& .RaFileInput-dropZone": {
                        borderRadius: 10,
                        border: "1px solid #878787",
                        color: "#878787",
                    },
                    "& .RaFileInput-preview a": {
                        color: "#878787",
                        textDecoration: "none",
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    borderLeft: `5px solid ${defaultLightBg}`,
                    "& .RaMenuItemLink-icon": {
                        minWidth: "36px",
                    },
                    "&.RaMenuItemLink-active": {
                        borderLeft: `5px solid ${everonBlue}`,
                    },
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&$disabled": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: "none",
                },
                root: {
                    border: "1px solid #e0e0e3",
                    backgroundClip: "padding-box",
                    backgroundColor: defaultLightBg,
                },
            },
        },
    },
};

export const darkTheme: DefaultTheme = {
    ...commonProps,
    palette: {
        primary: {
            main: everonLightBlue,
        },
        secondary: {
            main: everonBlue,
        },
        background: {
            default: defaultDarkBg,
        },
        mode: "dark" as const, // Switching the dark mode on is a single property value change.
    },
    components: {
        ...commonComponentsProps,
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem",
                    borderLeft: `5px solid ${defaultDarkBg}`,
                    "& .RaMenuItemLink-icon": {
                        minWidth: "36px",
                    },
                    "&.RaMenuItemLink-active": {
                        borderLeft: `5px solid ${everonLightBlue}`,
                    },
                },
            },
        },
        RaFileInput: {
            styleOverrides: {
                root: {
                    "& .RaFileInput-dropZone": {
                        borderRadius: 10,
                        border: "1px solid #c5c5c5",
                        color: "#c5c5c5",
                    },
                    "& .RaFileInput-preview a": {
                        color: "#c5c5c5",
                        textDecoration: "none",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: "none",
                },
                root: {
                    border: "1px solid #373737",
                    backgroundClip: "padding-box",
                    backgroundColor: defaultDarkBg,
                },
            },
        },
    },
};
