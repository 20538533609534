import React, { useState } from "react";
import { List, useGetList, useTranslate } from "react-admin";
import { useLocation } from "react-router";
import { VocCardListContent } from "./VocCardListContent";
import { VocShow } from "./VocShow";
import queryString from "query-string";

export const VocCardList = ({ filters, actions, filterDefaultValues, perPage, pagination, exporter }: any) => {
    const { search } = useLocation();
    const query = queryString.parse(search);
    const defaultShowInfo =
        query.id != null
            ? {
                  open: true,
                  id: +query.id,
              }
            : {
                  open: false,
                  id: -1,
              };

    const translate = useTranslate();
    const [showInfo, setShowInfo] = useState(defaultShowInfo);
    const { data, isLoading } = useGetList("CommonCode", {
        sort: { field: "codeOrder", order: "ASC" },
        filter: { parentCode: "VOC_STATUS" },
    });

    if (isLoading || !data) return null;

    return (
        <>
            <List
                pagination={pagination}
                perPage={perPage}
                filterDefaultValues={filterDefaultValues}
                filters={filters}
                actions={actions}
                component="div"
                title={translate("title.voc_list")}
                exporter={exporter}
                empty={false}
            >
                <VocCardListContent
                    vocStatus={data.map(({ parentCode, code, codeDescription, codeName, codeOrder }) => ({
                        parentCode,
                        code,
                        codeDescription,
                        codeName,
                        codeOrder,
                    }))}
                    setShowInfo={setShowInfo}
                />
            </List>
            <VocShow showInfo={showInfo} setShowInfo={setShowInfo} />
        </>
    );
};
