import React, { useState, useEffect } from "react";
import {
    Button,
    CreateButton,
    ExportButton,
    FilterButton,
    Pagination,
    SelectInput,
    TextInput,
    TopToolbar,
    useDataProvider,
    useGetIdentity,
    usePermissions,
    useRemoveFromStore,
} from "react-admin";
import ListIcon from "@mui/icons-material/List";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import { VocCardList } from "./VocCardList";
import { VocStashList } from "./VocStashList";
import { vocExporter } from "@src/utils/exporters";

export const VocList = () => {
    const [isCardList, setIsCardList] = useState(true);
    const { isLoading, permissions } = usePermissions();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();
    const [deptChoices, setDeptChoices] = useState<{ id: any; name: any }[]>([]);

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: "VOC_ASSIGN_DEPT" },
            });
            setDeptChoices(
                data.map(item => {
                    return {
                        id: item.parentCode + "." + item.code,
                        name: item.codeName,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    const filters = [
        <TextInput label="voc.requestTitle" source="requestTitle" />,
        <TextInput label="voc.customerName" source="customerName" />,
        <SelectInput label="voc.responseDepartment" source="responseDepartment" choices={deptChoices} />,
    ];
    if (!identity) return null;
    if (isLoading || !permissions.voc?.access) return null;

    return (
        <>
            {isCardList ? (
                <VocCardList
                    pagination={<Pagination rowsPerPageOptions={[20, 40, 60, 80, 100]} />}
                    perPage={20}
                    filters={filters}
                    filterDefaultValues={{ vocStash: !isCardList }}
                    actions={<VocListActions isCardList={isCardList} setIsCardList={setIsCardList} />}
                    exporter={vocExporter}
                />
            ) : (
                <VocStashList
                    pagination={<Pagination rowsPerPageOptions={[15, 25, 50, 100]} />}
                    perPage={15}
                    filters={filters}
                    filterDefaultValues={{ vocStash: !isCardList }}
                    actions={<VocListActions isCardList={isCardList} setIsCardList={setIsCardList} />}
                    exporter={vocExporter}
                />
            )}
        </>
    );
};

const VocListActions = ({
    isCardList,
    setIsCardList,
}: {
    isCardList: boolean;
    setIsCardList: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { isLoading, permissions } = usePermissions();
    const removeKey = useRemoveFromStore();
    if (isLoading) return null;
    return (
        <TopToolbar>
            <FilterButton />
            <ExportButton />

            <Button
                label={isCardList ? "common.stashList" : "voc.vocCardList"}
                children={isCardList ? <ListIcon /> : <ViewComfyIcon />}
                onClick={() => {
                    removeKey("Voc.listParams");
                    setIsCardList(!isCardList);
                }}
            />
            {permissions.voc?.create && <CreateButton variant="contained" label="voc.addVoc" sx={{ marginLeft: 2 }} />}
        </TopToolbar>
    );
};
