import React, { useState, useEffect, ChangeEvent } from "react";
import { useDataProvider, useGetIdentity, useResourceContext, useRecordContext } from "react-admin";
import { TextField, MenuItem, Box } from "@mui/material";

export const DeptSelector = ({ dept, setDept, sx }: any) => {
    const record = useRecordContext();
    const resouce = useResourceContext();
    const dataProvider = useDataProvider();
    const [deptData, setDeptData] = useState<any[]>([]);
    const { identity } = useGetIdentity();

    useEffect(() => {
        const initChoices = async () => {
            const { data } = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 50 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: "VOC_ASSIGN_DEPT" },
            });
            setDeptData(
                data.map(({ id, parentCode, code, codeName, codeDescription, codeOrder }: any) => {
                    return {
                        id,
                        parentCode,
                        code,
                        codeName,
                        codeDescription,
                        codeOrder,
                    };
                }),
            );
        };
        initChoices();
    }, []);

    if (deptData.length === 0) return null;

    return (
        <TextField
            required
            select
            value={dept}
            onChange={async (event: ChangeEvent<{ value: unknown }>) => {
                setDept(event.target.value as string);
                dataProvider.update(resouce, {
                    id: record?.id,
                    data: {
                        responseDepartment: "VOC_ASSIGN_DEPT." + event.target.value,
                        vocStatus: "VOC_STATUS.VOC_STATUS_DEPT_SELECTED",
                        updateUserid: `${identity?.id}(${identity?.fullName})`,
                    },
                    previousData: record,
                });
            }}
            margin="none"
            size="small"
            sx={{
                minWidth: "160px",
                ...sx,
            }}
        >
            <Box width={10} height={10} display="inline-block" borderRadius={5} component="span" />
            {deptData.map((item: any) => (
                <MenuItem key={item.id} value={item.code}>
                    {item.codeName}
                </MenuItem>
            ))}
        </TextField>
    );
};
