import * as React from "react";
import { Box, Paper } from "@mui/material";
import { RecordContextProvider, useListContext } from "react-admin";
import { ClientCard } from "./ClientCard";
import { CrmClient } from "../types";

const times = (nbChildren: number, fn: (key: number) => any) => Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
    return (
        <Box display="flex" flexWrap="wrap" width="100%" gap={1}>
            {times(15, key => (
                <Paper
                    sx={{
                        height: 200,
                        width: 194,
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "grey[200]",
                    }}
                    key={key}
                />
            ))}
        </Box>
    );
};

const LoadedGridList = () => {
    const { data, isLoading } = useListContext<CrmClient>();
    if (isLoading) return null;

    return (
        <Box display="flex" flexWrap="wrap" width="100%" gap={1}>
            {data.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <ClientCard />
                </RecordContextProvider>
            ))}
        </Box>
    );
};

export const GridList = () => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingGridList /> : <LoadedGridList />;
};
