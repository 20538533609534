import React, { useState, useEffect } from "react";
import {
    Button,
    Datagrid,
    FunctionField,
    List,
    TextField,
    useListContext,
    useListFilterContext,
    useTranslate,
    useRefresh,
    useNotify,
    useUnselectAll,
    useUpdateMany,
    useResourceContext,
    useGetIdentity,
    BulkExportButton,
    useRecordContext,
    SimpleList,
} from "react-admin";
import OutboxIcon from "@mui/icons-material/Outbox";
import { VocShow } from "./VocShow";
import { format } from "date-fns";
import { convertMsToProgressTime, isValidDate } from "@src/utils/date";
import { Voc } from "./types";
import { Theme, useMediaQuery } from "@mui/material";
import { ko } from "date-fns/locale";

const VocUnstashButton = () => {
    const { selectedIds } = useListContext();
    const { identity } = useGetIdentity();
    const resource = useResourceContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll(resource);
    const [updateMany, { isLoading }] = useUpdateMany(
        resource,
        { ids: selectedIds, data: { vocStash: false, updateUserid: identity?.id } },
        {
            onSuccess: () => {
                refresh();
                notify("notification.unstash");
                unselectAll();
            },
            onError: error => notify("notification.unstashFail", { type: "warning" }),
        },
    );

    return (
        <Button label="common.unstash" disabled={isLoading} onClick={() => updateMany()}>
            <OutboxIcon />
        </Button>
    );
};

const VocStashListBulkActions = () => {
    return (
        <>
            <BulkExportButton />
            <VocUnstashButton />
        </>
    );
};

const ProgressTimeField = ({ label, source, textAlign }: any) => {
    const record = useRecordContext();
    let progressTimeColor = "#00c694";
    if (record.responseTimeToComplete > 0) {
        const days = Math.floor(record.responseTimeToComplete / 1000 / 60 / 60 / 24);
        if (days > 4) {
            progressTimeColor = "red";
        } else if (days > 2) {
            progressTimeColor = "orange";
        }
    }
    const progressTime = convertMsToProgressTime(+record.responseTimeToComplete);

    return <span style={{ color: progressTimeColor }}>{progressTime}</span>;
};

const ListContent = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [showInfo, setShowInfo] = useState({ open: false, id: -1 });
    const { data, isLoading, refetch, sort, setSort, perPage, setPerPage } = useListContext();
    const { filterValues, setFilters, displayedFilters } = useListFilterContext();
    const dateFnsFormat = format;

    useEffect(() => {
        if (isLoading) return;
        if (filterValues && !filterValues.vocStash) {
            setFilters({ ...filterValues, vocStash: true }, displayedFilters);
        }
        if (![15, 25, 50, 100].includes(perPage)) {
            setPerPage(15);
        }
        if (sort.field === "vocStatusOrder") {
            setSort({
                field: "id",
                order: "DESC",
            });
        }
        refetch();
    }, [isLoading, perPage, filterValues, data]);

    if (isLoading || ![15, 25, 50, 100].includes(perPage) || !filterValues.vocStash || !data) return null;

    return (
        <>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.requestTitle}
                    secondaryText={record => {
                        return (
                            <span>
                                {`${record.customerName}, ${record.responseDepartment.codeName}, `}
                                <ProgressTimeField />
                            </span>
                        );
                    }}
                    tertiaryText={record => {
                        const date = new Date(record.inboundAt);
                        if (!isValidDate(date)) {
                            return "invalid Date";
                        }
                        return dateFnsFormat(date, "yyyy-MM-dd");
                    }}
                    linkType={(record: any) => {
                        return `/voc?id=${record.id}`;
                    }}
                    onClick={() => {
                        const idx = location.hash.indexOf("id");
                        if (idx !== -1) {
                            const id = location.hash.substring(idx + 3);
                            if (!showInfo.open) {
                                setShowInfo({
                                    open: true,
                                    id: +id,
                                });
                            }
                        }
                    }}
                />
            ) : (
                <>
                    <Datagrid
                        rowClick={(id, resource, record) => {
                            setShowInfo({
                                open: true,
                                id: +record.id,
                            });
                            return "";
                        }}
                        bulkActionButtons={<VocStashListBulkActions />}
                    >
                        <TextField label="common.index" source="id" textAlign="center" sortable={false} />
                        <TextField label="voc.customerName" source="customerName" textAlign="center" sortable={false} />
                        <TextField label="voc.requestTitle" source="requestTitle" textAlign="center" sortable={false} />
                        <FunctionField
                            label="voc.responseDepartment"
                            source="responseDepartment"
                            textAlign="center"
                            render={(record: any) => {
                                if (!record.responseDepartment) return "";

                                return record.responseDepartment.codeName;
                            }}
                        />
                        <FunctionField
                            label="voc.inboundAt"
                            source="inboundAt"
                            textAlign="center"
                            render={({ inboundAt }: { inboundAt: string }) => {
                                const date = new Date(inboundAt);
                                if (!isValidDate(date)) {
                                    return "invalid Date";
                                }
                                return dateFnsFormat(date, "yyyy-MM-dd");
                            }}
                        />
                        <FunctionField
                            label="voc.responseCompleteAt"
                            source="responseCompleteAt"
                            textAlign="center"
                            render={({ responseCompleteAt }: { responseCompleteAt: string }) => {
                                const date = new Date(responseCompleteAt);
                                if (!isValidDate(date)) {
                                    return "invalid Date";
                                }
                                return dateFnsFormat(date, "yyyy-MM-dd");
                            }}
                        />
                        <ProgressTimeField
                            label="voc.responseTimeToComplete"
                            source="responseTimeToComplete"
                            textAlign="center"
                        />
                    </Datagrid>
                </>
            )}
            <VocShow showInfo={showInfo} setShowInfo={setShowInfo} />
        </>
    );
};

export const VocStashList = ({ filters, actions, filterDefaultValues, perPage, pagination, exporter }: any) => {
    const translate = useTranslate();
    return (
        <List
            pagination={pagination}
            perPage={perPage}
            sort={{ field: "id", order: "DESC" }}
            filterDefaultValues={filterDefaultValues}
            filters={filters}
            actions={actions}
            title={translate("title.vocDoneList")}
            exporter={exporter}
            empty={false}
        >
            <ListContent />
        </List>
    );
};
