import * as React from "react";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import {
    Edit,
    ListButton,
    TopToolbar,
    SelectInput,
    SimpleForm,
    TextInput,
    useGetList,
    useRecordContext,
    useTranslate,
    RichTextField,
} from "react-admin";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { RichTextInputWithImageAttachment } from "@src/components/inputs/RichTextInputWithImageAttachment";
import { RichTextInput } from "ra-input-rich-text";
import { getUserName } from "@src/utils/utils";

const CorpAdminQnaAnswerInputs = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    const { data, isLoading } = useGetList("CommonCode", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "codeOrder", order: "ASC" },
        filter: { parentCode: "CORP_ADMIN_QNA" },
    });
    const [qnaCategoryChoices, setQnaCategirtChoices] = useState<any>([]);

    useEffect(() => {
        if (isLoading || !data) return;

        const choices = data.map(item => {
            return {
                id: item.code,
                name: item.code_name,
            };
        });
        setQnaCategirtChoices(choices);
    }, [isLoading, data]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9}>
                    <TextInput source="title" disabled label={"common.title"} margin="none" fullWidth />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} md={3}>
                    <TextInput
                        format={(create_at: any) => {
                            return format(new Date(+create_at), "yyyy-MM-dd HH:mm:ss");
                        }}
                        disabled
                        source="create_at"
                        label={"common.date"}
                        margin="none"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                    <TextInput
                        format={(create_userid: any) => {
                            return getUserName(create_userid);
                        }}
                        disabled
                        source="create_userid"
                        label={"common.writer"}
                        margin="none"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                    <SelectInput source="category" disabled label={"분류"} choices={qnaCategoryChoices} fullWidth />
                </Grid>
            </Grid>
            {/* <TextField
                source="name"
                label={"common.writer"}
                sx={{ display: "inline-flex", marginRight: "1rem" }}
                defaultValue={identity?.fullName}
                margin="none"
            /> */}
            {/* <TextInput variant="filled" source="content" label={"common.content"} minRows={5} multiline fullWidth /> */}
            <Box>
                <Typography color="textSecondary" variant="caption">
                    {translate("common.question")}
                </Typography>
                <RichTextField label={"common.content"} source="content" />
            </Box>
            {/* <TextInput source="paragraphs" label={"common.content"} margin="none" minRows={5} multiline fullWidth /> */}
            {/* <FileInput source="files" label={'common.attachments'} accept="application/pdf, image/*" multiple>
                <FileField source="src" title="title" target="_blank" />
            </FileInput> */}

            {/* <Box mt={4} mb={2}>
                <Typography variant="body2">답변 내용</Typography>
            </Box> */}
            <Box sx={{ mt: 4, width: "100%" }}>
                <RichTextInputWithImageAttachment label={"common.answer"} source="content" />
            </Box>
        </>
    );
};

const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const CorpAdminQnaAnswerEdit = () => {
    return (
        <Edit title={"title.customer_qna"} actions={<EditActions />}>
            <SimpleForm>
                <CorpAdminQnaAnswerInputs />
            </SimpleForm>
        </Edit>
    );
};
