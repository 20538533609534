import { Box, Grid } from "@mui/material";
import { RichTextInputWithImageAttachment } from "@src/components/inputs/RichTextInputWithImageAttachment";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { SelectInput, SimpleForm, TextInput, useGetList, useRecordContext, useTranslate } from "react-admin";

export const CorpAdminQnaInputs = ({ type }: { type: string }) => {
    // const record = useRecordContext();
    const { data, isLoading } = useGetList("CommonCode", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "codeOrder", order: "ASC" },
        filter: { parentCode: "CORP_ADMIN_QNA" },
    });
    const [qnaCategoryChoices, setQnaCategirtChoices] = useState<any>([]);

    useEffect(() => {
        if (isLoading || !data) return;

        const choices = data.map(item => {
            return {
                id: item.code,
                name: item.code_name,
            };
        });
        setQnaCategirtChoices(choices);
    }, [isLoading, data]);

    return (
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9}>
                    <TextInput source="title" disabled label={"common.title"} margin="none" fullWidth />
                </Grid>
            </Grid>
            <Box>
                <SelectInput source="category" label={"분류"} choices={qnaCategoryChoices} sx={{ mt: 0, mr: 2 }} />
                {/* <DateInput disabled source="date" label={"common.date"} defaultValue={new Date()} margin="none" /> */}
                {type === "edit" && (
                    <TextInput
                        format={(create_at: any) => {
                            return format(new Date(+create_at), "yyyy-MM-dd HH:mm:ss");
                        }}
                        disabled
                        source="create_at"
                        label={"common.date"}
                        margin="none"
                    />
                )}
            </Box>
            {/* <TextField
                source="name"
                label={"common.writer"}
                sx={{ display: "inline-flex", marginRight: "1rem" }}
                defaultValue={identity?.fullName}
                margin="none"
            /> */}
            {/* <TextInput variant="filled" source="content" label={"common.content"} minRows={5} multiline fullWidth /> */}
            <RichTextInputWithImageAttachment label={"common.content"} source="content" />
            {/* <TextInput source="paragraphs" label={"common.content"} margin="none" minRows={5} multiline fullWidth /> */}
            {/* <FileInput source="files" label={'common.attachments'} accept="application/pdf, image/*" multiple>
                <FileField source="src" title="title" target="_blank" />
            </FileInput> */}
        </SimpleForm>
    );
};
