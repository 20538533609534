import { ContactShow } from "./contacts/ContactShow";
import { ContactList } from "./contacts/ContactList";
import { ContactEdit } from "./contacts/ContactEdit";
import { ContactCreate } from "./contacts/ContactCreate";

import { ClientList } from "./clients/ClientList";
import { ClientCreate } from "./clients/ClientCreate";
import { ClientShow } from "./clients/ClientShow";
import { ClientEdit } from "./clients/ClientEdit";

import { DealList } from "./deals/DealList";

export const getCrmContactResourceProps = ({ crm }: any) => ({
    ...(!!crm?.create && { create: ContactCreate }),
    ...(!!crm?.access && { list: ContactList, show: ContactShow }),
    ...((!!crm?.update || !!crm?.delete) && { edit: ContactEdit }),
});

export const getCrmClientResourceProps = ({ crm }: any) => ({
    ...(!!crm?.create && { create: ClientCreate }),
    ...(!!crm?.access && { list: ClientList, show: ClientShow }),
    ...((!!crm?.update || !!crm?.delete) && { edit: ClientEdit }),
});

export const getCrmDealResourceProps = ({ crm }: any) => ({
    ...(!!crm?.access && { list: DealList }),
});
