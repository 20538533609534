import React from "react";
import {
    Create,
    ListButton,
    TopToolbar,
    useCreate,
    useDataProvider,
    useGetIdentity,
    useNotify,
    useRedirect,
    useResourceContext,
    useTranslate,
} from "react-admin";
import { Voc, VocCreateInputs } from "./types";
import { VocInputs } from "./VocInputs";

const CreateActions = () => {
    return (
        <TopToolbar>
            <ListButton />
        </TopToolbar>
    );
};

export const VocCreate = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [create] = useCreate();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const dataProvider = useDataProvider();

    const handleSubmit = (data: VocCreateInputs) => {
        const {
            // inbound_at,
            inboundContact,
            inboundChannelCategory1,
            inboundChannelCategory2,
            customerInboundCount,
            customerName,
            customerType,
            requestTitle,
            requestCategory1,
            requestCategory2,
            requestCategory3,
            requestDescription,
        } = data;

        create(
            "voc",
            {
                data: {
                    // inbound_at,
                    inboundContact,
                    inboundChannelCategory1: "VOC_INBOUND_CHANNEL." + inboundChannelCategory1,
                    inboundChannelCategory2: inboundChannelCategory1 + "." + inboundChannelCategory2,
                    customerInboundCount: +customerInboundCount + 1,
                    customerName,
                    customerType: "VOC_CUSTOMER_TYPE." + customerType,
                    requestTitle,
                    requestCategory1: "VOC_REQUEST_TYPE." + requestCategory1,
                    requestCategory2: requestCategory1 + "." + requestCategory2,
                    requestCategory3: requestCategory2 + "." + requestCategory3,
                    requestDescription,
                    createUserid: `${identity?.id}(${identity?.fullName})`,
                },
            },
            {
                onSuccess: (voc: Voc) => {
                    notify("ra.notification.created");
                    // increase the vocStatusOrder of all vocs in the same status as the new voc
                    dataProvider
                        .getList("voc", {
                            pagination: { page: 1, perPage: 1000 },
                            sort: { field: "id", order: "ASC" },
                            filter: { vocStatus: voc.vocStatus.code },
                        })
                        .then(({ data: vocs }) =>
                            Promise.all(
                                vocs
                                    .filter(oldVoc => oldVoc.id !== voc.id)
                                    .map(oldVoc =>
                                        dataProvider.update("voc", {
                                            id: oldVoc.id,
                                            data: {
                                                id: oldVoc.id,
                                                vocStatusOrder: oldVoc.vocStatusOrder + 1,
                                            },
                                            previousData: null,
                                        }),
                                    ),
                            ),
                        );
                    redirect("list", resource);
                },
            },
        );
    };

    return (
        <Create title={translate("voc.addVoc")} actions={<CreateActions />} redirect="list">
            <VocInputs handleSubmit={handleSubmit} />
        </Create>
    );
};
