import { useMediaQuery, Theme } from "@mui/material";
import {
    List,
    TextField as RaTextField,
    TopToolbar,
    Datagrid,
    useTranslate,
    FilterButton,
    SimpleList,
    TextInput,
    Button,
    useRedirect,
} from "react-admin";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

const noticeFilters = [<TextInput source="name" label={"이름"} />, <TextInput source="userid" label={"아이디"} />];

const ListActions = () => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <Button
                label={"title.corp_admin_bills_now"}
                children={<PointOfSaleIcon />}
                onClick={() => {
                    redirect("/corp_admin/bills");
                }}
            />
            <FilterButton />
        </TopToolbar>
    );
};

export const CorpAdminBillsDetailList = () => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

    const type = "roaming";
    let dynamicFields = null;
    if (type === "roaming") {
        dynamicFields = [
            <RaTextField
                source="create_userid"
                label={translate("common.roaming_corp")}
                sortable={false}
                textAlign="center"
            />,
            <RaTextField source="id" label={translate("common.account_card")} sortable={false} textAlign="center" />,
        ];
    } else {
        dynamicFields = [
            <RaTextField
                source="create_userid"
                label={translate("common.employee_number")}
                sortable={false}
                textAlign="center"
            />,
            <RaTextField
                source="id"
                label={translate("common.reservation_number")}
                sortable={false}
                textAlign="center"
            />,
        ];
    }

    return (
        <>
            <List
                title={"title.corp_admin_bills_detail"}
                filters={noticeFilters}
                sort={{ field: "id", order: "DESC" }}
                actions={<ListActions />}
                empty={false}
            >
                {isXSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.writer}
                        tertiaryText={record => new Date(+record.create_at).toISOString()}
                        linkType="show"
                    />
                ) : (
                    <Datagrid rowClick="" bulkActionButtons={false}>
                        <RaTextField source="id" label={"common.idx"} sortable={false} textAlign="center" />
                        {dynamicFields}
                        <RaTextField source="create_userid" label={"common.name"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.id"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.station_name"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.station_id"} sortable={false} textAlign="center" />
                        <RaTextField
                            source="id"
                            label={"common.charge_amount_kw"}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField source="id" label={"common.usage_fee_won"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.charge_time"} sortable={false} textAlign="center" />
                    </Datagrid>
                )}
            </List>
        </>
    );
};
