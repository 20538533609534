import { keycloak } from "@src/auth/keycloak";
import setLocales from "@src/configuration/setLocales";
import { fetchUtils, DataProvider } from "ra-core";
import { stringify } from "query-string";

type ErrorsFromServer = { status: string; message: string }[];

const handleErrorsFromServer = (errors: ErrorsFromServer) => {
    const { translate } = setLocales;
    errors.map(error => {
        let message = error.message;
        // let forceLogout = false;
        if (error.message === "User not Authenticated") {
            message = translate("notification.auth_failed");
            // forceLogout = true;
        } else if (error.message.startsWith("User is not authorized")) {
            message = translate("ra.notification.not_authorized");
            // forceLogout = true;
        }
        // if (forceLogout) {
        //     setTimeout(function () {
        //         keycloak.logout();
        //     }, 2000);
        // }
        throw new Error(message);
    });
};

export const useRestDataProvider = () => {
    const apiUrl = process.env.REACT_APP_BACKEND_SERVER_URI;
    const httpClient = fetchUtils.fetchJson;
    const countHeader = "Content-Range";

    const dataProvider: DataProvider = {
        getList: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const rangeStart = (page - 1) * perPage;
            const rangeEnd = page * perPage - 1;

            const query = {
                sortField: field,
                sortOrder: order,
                page,
                perPage,
                filter: JSON.stringify(params.filter),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            const options = {
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                    // Chrome doesn"t return `Content-Range` header if no `Range` is provided in the request.
                    ...(countHeader === "Content-Range" && { Range: `${resource}=${rangeStart}-${rangeEnd}` }),
                }),
            };

            return httpClient(url, options).then(response => {
                const { headers, json } = response;

                if (!headers.has(countHeader)) {
                    throw new Error(
                        `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
                    );
                }

                let total = 0;
                if (headers) {
                    if (countHeader === "Content-Range") {
                        const count = headers.get("content-range")?.split("/").pop();
                        if (count) {
                            total = parseInt(count, 10);
                        }
                    } else {
                        const count = headers.get((countHeader as string).toLowerCase());
                        if (count) {
                            total = parseInt(count);
                        }
                    }
                }
                return {
                    data: json,
                    total,
                };
            });
        },

        getOne: (resource, params) => {
            const options = {
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                }),
            };
            return httpClient(`${apiUrl}/${resource}/${params.id}`, options).then(({ json }) => ({
                data: json,
            }));
        },

        getMany: (resource, params) => {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            const options = {
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                }),
            };
            return httpClient(url, options).then(({ json }) => ({ data: json }));
        },

        getManyReference: (resource, params) => {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;

            const rangeStart = (page - 1) * perPage;
            const rangeEnd = page * perPage - 1;

            const query = {
                sortField: field,
                sortOrder: order,
                page,
                perPage,
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            const url = `${apiUrl}/${resource}?${stringify(query)}`;
            const options = {
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                    // Chrome doesn"t return `Content-Range` header if no `Range` is provided in the request.
                    ...(countHeader === "Content-Range" && { Range: `${resource}=${rangeStart}-${rangeEnd}` }),
                }),
            };

            return httpClient(url, options).then(({ headers, json }) => {
                if (!headers.has(countHeader)) {
                    throw new Error(
                        `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
                    );
                }
                let total = 0;
                if (headers) {
                    if (countHeader === "Content-Range") {
                        const count = headers.get("content-range")?.split("/").pop();
                        if (count) {
                            total = parseInt(count, 10);
                        }
                    } else {
                        const count = headers.get((countHeader as string).toLowerCase());
                        if (count) {
                            total = parseInt(count);
                        }
                    }
                }
                return {
                    data: json,
                    total,
                };
            });
        },

        update: (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: "PUT",
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),

        // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
        updateMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "PUT",
                        headers: new Headers({
                            authorization: `bearer ${localStorage.getItem("token")}`,
                        }),
                        body: JSON.stringify(params.data),
                    }),
                ),
            ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

        create: (resource, params) =>
            httpClient(`${apiUrl}/${resource}`, {
                method: "POST",
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                }),
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({ data: json })),

        delete: (resource, params) =>
            httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: "DELETE",
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "text/plain",
                }),
            }).then(({ json }) => ({ data: json })),

        // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: (resource, params) =>
            Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: "DELETE",
                        headers: new Headers({
                            authorization: `bearer ${localStorage.getItem("token")}`,
                            "Content-Type": "text/plain",
                        }),
                    }),
                ),
            ).then(responses => ({
                data: responses.map(({ json }) => json.id),
            })),

        getCount: (resource: string, params: any) => {
            const query = {
                count: true,
                filter: JSON.stringify(params.filter),
            };
            const url = `${apiUrl}/${resource}/count?${stringify(query)}`;
            const options = {
                headers: new Headers({
                    authorization: `bearer ${localStorage.getItem("token")}`,
                }),
            };

            return httpClient(url, options).then(response => {
                const { body } = response;

                return {
                    data: parseInt(body),
                };
            });
        },

        callExternalApi: (endpoint: string, body?: any, method = "POST") => {
            return fetch(`${process.env.REACT_APP_BACKEND_SERVER_URI}${endpoint}` as string, {
                method,
                headers: {
                    authorization: `bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                ...(body != null && { body: JSON.stringify(body) }),
            })
                .then(result => {
                    return result.json();
                })
                .then(result => {
                    if (result.errors) {
                        handleErrorsFromServer(result.errors);
                    }
                    return result; // { data: ..., status: ... }
                })
                .catch((error: Error) => {
                    return { data: null, message: error.message };
                });
        },
    };

    return dataProvider;
};
