import * as React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useTranslate } from "react-admin";
import EmailIcon from "@mui/icons-material/Email";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CampaignIcon from "@mui/icons-material/Campaign";
import VideocamIcon from "@mui/icons-material/Videocam";
import ChatIcon from "@mui/icons-material/Chat";
import ContactsIcon from "@mui/icons-material/Contacts";
import WorkIcon from "@mui/icons-material/Work";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";

const GroupwareMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/home/3241943554011587605");
                }}
            >
                <ListItemIcon>
                    <CampaignIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_notice_board`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/messenger");
                }}
            >
                <ListItemIcon>
                    <ChatIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_chatting`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/mail/systems/inbox");
                }}
            >
                <ListItemIcon>
                    <EmailIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_email`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/calendar");
                }}
            >
                <ListItemIcon>
                    <CalendarMonthIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_calendar`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/workflow/main");
                }}
            >
                <ListItemIcon>
                    <AssignmentIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_approval`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/contacts/organization");
                }}
            >
                <ListItemIcon>
                    <ContactsIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_contact`)}
                </Typography>
            </MenuItem>
            <MenuItem
                dense={dense}
                onClick={() => {
                    window.open("https://everon.dooray.com/meeting");
                }}
            >
                <ListItemIcon>
                    <VideocamIcon />
                </ListItemIcon>
                <Typography variant="inherit" color="textSecondary">
                    {translate(`title.groupware_meeting`)}
                </Typography>
            </MenuItem>
        </>
    );
};

export default GroupwareMenuItems;
