import * as React from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import { DashboardMenuItem, MenuProps, usePermissions, useSidebarState } from "react-admin";
import { MenuName } from "./types";
import { EveronMenu } from "./menu/everon/EveronMenu";
import { CorpAdminMenu } from "./menu/corp_admin/CorpAdminMenu";

const Menu = ({ dense = true }: MenuProps) => {
    const { isLoading, permissions } = usePermissions();
    const [menuOpenState, setMenuOpenState] = useState({
        // 에버온 메뉴
        groupware: false,
        crm: false,
        control_cp: false,
        repair: false,
        customer: false,
        call_center: false,
        commission: false,
        calculation: false,
        info_retrieval: false,
        ceo: false,
        system: false,
        voc: false,
        service_mgmt: false,

        // 법인 관리자 메뉴
        corp_admin_notice: false,
        corp_admin_accounts: false,
        corp_admin_bills: false,
    });
    const [open] = useSidebarState();

    const handleMenuToggle = React.useCallback((menu: MenuName) => {
        setMenuOpenState(menuOpenState => ({ ...menuOpenState, [menu]: !menuOpenState[menu] }));
    }, []);

    if (isLoading) return null;

    return (
        <Box
            sx={{
                width: (theme: any) => {
                    if (open) {
                        return theme?.sidebar ? theme.sidebar : 240;
                    } else {
                        return 50;
                    }
                },
                transition: (theme: any) =>
                    theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {/* @ts-ignore */}
            {permissions?.groupware?.access && (
                <DashboardMenuItem sx={{ pl: "12px", minHeight: "32px", paddingTop: "4px", paddingBottom: "4px" }} />
            )}
            {/* Everon 메뉴 */}
            {permissions.everon?.access && (
                <EveronMenu menuOpenState={menuOpenState} dense={dense} handleMenuToggle={handleMenuToggle} />
            )}

            {/* 법인 관리자 */}
            {permissions.corp_admin?.access && (
                <CorpAdminMenu menuOpenState={menuOpenState} dense={dense} handleMenuToggle={handleMenuToggle} />
            )}
        </Box>
    );
};

export default Menu;
