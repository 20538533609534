import React, { useRef } from "react";
import { useState } from "react";
import {
    useRecordContext,
    useCreate,
    useNotify,
    useGetIdentity,
    useResourceContext,
    useTranslate,
    useListContext,
    Form,
} from "react-admin";
import { Button, Box } from "@mui/material";
import { StatusSelector } from "./StatusSelector";
import { RichTextInputWithImageAttachment } from "@components/inputs/RichTextInputWithImageAttachment";
import { fontFamily } from "@src/layout/themes";
import { foreignKeyMapping } from "./Note";
import { NoteReferences } from "./NotesIterator";

export const NewNote = ({ showStatus, reference }: { showStatus?: boolean; reference: NoteReferences }) => {
    const record = useRecordContext();
    const resource = useResourceContext();
    const [text, setText] = useState("");
    const [status, setStatus] = useState(record?.noteStatus?.code ?? "STATUS_DECENT");
    const [create, { isLoading }] = useCreate();
    const { refetch } = useListContext();
    const notify = useNotify();
    const [editor, setEditor] = useState<any>(null);
    const { identity } = useGetIdentity();
    const translate = useTranslate();
    if (!record || !identity) return null;
    const handleSubmit = (data: any) => {
        create(
            resource,
            {
                data: {
                    [foreignKeyMapping[reference]]: data.id,
                    createUserid: `${identity?.id}(${identity?.fullName})`,
                    note: text,
                    ...(showStatus && { noteStatus: "NOTE_STATUS." + status }),
                },
            },
            {
                onSuccess: () => {
                    if (editor && editor.commands) {
                        editor.commands.clearContent();
                        editor.commands.setFontFamily(fontFamily);
                    }
                    setText("");
                    notify("ra.notification.created");
                    refetch();
                },
            },
        );
        return false;
    };

    const handleChange = (value: any) => setText(value);

    return (
        <Box mt={4} mb={1}>
            <Form onSubmit={handleSubmit}>
                <RichTextInputWithImageAttachment
                    label={false}
                    name="note"
                    source="note"
                    defaultValue=""
                    setEditor={setEditor}
                    handleChange={handleChange}
                />
                <Box display="flex" justifyContent="end" mt={1}>
                    <span>
                        {text ? (
                            <>
                                {showStatus && (
                                    <StatusSelector
                                        status={status}
                                        setStatus={setStatus}
                                        sx={{
                                            marginRight: "1em",
                                            "& .MuiFilledInput-input": {
                                                paddingTop: "10px",
                                            },
                                            width: 150,
                                        }}
                                    />
                                )}
                            </>
                        ) : null}
                    </span>
                    <Button type="submit" variant="contained" color="primary" disabled={!text || isLoading}>
                        {translate("crm.addNote")}
                    </Button>
                </Box>
            </Form>
        </Box>
    );
};
