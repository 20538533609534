import React from "react";
import { FunctionField, TextField, useRecordContext, useTheme } from "react-admin";
import { Card, Box, Typography, useMediaQuery, Theme } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { VocAvatar } from "./VocAvatar";
import { CommonCodeDesc } from "@src/types";
import { format } from "date-fns";
import { getUserName } from "@src/utils/utils";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const VocCard = ({ index = 0, setShowInfo = null }: { index?: number; setShowInfo?: any }) => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const record = useRecordContext();
    const [theme] = useTheme();
    if (!record) return null;

    const handleClick = () => {
        if (setShowInfo) {
            setShowInfo({
                open: true,
                id: record.id,
            });
        }
    };

    return (
        <Draggable draggableId={String(record.id)} index={index}>
            {(provided, snapshot) => (
                <Box
                    sx={{ marginBottom: 1 }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleClick}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging ? "rotate(-2deg)" : "",
                        }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                        <Box
                            padding={1}
                            sx={{
                                display: "flex",
                                flexDirection: isDesktop ? "row" : "column",
                                alignItems: isDesktop ? "flex-start" : "center",
                                ...(record.requestTitle.indexOf("★") != -1 && {
                                    background: theme?.palette?.mode == "dark" ? "#614642" : "#ffdada",
                                }),
                            }}
                        >
                            <VocAvatar dept={record?.responseDepartment?.code} />
                            <Box sx={{ marginLeft: 1, width: "100%" }}>
                                <Box>
                                    <Typography component="div" variant="body2" gutterBottom>
                                        <ResponsiveEllipsis
                                            text={record.requestTitle}
                                            ellipsis="..."
                                            basedOn="letters"
                                            trimRight
                                        />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="textSecondary">
                                        <FunctionField
                                            render={({ requestCategory1 }: { requestCategory1: CommonCodeDesc }) => {
                                                return `${requestCategory1.codeName}관련`;
                                            }}
                                        />
                                        <br />
                                        {format(new Date(record.inboundAt), "yyyy-MM-dd HH:mm:ss")}
                                        <br />
                                        고객명 : {record.customerName}
                                        <br />
                                        등록자 : {getUserName(record.createUserid)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            )}
        </Draggable>
    );
};
