import React from "react";
import { useRedirect, useGetIdentity, Identifier, Edit, useResourceContext, useRefresh, useNotify } from "react-admin";
import { Dialog } from "@mui/material";
import { DealInput } from "./DealInput";
import { getCommonCode } from "@src/utils/utils";

export const DealEdit = ({ open, id }: { open: boolean; id?: Identifier }) => {
    const { identity } = useGetIdentity();
    const redirect = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const resource = useResourceContext();

    if ((id as string) === "create") return null;

    const handleClose = () => {
        redirect("/crm/deal");
    };
    const onSuccess = () => {
        notify("ra.notification.updated");
        redirect("/crm/deal");
        refresh();
    };

    if (!identity) return null;

    return !!id ? (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <Edit
                id={id}
                title=" "
                resource={resource}
                sx={{ "& .RaEdit-main": { mt: 0 } }}
                transform={({
                    id,
                    client_id,
                    deal_title,
                    deal_description,
                    deal_status,
                    deal_status_order,
                    budget,
                }: any) => {
                    return {
                        dealInput: {
                            id,
                            client_id,
                            deal_title,
                            deal_description,
                            deal_status: "CRM_DEAL_STATUS." + getCommonCode(deal_status),
                            deal_status_order,
                            budget: +budget,
                            update_userid: `${identity?.id}(${identity?.fullName})`,
                        },
                    };
                }}
                mutationMode="pessimistic"
                mutationOptions={{ onSuccess }}
            >
                <DealInput />
            </Edit>
        </Dialog>
    ) : null;
};
