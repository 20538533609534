import { Box } from "@mui/material";
import { useEffect } from "react";
import { Button, usePermissions, useRedirect } from "react-admin";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import DashboardIcon from "@mui/icons-material/Dashboard";

const EveronDashboard = () => {
    const { isLoading, permissions } = usePermissions();
    const redirect = useRedirect();
    const useGroupware = permissions?.groupware?.access;
    const doorayUrl = "https://everon.dooray.com/home";

    useEffect(() => {
        if (isLoading) return;
        if (useGroupware) {
            window.open(doorayUrl);
        }
    }, [isLoading, useGroupware]);

    if (isLoading) return null;

    return (
        <Box
            sx={{
                textAlign: "center",
                border: "none",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
            }}
        >
            {useGroupware ? (
                <Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <img src="/assets/light_logo.png" width="120px" />
                        <h3 style={{ padding: "10px" }}> X </h3>
                        <img src="/assets/logo_dooray.png" width="120px" />
                    </Box>
                    <h4 style={{ opacity: 0.5 }}>에버온은 그룹웨어 Dooray!와 함께합니다</h4>
                </Box>
            ) : (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src="/assets/light_logo.png" width="120px" />
                </Box>
            )}
            <div style={{ marginTop: "2em" }}>
                {useGroupware ? (
                    <Button
                        onClick={() => {
                            window.open(doorayUrl);
                        }}
                        label="action.go_home"
                        variant="contained"
                        size="medium"
                        children={<DashboardIcon />}
                    />
                ) : (
                    <Button
                        onClick={() => {
                            redirect("list", "voc");
                        }}
                        label="action.go_voc"
                        variant="contained"
                        size="medium"
                        children={<RecordVoiceOverIcon />}
                    />
                )}
            </div>
        </Box>
    );
};

export default EveronDashboard;
