import { MenuItemLink, usePermissions, useTranslate } from "react-admin";
import SubMenu from "@src/layout/SubMenu";
import {
    CalculationMenuItems,
    CallCenterMenuItems,
    CommissionMenuItems,
    ControlCpMenuItems,
    CustomerMenuItems,
    RepairMenuItems,
    SystemMenuItems,
    CrmMenuItems,
    VocMenuItems,
    CeoMenuItems,
    GroupwareMenuItems,
    InfoRetrievalMenuItems,
} from "./submenus";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import InsightsIcon from "@mui/icons-material/Insights";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import HandymanIcon from "@mui/icons-material/Handyman";
import EvStationIcon from "@mui/icons-material/EvStation";
import GroupIcon from "@mui/icons-material/Group";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import WebIcon from "@mui/icons-material/Web";
import ServiceMgmtMenuItems from "./submenus/ServiceMgmtMenuItems";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";

export const EveronMenu = ({ dense, handleMenuToggle, menuOpenState }: any) => {
    const translate = useTranslate();
    const { isLoading, permissions } = usePermissions();

    if (isLoading) return null;

    return (
        <>
            {permissions.groupware?.access && (
                <>
                    <SubMenu
                        name="groupware"
                        handleMenuToggle={handleMenuToggle}
                        isOpen={menuOpenState.groupware}
                        icon={<GroupsIcon />}
                        dense={dense}
                    >
                        <GroupwareMenuItems dense={dense} />
                    </SubMenu>
                    {/* <SubMenu
                        name="info_retrieval"
                        handleMenuToggle={handleMenuToggle}
                        isOpen={menuOpenState.info_retrieval}
                        icon={<NewspaperIcon />}
                        dense={dense}
                    >
                        <InfoRetrievalMenuItems dense={dense} />
                    </SubMenu> */}
                </>
            )}
            {/* {permissions.crm?.access && (
                <SubMenu
                    name="crm"
                    handleMenuToggle={handleMenuToggle}
                    isOpen={menuOpenState.crm}
                    icon={<SupervisedUserCircleIcon />}
                    dense={dense}
                >
                    <CrmMenuItems dense={dense} />
                </SubMenu>
            )} */}
            {permissions.voc?.access && (
                <SubMenu
                    name="voc"
                    handleMenuToggle={handleMenuToggle}
                    isOpen={menuOpenState.voc}
                    icon={<RecordVoiceOverIcon />}
                    dense={dense}
                >
                    <VocMenuItems dense={dense} />
                </SubMenu>
            )}
            {permissions.ceo?.access && (
                <SubMenu
                    name="ceo"
                    handleMenuToggle={handleMenuToggle}
                    isOpen={menuOpenState.ceo}
                    icon={<InsightsIcon />}
                    dense={dense}
                >
                    <CeoMenuItems dense={dense} />
                </SubMenu>
            )}
            {/* {permissions.service_mgmt?.access && (
                <SubMenu
                    name="service_mgmt"
                    handleMenuToggle={handleMenuToggle}
                    isOpen={menuOpenState.service_mgmt}
                    icon={<HandymanIcon />}
                    dense={dense}
                >
                    <ServiceMgmtMenuItems dense={dense} />
                </SubMenu>
            )} */}
            {permissions.system?.access && (
                <SubMenu
                    name="system"
                    handleMenuToggle={handleMenuToggle}
                    isOpen={menuOpenState.system}
                    icon={<SettingsIcon />}
                    dense={dense}
                >
                    <SystemMenuItems dense={dense} />
                </SubMenu>
            )}

            {/* <SubMenu
                name="controlCp"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.controlCp}
                icon={<EvStationIcon />}
                dense={dense}
            >
                <ControlCpMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="customer"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.customer}
                icon={<GroupIcon />}
                dense={dense}
            >
                <CustomerMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="repair"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.repair}
                icon={<DynamicFormIcon />}
                dense={dense}
            >
                <RepairMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="callCenter"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.callCenter}
                icon={<SupportAgentIcon />}
                dense={dense}
            >
                <CallCenterMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="commission"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.commission}
                icon={<PointOfSaleIcon />}
                dense={dense}
            >
                <CommissionMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="calculation"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.calculation}
                icon={<AttachMoneyIcon />}
                dense={dense}
            >
                <CalculationMenuItems dense={dense} />
            </SubMenu>
             */}
        </>
    );
};
