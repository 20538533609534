import PersonIcon from "@mui/icons-material/Person";
import { ChartBox } from "@src/components/charts/ChartBox";
import { BarChart } from "@src/components/charts/BarChart";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { useDataProvider } from "react-admin";
import { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";

export const AccountsChart = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);
    const keys = useRef<string[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data } = await dataProvider.callExternalApi("/analytics/accounts", null, "GET");
            const accByDate = data.reduce((acc: any, item: any) => {
                return {
                    ...acc,
                    [item.yyyymm]: {
                        ...acc[item.yyyymm],
                        [item.bid]: item.memcnt_over,
                    },
                };
            }, {});
            const annualData = Object.keys(accByDate).map((date: any) => {
                if (keys.current.length === 0) {
                    keys.current = Object.keys(accByDate[date]);
                }
                return {
                    date: date.substring(2),
                    ...accByDate[date],
                };
            });

            setData(annualData);
        };
        setChartData();
    }, []);

    return (
        <ChartBox
            titleIcon={<PersonIcon color="disabled" fontSize="large" />}
            titleText="ceo.account_chart"
            chart={
                data.length > 0 ? (
                    <BarChart
                        enableGridY={false}
                        enableGridX={true}
                        data={data}
                        keys={keys.current}
                        axisBottom={{
                            legendPosition: "middle",
                            legendOffset: 50,
                            tickSize: 0,
                            tickPadding: 12,
                            tickRotation: 40,
                        }}
                        axisLeft={{
                            format: (v: any) => `${Math.abs(v / 10000)}만`,
                            tickValues: 8,
                        }}
                        margin={{ top: 30, right: 100, bottom: 50, left: 50 }}
                        tooltip={({ id, value, color }: any) => {
                            return <ChartTooltip id={id} color={color} content={`${value.toLocaleString()}명`} />;
                        }}
                        legends={[
                            {
                                anchor: "bottom-right",
                                dataFrom: "keys",
                                direction: "column",
                                itemHeight: 20,
                                itemWidth: 90,
                                toggleSerie: true,
                                translateX: 100,
                            },
                        ]}
                    />
                ) : (
                    <CircularProgress />
                )
            }
        />
    );
};
