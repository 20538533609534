import * as React from "react";
import { useState, ChangeEvent } from "react";
import {
    ShowBase,
    ShowProps,
    ReferenceManyField,
    useShowContext,
    useRecordContext,
    useListContext,
    TopToolbar,
    ListButton,
    useTranslate,
    Title,
    EditButton,
    RecordContextProvider,
    FunctionField,
    useGetIdentity,
    usePermissions,
} from "react-admin";
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Tabs,
    Tab,
    Divider,
    useMediaQuery,
    Theme,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";

import { ContactAvatar } from "../contacts/ContactAvatar";
import { ClientAside } from "./ClientAside";
import { CrmClient, CrmDeal, CrmContact } from "../types";
import { ClientAvatar } from "./ClientAvatar";
import { CommonCodeDesc } from "@src/types";

export const ClientShow = (props: ShowProps) => (
    <ShowBase {...props}>
        <ClientShowContent />
    </ShowBase>
);

const ClientShowContent = () => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const { record, isLoading } = useShowContext<CrmClient>();
    const [value, setValue] = useState(0);
    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };
    if (isLoading || !record || !identity) return null;
    if (isLoadingForPemission) return null;

    return (
        <>
            <Title title={translate("title.crm_client")} />
            <TopToolbar>
                {permissions.crm?.update && <EditButton label={translate("crm.editClient")} />}
                <ListButton />
            </TopToolbar>
            <Box display="flex">
                <Box flex="1">
                    <Card>
                        <CardContent>
                            <Box display="flex" mb={1}>
                                <ClientAvatar />
                                <Box ml={2} flex="1">
                                    <Typography variant="h4">{record.client_name}</Typography>
                                    <Typography variant="body2" color="textSecondary" component="span">
                                        <FunctionField
                                            source="client_type"
                                            render={({ codeDescription }: CommonCodeDesc) => {
                                                return codeDescription;
                                            }}
                                        />
                                    </Typography>{" "}
                                </Box>
                            </Box>
                            <Box mt={2} mb={2}>
                                {!isDesktop && (
                                    <>
                                        <Box>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("crm.clientInfo")}
                                            </Typography>{" "}
                                        </Box>
                                        <Divider />
                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.address")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.address}" {record.address_detail}
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.tel_number")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.tel_number}
                                            </Typography>
                                        </Box>
                                        <Box mt={1}>
                                            <Typography variant="body2" color="textSecondary" component="span">
                                                {translate("common.bigo_note")}
                                            </Typography>{" "}
                                            <Typography variant="body2" color="textPrimary" component="span">
                                                {record.bigo_note}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange}>
                                {record.id && <Tab label={translate("common.contacts")} />}
                                {record.id && <Tab label={translate("crm.deals")} />}
                            </Tabs>
                            <Divider />
                            <TabPanel value={value} index={0}>
                                <ReferenceManyField
                                    label={translate("common.contacts")}
                                    reference="crm/contact"
                                    target="client_id"
                                    sort={{ field: "contact_name", order: "ASC" }}
                                >
                                    <ContactsIterator />
                                </ReferenceManyField>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ReferenceManyField
                                    label={translate("crm.deals")}
                                    reference="crm/deal"
                                    target="client_id"
                                    sort={{ field: "deal_title", order: "ASC" }}
                                >
                                    <DealsIterator />
                                </ReferenceManyField>
                            </TabPanel>
                        </CardContent>
                    </Card>
                </Box>
                {isDesktop && <ClientAside />}
            </Box>
        </>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
};

const ContactsIterator = () => {
    const { data: contacts, isLoading } = useListContext<CrmContact>();
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (isLoading || isLoadingForPemission) return null;
    return (
        <Box>
            <List>
                {contacts.map(contact => (
                    <RecordContextProvider key={contact.id} value={contact}>
                        <ListItem button component={RouterLink} to={`/crm/contact/${contact.id}/show`}>
                            <ListItemAvatar>
                                <ContactAvatar />
                            </ListItemAvatar>
                            <ListItemText primary={`${contact.contact_name}`} secondary={`${contact.job_title}`} />
                            <ListItemSecondaryAction>
                                <Typography variant="body2" color="textSecondary" component="span">
                                    {format(
                                        new Date(Number(contact.update_at ? contact.update_at : contact.create_at)),
                                        "yyyy-MM-dd hh:mm:ss",
                                    )}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
            {permissions.crm?.create && (
                <Box textAlign="center" mt={1}>
                    <CreateRelatedContactButton />
                </Box>
            )}
        </Box>
    );
};

const CreateRelatedContactButton = () => {
    const translate = useTranslate();
    const client = useRecordContext<CrmClient>();
    return (
        <Button
            component={RouterLink}
            to="/crm/contact/create"
            state={{ record: { client_id: client.id } }}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<PersonAddIcon />}
        >
            {translate("crm.addContact")}
        </Button>
    );
};

const DealsIterator = () => {
    const { data: deals, isLoading } = useListContext<CrmDeal>();
    const translate = useTranslate();
    const { permissions, isLoading: isLoadingForPemission } = usePermissions();
    if (isLoadingForPemission || isLoading) return null;
    return (
        <Box>
            <List>
                {deals.map(deal => (
                    <RecordContextProvider key={deal.id} value={deal}>
                        <ListItem button component={RouterLink} to={`/crm/deal/${deal.id}/show`}>
                            <ListItemText
                                primary={`${deal.deal_title}`}
                                secondary={
                                    `${deal.deal_status.codeDescription}, ` +
                                    Number(deal.budget).toLocaleString("ko-KR", {
                                        notation: "compact",
                                        style: "currency",
                                        currency: "KRW",
                                        currencyDisplay: "narrowSymbol",
                                        minimumSignificantDigits: 3,
                                    })
                                }
                            />
                            <ListItemSecondaryAction>
                                <Typography variant="body2" color="textSecondary" component="span">
                                    {format(
                                        new Date(Number(deal.update_at ? deal.update_at : deal.create_at)),
                                        "yyyy-MM-dd hh:mm:ss",
                                    )}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
            {permissions.crm?.create && (
                <Box textAlign="center" mt={1}>
                    <CreateRelatedDealButton />
                </Box>
            )}
        </Box>
    );
};

const CreateRelatedDealButton = () => {
    const translate = useTranslate();
    const deal = useRecordContext<CrmDeal>();
    return (
        <Button
            component={RouterLink}
            to="/crm/deal/create"
            state={{ record: { client_id: deal.id } }}
            color="primary"
            variant="contained"
            size="small"
            startIcon={<AddCircleOutlineIcon />}
        >
            {translate("crm.addDeal")}
        </Button>
    );
};
