import React, { useEffect, useState } from "react";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import { useDataProvider } from "react-admin";
import { BarChart } from "@src/components/charts/BarChart";
import { ChartBox } from "@src/components/charts/ChartBox";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { CircularProgress } from "@mui/material";

export const ChargeAmountsChart = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data } = await dataProvider.callExternalApi("/analytics/monthlyChargeAmount", null, "GET");
            const items = data.reduce((acc: any, item: any) => {
                return {
                    ...acc,
                    [item.basedate]: {
                        ...acc[item.basedate],
                        [item.bid]: item.charge,
                    },
                };
            }, {});
            const annualData = Object.keys(items).map((date: any) => {
                return {
                    date: date.substring(2),
                    로밍: items[date].로밍 ? items[date].로밍 : 0,
                    에버온: items[date].에버온 ? items[date].에버온 : 0,
                };
            });
            setData(annualData);
        };
        setChartData();
    }, []);
    return (
        <ChartBox
            titleIcon={<BatteryChargingFullIcon color="disabled" fontSize="large" />}
            titleText="dashboard.charge_amounts_statistic"
            chart={
                data.length > 0 ? (
                    <BarChart
                        data={data}
                        keys={["로밍", "에버온"]}
                        colors={["#61cdbb", "#97e3d5"]}
                        axisRight={{
                            format: (v: any) => `${Math.abs(v / 1000000).toLocaleString()}GWh`,
                            tickValues: 8,
                        }}
                        axisBottom={{
                            legendPosition: "middle",
                            legendOffset: 50,
                            tickSize: 0,
                            tickPadding: 12,
                            tickRotation: 40,
                        }}
                        tooltip={({ id, value, color }: any) => {
                            return (
                                <ChartTooltip
                                    id={id}
                                    color={color}
                                    content={`${Math.floor(+value / 1000).toLocaleString()}MWh`}
                                />
                            );
                        }}
                    />
                ) : (
                    <CircularProgress />
                )
            }
        />
    );
};
