import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { TextField, MenuItem } from "@mui/material";
import { Status } from "@src/components/notes/Status";

export const StatusSelector = ({ status, setStatus, sx, statusTooltip }: any) => {
    const [commonCode, setCommonCode] = useState<any[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        const initSelectData = async () => {
            const response = await dataProvider.getList("CommonCode", {
                pagination: { page: 1, perPage: 10 },
                sort: { field: "codeOrder", order: "ASC" },
                filter: { parentCode: "NOTE_STATUS" },
            });
            setCommonCode(response.data);
        };
        initSelectData();
        setStatus(status);
    }, []);
    if (commonCode.length === 0) return null;

    return (
        <TextField
            required
            select
            value={status}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setStatus(event.target.value);
            }}
            margin="none"
            size="small"
            sx={sx}
        >
            {commonCode.map(item => (
                <MenuItem key={item.id} value={item.code}>
                    <Status status={item.code} statusTooltip={statusTooltip} />
                    {`  ${item.code_name}`}
                </MenuItem>
            ))}
        </TextField>
    );
};
