import React from "react";
import { Show, useTranslate, TopToolbar, ListButton, useRecordContext, RichTextField } from "react-admin";
import { Box, Typography } from "@mui/material";
import { TypographWithIcon } from "@src/components/TypographWithIcon";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import HelpIcon from "@mui/icons-material/Help";

const ShowActions = ({ permissions, data, basePath }: any) => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

const CorpAdminQnaShowContent = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ padding: "20px 24px" }} mb={4}>
                <TypographWithIcon
                    icon={<HelpIcon color="disabled" />}
                    variant="h4"
                    sx={{ mb: 2 }}
                    text={translate("common.question")}
                />
                <Box ml={1}>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("common.title")}
                        </Typography>
                        <Typography variant="subtitle1">{record.title}</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" mb={2}>
                        <Box mr={2}>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.create_at")}
                            </Typography>
                            <Typography variant="subtitle2">2022-00-00</Typography>
                        </Box>
                        <Box mr={2}>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.create_userid")}
                            </Typography>
                            <Typography variant="subtitle2">테스트 글쓴이</Typography>
                        </Box>
                        <Box>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.category")}
                            </Typography>
                            <Typography variant="subtitle2">테스트/분류</Typography>
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <RichTextField source="content" />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ padding: "20px 24px" }}>
                <TypographWithIcon
                    icon={<QuestionAnswerIcon color="disabled" />}
                    variant="h4"
                    sx={{ mb: 2 }}
                    text={translate("common.answer")}
                />
                <Box ml={1}>
                    <Box display="flex" flexDirection="row" mb={2}>
                        <Box mr={2}>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.create_at")}
                            </Typography>
                            <Typography variant="subtitle2">2022-00-00</Typography>
                        </Box>
                        <Box>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.create_userid")}
                            </Typography>
                            <Typography variant="subtitle2">테스트 글쓴이</Typography>
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <RichTextField source="content" />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export const CorpAdminQnaShow = () => {
    const translate = useTranslate();
    return (
        <Show title={translate("title.customer_qna")} actions={<ShowActions />}>
            <CorpAdminQnaShowContent />
        </Show>
    );
};
