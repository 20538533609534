import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { ChartBox } from "@src/components/charts/ChartBox";
import { BarChart } from "@src/components/charts/BarChart";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { useDataProvider } from "react-admin";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export const IncomeChart = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data } = await dataProvider.callExternalApi("/analytics/income", null, "GET");
            const items = data.reduce((acc: any, item: any) => {
                return {
                    ...acc,
                    [item.yyyymm]: {
                        ...acc[item.yyyymm],
                        [item.bid]: item.amtsum,
                    },
                };
            }, {});
            const annualData = Object.keys(items).map((date: any) => {
                return {
                    date: date.substring(2),
                    로밍: items[date].로밍 ? items[date].로밍 : 0,
                    에버온: items[date].에버온 ? items[date].에버온 : 0,
                };
            });
            setData(annualData);
        };
        setChartData();
    }, []);

    return (
        <ChartBox
            titleIcon={<AttachMoneyIcon color="disabled" fontSize="large" />}
            titleText="ceo.income_chart"
            chart={
                data.length > 0 ? (
                    <BarChart
                        data={data}
                        keys={["로밍", "에버온"]}
                        colors={["#e8a838", "#f1e15b"]}
                        axisRight={{
                            format: (v: any) => `${Math.abs(v / 100000000)}억`,
                            tickValues: 8,
                        }}
                        axisBottom={{
                            legendPosition: "middle",
                            legendOffset: 50,
                            tickSize: 0,
                            tickPadding: 12,
                            tickRotation: 40,
                        }}
                        tooltip={({ id, value, color }: any) => {
                            return (
                                <ChartTooltip
                                    id={id}
                                    color={color}
                                    content={`${Math.floor(+value / 10000).toLocaleString()}만원`}
                                />
                            );
                        }}
                    />
                ) : (
                    <CircularProgress />
                )
            }
        />
    );
};
