import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Box, Card, CardContent, Grid, Theme, useMediaQuery } from "@mui/material";
import {
    useDataProvider,
    useNotify,
    Form,
    required,
    TextInput,
    regex,
    email,
    Toolbar,
    useTranslate,
    Button,
    SelectInput,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";

const DoorayAddMemberToolbar = forwardRef(({ loading }: any, ref) => {
    const { reset } = useFormContext();
    // 부모 컴포넌트에서 자식 컴포넌트의 reset 함수를 사용하기 위해 forwardRef, useImperativeHandle 사용
    // <부모에서 전달한 ref>.current.reset()으로 자식 컴포넌트의 함수 호출 가능
    useImperativeHandle(ref, () => ({
        reset,
    }));

    return (
        <Toolbar>
            <Button
                type="submit"
                variant="contained"
                label="title.dooray_add_member"
                children={<AddIcon />}
                disabled={loading}
            />
        </Toolbar>
    );
});

export const AddMemberInputs = ({
    members,
    departments,
    jobRanks: jobRankChoices,
    setRefetch,
}: {
    members: { emailId: string; doorayId: string }[];
    departments: { groupPath: string; name: string }[];
    jobRanks: { id: string; name: string }[];
    setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const resetRef = useRef();
    const [deptChoices, setDeptChoices] = useState<{ id: string; name: string }[]>([]);

    useEffect(() => {
        const depts = departments.map(dept => ({
            id: dept.groupPath,
            name: dept.name,
        }));
        setDeptChoices(depts);
    }, [departments]);

    const everonId = (everonId: any) => {
        if (everonId.indexOf("@") !== -1) {
            return "notification.account_mgmt.everonId_has_at";
        }

        const emailId = `${everonId}@everon.co.kr`;
        const filteredIds = members.filter(member => member.emailId === emailId);
        const IsAdded = filteredIds.length > 0;
        if (IsAdded) {
            return "notification.account_mgmt.id_exists";
        }
        if (everonId.length > 20) {
            return "notification.account_mgmt.id_maxLength";
        }
        return undefined;
    };
    const validateId = [required(), everonId];

    const handleSubmit = async (data: any) => {
        setLoading(true);
        const { everonId, name, groupPath, phone, officeTelNumber, externalEmailAddress, jobRankId } = data;
        const emailId = `${everonId}@everon.co.kr`;

        // 1. keycloak 계정 생성
        const response = await dataProvider.callExternalApi("/keycloak/addMember", {
            emailId,
            everonId,
            name,
            groupPath,
            // groupPath: "/everon",
        });

        if (response?.data !== 200) {
            if (response.data === 409) {
                notify("notification.account_mgmt.id_exists", { type: "warning" });
            } else {
                notify("notification.account_mgmt.fail_to_add_keycloak_id", { type: "warning" });
            }
            setLoading(false);
            return;
        }

        // // 2. Dooray 계정 생성
        const resAddMemberDooray = await dataProvider.callExternalApi("/dooray/addMember", {
            emailId,
            name,
            everonId,
            externalEmailAddress,
            phone,
            officeTelNumber,
            jobRankId,
        });

        if (resAddMemberDooray?.data !== 200) {
            notify("notification.account_mgmt.fail_to_add_dooray_id", { type: "warning" });
            setLoading(false);
            return;
        }

        // 3. Dooray 멤버 조회
        // POST response /admin/v1/members 로 반환되는 ID가 추가된 멤버 ID와 다른 Dooray API 이슈가 발견돼
        // 재조회해서 memberId를 설정하도록 함
        // const resMembers = await dataProvider.callExternalApi(
        //     `/dooray/getMembers?userCodeExact=${everonId}`,
        //     null,
        //     "GET",
        // );
        // if (resMembers?.data == null || resMembers?.data.length === 0) {
        //     notify("Dooray 계정 조회 실패", { type: "warning" });
        //     setLoading(false);
        //     return;
        // }
        // const memberId = resMembers.data[0].id;

        // // 4. Dooray 멤버 부서 등록
        // const departmentExternalKey = groupPath.substring(groupPath.lastIndexOf("/") + 1);
        // const addDoorayDeptBody = [
        //     {
        //         departmentId: "",
        //         departmentExternalKey,
        //         jobPositionId: "",
        //         primaryFlag: false,
        //         leaderFlag: false,
        //     },
        // ];
        // const resAddDepartmentDooray = await dataProvider.callExternalApi("/dooray/addDepartment", {
        //     memberId,
        //     addDoorayDeptBody,
        // });

        // if (resAddDepartmentDooray?.data != 200) {
        //     notify("notification.account_mgmt.fail_to_add_dooray_dept", { type: "warning" });
        //     setLoading(false);
        //     return;
        // }

        notify("notification.account_mgmt.succeed_in_adding_member", { type: "info" });

        // 회원 등록 후 Input들 초기화
        if (resetRef.current) {
            (resetRef.current as any).reset();
        }
        setLoading(false);
        setRefetch(true);
    };

    return (
        <Box display="flex">
            <Box flex="1" mb={isSmall ? "80px" : 0}>
                <Form onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <Box display="flex">
                                <Box flex="1" mt={-1}>
                                    <Box display="flex" width={isSmall ? "95%" : 400} mb={2}>
                                        <TextInput
                                            label={"common.everon_id"}
                                            disabled={loading}
                                            source="everonId"
                                            validate={validateId}
                                            fullWidth
                                            helperText={
                                                <>
                                                    1) 아이디는 영문 소문자, 숫자, 특수문자('.', '-', '_')만
                                                    사용가능합니다
                                                    <br />
                                                    2) 특수문자는 연속으로 사용할 수 없고 제일 앞과 제일 뒤에 사용할 수
                                                    없습니다
                                                    <br />
                                                    3) 최초 비밀번호는 아이디와 동일합니다
                                                </>
                                            }
                                        />
                                    </Box>
                                    <Box display="flex" width={isSmall ? "95%" : 200}>
                                        <TextInput
                                            label={"common.name"}
                                            disabled={loading}
                                            source="name"
                                            validate={[required()]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Grid container width={isSmall ? "95%" : 400}>
                                        <Grid item xs={12} sm={6} pr={isSmall ? 0 : 2}>
                                            <SelectInput
                                                label={"common.department"}
                                                disabled={loading}
                                                source="groupPath"
                                                choices={deptChoices}
                                                // validate={[required()]}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <SelectInput
                                                label={"common.jobRank"}
                                                disabled={loading}
                                                source="jobRankId"
                                                choices={jobRankChoices}
                                                // validate={[required()]}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box display="flex" width={isSmall ? "95%" : 400}>
                                        <TextInput
                                            label={"common.phone"}
                                            disabled={loading}
                                            source="phone"
                                            validate={[
                                                required(),
                                                regex(
                                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3,4})[-. ]*(\d{4})\s*$/,
                                                    translate("validation.tel_number"),
                                                ),
                                            ]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box display="flex" width={isSmall ? "95%" : 400}>
                                        <TextInput
                                            label={"common.external_email"}
                                            disabled={loading}
                                            source="externalEmailAddress"
                                            validate={[email()]}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box display="flex" width={isSmall ? "95%" : 400}>
                                        <TextInput
                                            label={"common.office_tel_number"}
                                            disabled={loading}
                                            source="officeTelNumber"
                                            validate={[
                                                regex(
                                                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3,4})[-. ]*(\d{4})\s*$/,
                                                    translate("validation.tel_number"),
                                                ),
                                            ]}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                        <DoorayAddMemberToolbar ref={resetRef} loading={loading} />
                    </Card>
                </Form>
            </Box>
        </Box>
    );
};
