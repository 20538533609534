import * as React from "react";
import { Edit, SimpleForm, TextInput, ListButton, TopToolbar, useTranslate, useGetIdentity } from "react-admin";
import { RichTextInputWithImageAttachment } from "@src/components/inputs/RichTextInputWithImageAttachment";
import { Box, Grid } from "@mui/material";
import { format } from "date-fns";
import { CorpAdminNoticeInputs } from "./CorpAdminNoticeInputs";

const EditActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const CorpAdminNoticeEdit = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();

    return (
        <Edit
            title={translate("title.customer_qna")}
            actions={<EditActions />}
            transform={({ title, content }: any) => ({
                noticeInput: {
                    title,
                    content,
                    create_userid: `${identity?.id}(${identity?.fullName})`,
                },
            })}
        >
            <CorpAdminNoticeInputs type="edit" />
        </Edit>
    );
};
