import { useMediaQuery, Theme } from "@mui/material";
import {
    List,
    TextField as RaTextField,
    TopToolbar,
    Datagrid,
    useTranslate,
    FilterButton,
    SimpleList,
    useRedirect,
    SelectInput,
} from "react-admin";
import { ButtonField } from "@src/components/fields";

const noticeFilters = [
    <SelectInput
        source="roaming"
        label="청구내역 구분"
        // TODO, 기본값 에버온 충전내역
        // emptyValue={}
        choices={[
            { id: "everon", name: "에버온" },
            { id: "roaming", name: "로밍" },
        ]}
        // defaultValue={"Y"}
        alwaysOn
    />,
];

const ListActions = () => {
    return (
        <TopToolbar>
            <FilterButton />
        </TopToolbar>
    );
};

export const CorpAdminBillsList = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

    return (
        <>
            <List
                title={translate("title.corp_admin_bills_now")}
                filters={noticeFilters}
                sort={{ field: "id", order: "DESC" }}
                actions={<ListActions />}
                empty={false}
            >
                {isXSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.writer}
                        tertiaryText={record => new Date(+record.create_at).toISOString()}
                        linkType="show"
                    />
                ) : (
                    <Datagrid rowClick="" bulkActionButtons={false}>
                        <RaTextField source="id" label={"common.idx"} sortable={false} textAlign="center" />
                        <ButtonField
                            variant="contained"
                            source="id"
                            label={"common.claim_date"}
                            btnText={translate("common.show_details")}
                            sortable={false}
                            textAlign="center"
                            fullWidth={false}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                redirect("/corp_admin/bills/detail");
                            }}
                        />
                        <RaTextField source="id" label={"common.usage_kw"} sortable={false} textAlign="center" />
                        <RaTextField
                            source="id"
                            label={"common.supply_price_won"}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField source="id" label={"common.vat_won"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.claim_fee_won"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.usage_fee_won"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.id"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.payment_type"} sortable={false} textAlign="center" />
                        <RaTextField source="id" label={"common.payment_method"} sortable={false} textAlign="center" />
                        <ButtonField
                            variant="contained"
                            source="id"
                            label={"common.pay"}
                            btnText={translate("common.print_recipt")}
                            sortable={false}
                            textAlign="center"
                            fullWidth={false}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                alert("승인");
                            }}
                        />
                        <RaTextField
                            source="id"
                            label={"common.payment_date_or_deposit_date"}
                            sortable={false}
                            textAlign="center"
                        />
                        <ButtonField
                            variant="contained"
                            source="id"
                            label={"common.bigo"}
                            btnText={"이메일 청구서 재요청"}
                            sortable={false}
                            textAlign="center"
                            fullWidth={false}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                alert("이메일 청구서 재요청");
                            }}
                        />
                    </Datagrid>
                )}
            </List>
        </>
    );
};
