import { Box, Typography } from "@mui/material";

export const TypographWithIcon = ({ icon, sx = {}, variant, text }: any) => {
    return (
        <Box sx={{ ...sx, display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex", marginRight: "8px" }}>
                <Box display="flex">{icon}</Box>
            </Box>
            <Typography sx={{ opacity: 0.65 }} variant={variant}>
                {" "}
                {text}
            </Typography>
        </Box>
    );
};
