import i18nProvider from "./configuration/setLocales";
import { Admin, ListGuesser, ShowGuesser, EditGuesser, Resource, CustomRoutes } from "react-admin";
import { useAuthProvider } from "./authProvider";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { Layout } from "./layout";
import { Box, CircularProgress } from "@mui/material";
import { lightTheme } from "./layout/themes";
import { getCrmContactResourceProps, getCrmClientResourceProps, getCrmDealResourceProps } from "./pages/crm";
import { getVocResourceProps } from "./pages/voc";
import { Route } from "react-router";
import { useRestDataProvider } from "./dataProvider/dataProvider";
import { newsResourceProps, lawResourceProps, bookmarkResourceProps } from "./pages/infoRetrieval";
import {
    getCorpAdminAccountApprovalResourceProps,
    getCorpAdminAccountPaymentResourceProps,
    getCorpAdminAccountsResourceProps,
    getCorpAdminBillsDetailResourceProps,
    getCorpAdminBillsResourceProps,
    getCorpAdminNoticeResourceProps,
    getCorpAdminQnaResourceProps,
} from "./pages/corp_admin";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak, onTokens } from "./auth/keycloak";
import NotFound from "./NotFound";
import { AccountManager } from "./pages/system/account_mgmt/AccountManager";
import CeoScreen from "./pages/ceo/CeoScreen";
import { CorpAdminCorpInfoEdit } from "./pages/corp_admin/accounts/CorpAdminCorpInfoEdit";

function App() {
    return (
        <ReactKeycloakProvider
            initOptions={{ onLoad: "login-required" }}
            authClient={keycloak}
            LoadingComponent={
                <Box sx={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                    <CircularProgress />
                </Box>
            }
            onTokens={onTokens}
        >
            <CustomAdminWithKeycloak />
        </ReactKeycloakProvider>
    );
}

const fetchResourcesAndCustomRoutes = (permissions: any) => {
    let routes: JSX.Element[] = [];
    let resources: JSX.Element[] = [<Resource name="CommonCode" />];

    if (permissions) {
        let keyIdx = 0;
        Object.keys(permissions).forEach(prop => {
            let allowedCustomRoutes: JSX.Element[] = [];
            let allowedResources: JSX.Element[] = [];

            switch (prop) {
                case "everon":
                    allowedResources = [
                        <Resource name="info_retrieval/news" {...newsResourceProps} />,
                        <Resource name="info_retrieval/law" {...lawResourceProps} />,
                        <Resource name="info_retrieval/bookmark" {...bookmarkResourceProps} />,
                    ];
                    allowedCustomRoutes = [...allowedCustomRoutes];
                    break;
                case "crm":
                    allowedResources = [
                        <Resource name="crm/deal" {...getCrmDealResourceProps(permissions)} />,
                        <Resource name="crm/contact" {...getCrmContactResourceProps(permissions)} />,
                        <Resource name="crm/client" {...getCrmClientResourceProps(permissions)} />,
                        <Resource name="crm/contact_note" />,
                        <Resource name="crm/deal_note" />,
                    ];
                    break;
                case "voc":
                    allowedResources = [
                        <Resource name="voc" {...getVocResourceProps(permissions)} />,
                        <Resource name="voc/note" />,
                    ];
                    break;
                case "account_mgmt":
                    allowedCustomRoutes = [
                        ...allowedCustomRoutes,
                        <Route key={keyIdx} path="/system/account_manager" element={<AccountManager />} />,
                    ];
                    break;
                case "ceo":
                    allowedCustomRoutes = [
                        ...allowedCustomRoutes,
                        <Route key={keyIdx} path="/ceo/dashboard" element={<CeoScreen />} />,
                    ];
                    break;

                // 법인 관리자용
                case "corp_admin": {
                    allowedResources = [
                        <Resource name="corp_admin/notice" {...getCorpAdminNoticeResourceProps(permissions)} />,
                        <Resource name="corp_admin/qna" {...getCorpAdminQnaResourceProps(permissions)} />,
                        <Resource name="corp_admin/accounts" {...getCorpAdminAccountsResourceProps(permissions)} />,
                        <Resource
                            name="corp_admin/accounts/payment"
                            {...getCorpAdminAccountPaymentResourceProps(permissions)}
                        />,
                        <Resource
                            name="corp_admin/accounts/approval"
                            {...getCorpAdminAccountApprovalResourceProps(permissions)}
                        />,
                        <Resource name="corp_admin/bills" {...getCorpAdminBillsResourceProps(permissions)} />,
                        <Resource
                            name="corp_admin/bills/detail"
                            {...getCorpAdminBillsDetailResourceProps(permissions)}
                        />,
                    ];
                    allowedCustomRoutes = [
                        ...allowedCustomRoutes,
                        <Route key={keyIdx} path="/corp_admin/corp_info" element={<CorpAdminCorpInfoEdit />} />,
                    ];
                    break;
                }
                case "corp_admin_mgmt": {
                    allowedResources = [
                        <Resource name="corp_admin/notice" {...getCorpAdminNoticeResourceProps(permissions)} />,
                        <Resource name="corp_admin/qna" {...getCorpAdminQnaResourceProps(permissions)} />,
                    ];
                    break;
                }
            }
            resources = [...resources, ...allowedResources];
            routes = [...routes, ...allowedCustomRoutes];
            keyIdx++;
        });
    }

    return [...resources, <CustomRoutes>{routes}</CustomRoutes>];
};

const CustomAdminWithKeycloak = () => {
    const authProvider = useAuthProvider();
    const dataProvider = useRestDataProvider();

    return (
        <Admin
            requireAuth
            catchAll={NotFound}
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
        >
            {fetchResourcesAndCustomRoutes}
        </Admin>
    );
};

export default App;
