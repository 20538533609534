import * as React from "react";
import { Edit, ListButton, TopToolbar } from "react-admin";
import { CorpAdminQnaInputs } from "./CorpAdminQnaInputs";

const EditActions = ({ basePath, data }: any) => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const CorpAdminQnaEdit = () => {
    return (
        <Edit title={"title.customer_qna"} actions={<EditActions />}>
            <CorpAdminQnaInputs type="edit" />
        </Edit>
    );
};
