import { Divider, Tab, Tabs, Theme, useMediaQuery } from "@mui/material";
import {
    Datagrid,
    List,
    TextField,
    FunctionField,
    Pagination,
    useListContext,
    ListContextProvider,
    useTranslate,
    RaRecord,
    useDataProvider,
} from "react-admin";
import { format } from "date-fns";
import { isValidDate } from "@src/utils/date";
import { Fragment, useCallback, useEffect, useState } from "react";
import MobileNewsGrid from "./MobileNewsGrid";
import { SearchInput } from "react-admin";
import { ExternalLinkField } from "@src/components/fields";
import { BookmarkField } from "@src/components/fields/BookmarkField";

const NewsPanel = ({ id, record, resource }: any) => <div dangerouslySetInnerHTML={{ __html: record.description }} />;
const NewsFields = [
    <ExternalLinkField label="common.title" sortable={false} source="title" textAlign="center" />,
    <FunctionField
        label="common.date"
        sortable={false}
        textAlign="center"
        render={(record: any) => {
            let date = new Date(record.pubDate);
            if (!isValidDate(date)) {
                // blog 날짜 응답 format이 "yyyyMMdd"여서 파싱 작업이 필요
                if (/^(\d){8}$/.test(record.pubDate)) {
                    const y = record.pubDate.substr(0, 4);
                    const m = record.pubDate.substr(4, 2) - 1;
                    const d = record.pubDate.substr(6, 2);
                    date = new Date(y, m, d);
                }
            }
            return format(date, "yyyy-MM-dd");
        }}
    />,
];

const BlogFields = [
    <TextField label="news.source" source="sourcename" sortable={false} textAlign="center" />,
    <ExternalLinkField label="common.title" source="title" sortable={false} textAlign="center" />,
    <FunctionField
        label="common.date"
        sortable={false}
        textAlign="center"
        render={(record: any) => {
            let date = new Date(record.pubDate);
            if (!isValidDate(date)) {
                // blog 날짜 응답 format이 "yyyyMMdd"여서 파싱 작업이 필요
                if (/^(\d){8}$/.test(record.pubDate)) {
                    const y = record.pubDate.substr(0, 4);
                    const m = record.pubDate.substr(4, 2) - 1;
                    const d = record.pubDate.substr(6, 2);
                    date = new Date(y, m, d);
                }
            }
            return format(date, "yyyy-MM-dd");
        }}
    />,
];

const CafeFields = [
    <TextField label="news.source" source="sourcename" sortable={false} textAlign="center" />,
    <ExternalLinkField label="common.title" source="title" sortable={false} textAlign="center" />,
];

const filters = [<SearchInput source="search" alwaysOn />];

export const NewsList = () => {
    return (
        <List
            title="title.ceo_news"
            actions={false}
            filters={filters}
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            filterDefaultValues={{ type: "news" }}
            empty={false}
        >
            <TabbedDatagrid />
        </List>
    );
};

const TabbedDatagrid = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const listContext = useListContext();
    const { data, filterValues, setFilters, displayedFilters, isLoading } = listContext;
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));
    const [news, setNews] = useState<RaRecord[]>([]);
    const [blog, setBlogposts] = useState<RaRecord[]>([]);
    const [cafe, setCafeposts] = useState<RaRecord[]>([]);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        switch (filterValues.type) {
            case "news":
                setNews(data);
                break;
            case "blog":
                setBlogposts(data);
                break;
            case "cafe":
                setCafeposts(data);
                break;
        }
    }, [data, isLoading, filterValues.type]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, type: value },
                    displayedFilters,
                    false, // no debounce, we want the filter to fire immediately
                );
        },
        [displayedFilters, filterValues, setFilters],
    );

    const selectedData = filterValues.type === "news" ? news : filterValues.type === "blog" ? blog : cafe;

    const tabs = [
        { id: "news", name: translate("news.news_feed") },
        { id: "blog", name: translate("news.blog_feed") },
        { id: "cafe", name: translate("news.cafe_feed") },
    ];

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.type}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab key={choice.id} label={choice.name} value={choice.id} />
                ))}
            </Tabs>
            <Divider />
            <ListContextProvider value={{ ...listContext, data: selectedData }}>
                {isXSmall ? (
                    <MobileNewsGrid type={filterValues.type} />
                ) : (
                    <Datagrid rowClick="expand" bulkActionButtons={false} expand={NewsPanel}>
                        <TextField label="common.index" source="id" sortable={false} textAlign="center" />
                        <BookmarkField
                            source="bookmarkId"
                            type={filterValues.type}
                            label=""
                            sortable={false}
                            textAlign="center"
                        />
                        {filterValues.type === "news" && NewsFields}
                        {filterValues.type === "blog" && BlogFields}
                        {filterValues.type === "cafe" && CafeFields}
                        <TextField
                            label="news.originallink"
                            source="originallink"
                            sortable={false}
                            textAlign="center"
                        />
                    </Datagrid>
                )}
            </ListContextProvider>
        </Fragment>
    );
};
