import * as React from "react";
import { Create, ListButton, TopToolbar, useTranslate, useGetIdentity } from "react-admin";
import { CorpAdminNoticeInputs } from "./CorpAdminNoticeInputs";

const CreateActions = () => (
    <TopToolbar>
        <ListButton />
    </TopToolbar>
);

export const CorpAdminNoticeCreate = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();

    return (
        <Create
            transform={({ title, content }: any) => ({
                noticeInput: {
                    title,
                    content,
                    create_userid: `${identity?.id}(${identity?.fullName})`,
                },
            })}
            title={translate("title.customer_qna")}
            actions={<CreateActions />}
        >
            <CorpAdminNoticeInputs type="create" />
        </Create>
    );
};
