import React from "react";
import {
    RichTextInput,
    DefaultEditorOptions,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
} from "ra-input-rich-text";
import { RichTextInputImageButton } from "./RichTextInputImageButton";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import FontFamily from "@tiptap/extension-font-family";
import { fontFamily } from "@src/layout/themes";

const TipTapEditorToolbar = () => {
    const size = "small";
    return (
        <RichTextInputToolbar>
            <LevelSelect size={size} />
            <FormatButtons size={size} />
            <ListButtons size={size} />
            <LinkButtons size={size} />
            <QuoteButtons size={size} />
            <ClearButtons size={size} />
            <RichTextInputImageButton size={size} />
        </RichTextInputToolbar>
    );
};

export const RichTextInputWithImageAttachment = ({
    label,
    name,
    source,
    defaultValue = "",
    setEditor,
    handleChange,
    validate = null,
    disabled = false,
}: any) => {
    const ff = fontFamily;
    const TipTapEditorOptions = {
        ...DefaultEditorOptions,
        onCreate: ({ editor }: any) => {
            if (setEditor) {
                editor.commands.setFontFamily(ff);
                setEditor(editor);
            }
        },
        extensions: [
            ...DefaultEditorOptions.extensions.filter(extention => {
                return extention.name != "link" && extention.name != "image";
            }),
            Image.configure({
                allowBase64: true,
                inline: true,
            }),
            Link.configure({
                linkOnPaste: false,
                autolink: false,
            }),
            FontFamily,
        ],
    };
    return (
        <RichTextInput
            label={label}
            name={name}
            source={source}
            defaultValue={defaultValue}
            validate={validate}
            onChange={handleChange}
            editorOptions={TipTapEditorOptions}
            toolbar={<TipTapEditorToolbar />}
            disabled={disabled}
            fullWidth
        />
    );
};
