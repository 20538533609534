import { Dialog } from "@mui/material";
import { getCommonCode } from "@src/utils/utils";
import React, { useEffect, useRef, useState } from "react";
import {
    Edit,
    Identifier,
    ListButton,
    TopToolbar,
    useDataProvider,
    useGetIdentity,
    useNotify,
    useRecordContext,
    useRedirect,
    useResourceContext,
    useTranslate,
    useUpdate,
} from "react-admin";
import { VocEditInputs } from "./types";
import { VocInputs } from "./VocInputs";

const EditActions = ({ setPrevCustomerName }: any) => {
    const record = useRecordContext();
    if (!record) return null;
    setPrevCustomerName(record.customerName);

    return (
        <TopToolbar>
            <ListButton />
        </TopToolbar>
    );
};

export const VocEdit = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    const translate = useTranslate();
    const [update] = useUpdate();
    const notify = useNotify();
    const { identity } = useGetIdentity();
    const [prevCustomerName, setPrevCustomerName] = useState("");

    if (!identity) return null;

    const handleSubmit = (data: VocEditInputs) => {
        const {
            id,
            // inbound_at,
            inboundContact,
            inboundChannelCategory1,
            inboundChannelCategory2,
            customerInboundCount,
            customerName,
            customerType,
            requestTitle,
            requestCategory1,
            requestCategory2,
            requestCategory3,
            requestDescription,
        } = data;

        update(
            "voc",
            {
                id,
                data: {
                    id,
                    // inbound_at: new Date(+inbound_at).toISOString(),
                    inboundContact,
                    inboundChannelCategory1: "VOC_INBOUND_CHANNEL." + getCommonCode(inboundChannelCategory1),
                    inboundChannelCategory2:
                        getCommonCode(inboundChannelCategory1) + "." + getCommonCode(inboundChannelCategory2),
                    customerInboundCount:
                        prevCustomerName !== customerName ? +customerInboundCount + 1 : +customerInboundCount,
                    customerName,
                    customerType: "VOC_CUSTOMER_TYPE." + getCommonCode(customerType),
                    requestTitle,
                    requestCategory1: "VOC_REQUEST_TYPE." + getCommonCode(requestCategory1),
                    requestCategory2: getCommonCode(requestCategory1) + "." + getCommonCode(requestCategory2),
                    requestCategory3: getCommonCode(requestCategory2) + "." + getCommonCode(requestCategory3),
                    requestDescription,
                    update_userid: `${identity?.id}(${identity?.fullName})`,
                },
            },
            {
                onSuccess: () => {
                    notify("ra.notification.updated");
                    redirect("list", resource);
                },
            },
        );
    };

    return (
        <Edit
            title={translate("voc.addVoc")}
            actions={<EditActions setPrevCustomerName={setPrevCustomerName} />}
            redirect="list"
        >
            <VocInputs handleSubmit={handleSubmit} />
        </Edit>
    );
};
