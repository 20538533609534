import polyglotI18nProvider from "ra-i18n-polyglot";
import koreanMessages from "../i18n/ko";
import englishMessages from "../i18n/en";
import { LocaleMessages } from "../types";
import { TranslationMessages } from "react-admin";

const messages: LocaleMessages = {
    ko: koreanMessages as TranslationMessages,
    en: englishMessages,
};

const locale = process.env.REACT_APP_SET_LOCALE;

export default polyglotI18nProvider(locale => {
    return messages[locale] ? messages[locale] : messages.en;
}, locale);
