import { useMediaQuery, Theme } from "@mui/material";
import { getUserName } from "@src/utils/utils";
import { format } from "date-fns";
import {
    List,
    ListProps,
    TextField,
    TopToolbar,
    Datagrid,
    useTranslate,
    FilterButton,
    SimpleList,
    TextInput,
    FunctionField,
    CreateButton,
    usePermissions,
} from "react-admin";

const noticeFilters = [
    <TextInput source="title" label={"common.title"} />,
    <TextInput source="writer" label={"common.writer"} />,
];

const ListActions = () => {
    const { isLoading, permissions } = usePermissions();

    if (isLoading) return null;
    return (
        <TopToolbar>
            <FilterButton />
            {permissions.corp_admin_mgmt?.access && <CreateButton />}
        </TopToolbar>
    );
};

export const CorpAdminNoticeList = (props: ListProps) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));

    return (
        <>
            <List
                {...props}
                title={translate("title.customer_notice")}
                filters={noticeFilters}
                sort={{ field: "id", order: "DESC" }}
                actions={<ListActions />}
                empty={false}
            >
                {isXSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.writer}
                        tertiaryText={record => new Date(+record.create_at).toISOString()}
                        linkType="show"
                    />
                ) : (
                    <Datagrid rowClick="show" bulkActionButtons={false}>
                        <TextField source="id" label={translate("common.idx")} sortable={false} textAlign="center" />
                        <TextField source="title" label={"common.title"} sortable={false} textAlign="center" />
                        <FunctionField
                            label="common.writer"
                            sortable={false}
                            textAlign="center"
                            render={(record: any) => {
                                return getUserName(record.create_userid);
                            }}
                        />
                        <FunctionField
                            label="common.date"
                            sortable={false}
                            textAlign="center"
                            render={(record: any) => {
                                return format(new Date(+record.create_at), "yyyy-MM-dd");
                            }}
                        />
                    </Datagrid>
                )}
            </List>
        </>
    );
};
