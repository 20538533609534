import { usePermissions } from "react-admin";
import { Helmet } from "react-helmet";
import EveronDashboard from "./everon/EveronDashboard";
import CorpAdminDashboard from "./corp_admin/CorpAdminDashboard";

export const Dashboard = () => {
    const { isLoading, permissions } = usePermissions();
    if (isLoading) return null;

    if (permissions?.corp_admin?.access) {
        return (
            <>
                <Helmet>
                    <title>에버온 충전서비스 법인관리자</title>
                </Helmet>
                <CorpAdminDashboard />
            </>
        );
    }

    return (
        <>
            <Helmet>
                <title>에버온 충전서비스 관리자</title>
            </Helmet>
            <EveronDashboard />
        </>
    );
};
