import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { ChartBox } from "@src/components/charts/ChartBox";
import { BarChart } from "@src/components/charts/BarChart";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { useDataProvider } from "react-admin";
import { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";

export const CorpChargeAmountChart = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);
    const keys = useRef<string[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data: rawData } = await dataProvider.callExternalApi("/analytics/corpChargeAmount", null, "GET");
            const accByDate = rawData.reduce((acc: any, record: any) => {
                return {
                    ...acc,
                    [record.yyyymm]: {
                        ...acc[record.yyyymm],
                        [record.companyName]: record.amount,
                    },
                };
            }, {});
            const data = Object.keys(accByDate).map(date => {
                if (keys.current.length === 0) {
                    keys.current = Object.keys(accByDate[date]);
                }
                const year = date.substring(2, 4);
                const month = date.substring(4);
                return {
                    date: `${year}-${month}`,
                    ...accByDate[date],
                };
            });
            setData(data);
        };

        setChartData();
    }, []);

    return (
        <ChartBox
            titleIcon={<AttachMoneyIcon color="disabled" fontSize="large" />}
            titleText="ceo.corp_charge_amount"
            chart={
                data.length > 0 ? (
                    <BarChart
                        enableGridY={false}
                        enableGridX={true}
                        data={data}
                        keys={keys.current}
                        axisLeft={{
                            format: (v: any) => `${Math.abs(v / 1000)}MWh`,
                            tickValues: 8,
                        }}
                        margin={{ top: 20, right: 100, bottom: 50, left: 50 }}
                        tooltip={({ id, value, color }: any) => {
                            return <ChartTooltip id={id} color={color} content={`${value.toLocaleString()}kWh`} />;
                        }}
                        legends={[
                            {
                                anchor: "bottom-right",
                                dataFrom: "keys",
                                direction: "column",
                                itemHeight: 20,
                                itemWidth: 90,
                                toggleSerie: true,
                                translateX: 100,
                            },
                        ]}
                        axisBottom={{
                            legendPosition: "middle",
                            legendOffset: 50,
                            tickSize: 0,
                            tickPadding: 12,
                            tickRotation: 40,
                        }}
                    />
                ) : (
                    <CircularProgress />
                )
            }
        />
    );
};
