import * as React from "react";
import { MenuItemLink, usePermissions, useTranslate } from "react-admin";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const SystemMenuItems = ({ dense }: { dense: boolean }) => {
    const { isLoading, permissions } = usePermissions();
    const translate = useTranslate();

    if (isLoading) return null;
    return (
        <>
            {permissions.account_mgmt?.access && (
                <MenuItemLink
                    to="/system/account_manager"
                    state={{ _scrollToTop: true }}
                    primaryText={translate(`title.system_account_mgmt`)}
                    dense={dense}
                    leftIcon={<ManageAccountsIcon />}
                />
            )}
            {/* <MenuItemLink
                to="/system"
                state={{ _scrollToTop: true }}
                primaryText={translate(`title.system`)}
                dense={dense}
            /> */}
        </>
    );
};

export default SystemMenuItems;
