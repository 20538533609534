import { TranslationMessages } from "react-admin";
import englishMessages from "ra-language-english";

// TODO, 페이지별 기능 개발하며 리소스로 교체
const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: "Search",
        language: "Language",
        theme: {
            name: "Theme",
            light: "Light",
            dark: "Dark",
        },
        dashboard: {
            monthly_revenue: "Monthly Revenue",
            month_history: "30 Day Revenue History",
            new_orders: "New Orders",
            pending_reviews: "Pending Reviews",
            all_reviews: "See all reviews",
            new_customers: "New Customers",
            all_customers: "See all customers",
            pending_orders: "Pending Orders",
            order: {
                items: "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
            },
            welcome: {
                title: "Welcome to the react-admin e-commerce demo",
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: "react-admin site",
                demo_button: "Source for this demo",
            },
        },
    },
    dashboard: {
        widget: {
            calendar: "Calendar",
            email: "Email",
            chat: "Chat",
            authorization: "Authorization",
        },
    },
    common: {
        index: "Index",
        viewmore: "View More",
        csvsave: "CSV Save",
        writer: "Writer",
        content: "Content",
        subject: "Subject",
        date: "Date",
        location: "Location",
        configuration: "Configuration",
        category: "Category",
        question: "Question",
        answer: "Answer",
        attachments: "Attachment",
    },
    email: {
        title: "Emails",
    },
    crm: {
        title: "CRM",
        contacts: {
            title: "Contacts",
        },
        companies: {
            title: "Companies",
        },
        deals: {
            title: "Deals",
        },
    },
    controlCp: {
        title: "Control CP",
        status: {
            title: "Charger Status",
        },
        chargers: {
            title: "Charger Management",
        },
        cps: {
            title: "ChargePoint Management",
            tabs: {
                basic_info: "Basic Info",
                locationInfo: "Location Info",
                operationInfo: "Opration Info",
            },
        },
        history: {
            title: "Charging History",
        },
        reservation: {
            title: "Reservation",
        },
        control: {
            title: "Charger Control",
        },
        fields: {
            cpName: "Charge Point Name",
            chargerID: "Charger ID",
            terminalNumber: "Terminal Number",
            chargerStatus: "Charger Status",
            channel: "Channel",
            cableStatus: "Cable Status",
            btnStatus: "Button Status",
            cardStatus: "Card Status",
            receivingDate: "Receiving Time",
            manufacturer: "Manufacturer",
            useYN: "Using",
            contractYN: "Contracted",
            kind: "Kind",
            sido: " 지역(시, 도)",
            gugun: "지역(구, 군)",
            dongeup: "지역(동, 면)",
            reservationStatus: "Reservation Status",
            reservationNumber: "Reservation Number",
            name: "Name",
            reservationDateFrom: "Reservation Date(From)",
            reservationDateTo: "Reservation Date(To)",
        },
        filters: {
            cpName: "Name of Charge Point",
        },
    },
    repair: {
        title: "Repair",
    },
    customer: {
        title: "Customer",
        accountDetail: {
            title: "Account Detail",
            basic_info: "Basic Info",
            vehicleInfo: "Vehicle Info",
            paymentInfo: "Payment Info",
        },
        accountsList: {
            title: "Accounts List",
        },
        basicAccount: "Basic Account",
        personal: "Personal",
        corporate: "Corporate",
        roamingCard: "Roaming Card",
        notice: {
            title: "Notices",
        },
        qna: {
            title: "QnA",
        },
        faq: {
            title: "FAQ",
        },
        oneOnOne: {
            title: "1:1 Questions",
        },
        fields: {
            name: "Name",
            accountID: "ID",
            cardNumber: "Card Number",
            accountLevel: "Account Level",
            usageLevel: "Usage Level",
            accountType: "Account Type",
            gender: "Gender",
            dateOfBirth: "Date Of Birth",
            dateOfSignUp: "Date Of Sign Up",
            phone: "Phone",
            answerYN: "Answer Status",
            answered: "Answered",
            waiting: "Waiting",
        },
    },
    callcenter: {
        title: "Call Center",
    },
    commission: {
        title: "Commission",
    },
    calculation: {
        title: "Calculation",
    },
    ceo: {
        title: "CEO",
        monitorSales: {
            title: "Sales",
        },
        monitorRoaming: {
            title: "Roaming Info",
        },
        monitorAccounts: {
            title: "Accounts",
        },
    },
    system: {
        title: "System",
    },
};

export default customEnglishMessages;
