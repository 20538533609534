import CampaignIcon from "@mui/icons-material/Campaign";
import BusinessIcon from "@mui/icons-material/Business";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SubMenu from "@src/layout/SubMenu";
import CorpAdminAccountsMenuItems from "./submenus/CorpAdminAccountsMenuItems";
import CorpAdminNoticeMenuItems from "./submenus/CorpAdminNoticeMenuItems";
import CorpAdminBillsMenuItems from "./submenus/CorpAdminBillsMenuItems";

export const CorpAdminMenu = ({ dense, handleMenuToggle, menuOpenState }: any) => {
    return (
        <>
            <SubMenu
                name="corp_admin_notice"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.corp_admin_notice}
                icon={<CampaignIcon />}
                dense={dense}
            >
                <CorpAdminNoticeMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="corp_admin_accounts"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.corp_admin_accounts}
                icon={<BusinessIcon />}
                dense={dense}
            >
                <CorpAdminAccountsMenuItems dense={dense} />
            </SubMenu>
            <SubMenu
                name="corp_admin_bills"
                handleMenuToggle={handleMenuToggle}
                isOpen={menuOpenState.corp_admin_bills}
                icon={<PointOfSaleIcon />}
                dense={dense}
            >
                <CorpAdminBillsMenuItems dense={dense} />
            </SubMenu>
        </>
    );
};
