import * as React from "react";
import { TopToolbar, ListButton, useTranslate, Title, ShowButton, useGetIdentity, EditBase } from "react-admin";
import { ClientInputs } from "./ClientInputs";
import { ClientInput } from "../types";
import { getCommonCode } from "@src/utils/utils";

export const ClientEdit = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();

    if (!identity) return null;

    return (
        <EditBase
            redirect="show"
            mutationMode="pessimistic"
            transform={({
                id,
                client_name,
                client_type,
                address,
                address_detail,
                thumbnail,
                tel_number,
                bigo_note,
            }: ClientInput) => {
                let file_change_status = ""; // NOT CHANGED
                if (thumbnail === null) {
                    file_change_status = "REMOVED";
                } else if (thumbnail && thumbnail.rawFile) {
                    file_change_status = "CHANGED";
                }

                return {
                    id,
                    clientInput: {
                        client_name,
                        client_type: "CRM_CLIENT_TYPE." + getCommonCode(client_type),
                        address,
                        address_detail,
                        update_userid: `${identity?.id}(${identity?.fullName})`,
                        file_change_status,
                        thumbnail: file_change_status === "CHANGED" ? thumbnail.rawFile : null,
                        tel_number,
                        bigo_note,
                    },
                };
            }}
        >
            <Title title={translate("crm.editClient")} />
            <TopToolbar>
                <ShowButton label={translate("crm.showClient")} />
                <ListButton />
            </TopToolbar>
            <ClientInputs />
        </EditBase>
    );
};
