import * as React from "react";
import { SimpleForm, TextInput, useTranslate, useGetIdentity } from "react-admin";
import { RichTextInputWithImageAttachment } from "@src/components/inputs/RichTextInputWithImageAttachment";
import { Box, Grid } from "@mui/material";
import { format } from "date-fns";

export const CorpAdminNoticeInputs = ({ type }: { type: string }) => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();

    return (
        <SimpleForm>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={9} md={9}>
                    <TextInput source="title" disabled label={"common.title"} margin="none" fullWidth />
                </Grid>
            </Grid>
            {type === "edit" && (
                <Box>
                    <TextInput
                        format={(create_at: any) => {
                            return format(new Date(+create_at), "yyyy-MM-dd HH:mm:ss");
                        }}
                        disabled
                        source="create_at"
                        label={"common.date"}
                        margin="none"
                    />
                </Box>
            )}
            <RichTextInputWithImageAttachment label={"common.content"} source="content" />
            {/* <FileInput source="files" label={'common.attachments'} accept="application/pdf, image/*" multiple>
                <FileField source="src" title="title" target="_blank" />
            </FileInput> */}
        </SimpleForm>
    );
};
