import * as React from "react";
import { Avatar } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

type VocAvatarProps = {
    dept: string;
    sx?: Object;
};

export const VocAvatar = ({ dept }: VocAvatarProps) => {
    let icon: JSX.Element | string = <QuestionMarkIcon />;
    let bgcolor = "none";

    switch (dept) {
        case "DEPT_00":
            icon = "!";
            bgcolor = "#ff958c";
            break;
        case "DEPT_01":
            icon = "CX고객";
            bgcolor = "#ffd17f";
            break;
        case "DEPT_02":
            icon = "전략영업";
            bgcolor = "#ff8584";
            break;
        case "DEPT_03":
            icon = "영업";
            bgcolor = "#9bc9ff";
            break;
        case "DEPT_04":
            icon = "LAB";
            bgcolor = "#87ccb5";
            break;
        case "DEPT_05":
            icon = "IT";
            bgcolor = "#ac94bf";
            break;
        case "DEPT_06":
            icon = "전략기획";
            bgcolor = "#9bccca";
            break;
        case "DEPT_07":
            icon = "AS타사";
            bgcolor = "#4974ae";
            break;
        case "DEPT_08":
            icon = "경영";
            bgcolor = "#69b879";
            break;
        case "DEPT_09":
            icon = "IPM";
            bgcolor = "#4178a5";
            break;
        case "DEPT_10":
            icon = "사업지원";
            bgcolor = "#96a6c6";
            break;
        case "DEPT_11":
            icon = "CX유지";
            bgcolor = "#5f97e1";
            break;
        case "DEPT_12":
            icon = "사업기획";
            bgcolor = "#f5aa74";
            break;
        case "DEPT_13":
            icon = "CS";
            bgcolor = "#b0d383";
            break;
        case "DEPT_14":
            icon = "미배정";
            bgcolor = "#ca7ed9";
            break;
        case "DEPT_15":
            icon = "충전이슈";
            bgcolor = "#ef83cb";
            break;
        case "DEPT_16":
            icon = "EV크루";
            bgcolor = "#8c9184";
            break;
        case "DEPT_17":
            icon = "미해결건";
            bgcolor = "#d34759";
            break;
        case "DEPT_18":
            icon = "TF";
            bgcolor = "#46cac3";
            break;
        case "DEPT_19":
            icon = "유심불량";
            bgcolor = "#48439bde";
            break;
        default:
            bgcolor = "none";
    }

    return (
        <Avatar
            variant="square"
            sx={{
                bgcolor,
                textAlign: "center",
                p: "0.3rem",
                borderRadius: "5px",
            }}
        >
            {icon}
        </Avatar>
    );
};
