import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { convertMsToProgressTime, convertTimestampToString } from "@src/utils/date";
import { removeHtmlTags } from "./utils";

// jsonExport doc - http://kaue.github.io/jsonexport/
// RA exporter - https://marmelab.com/react-admin/List.html#exporter

export const crmDealExporter = async (deals: any[], fetchRelatedRecords: any) => {
    const clients = await fetchRelatedRecords(deals, "client_id", "CrmClient");
    let idx = 0;
    const dealsForExport = deals.map(deal => {
        const { client_id, deal_title, deal_description, deal_status, budget, createAt } = deal;
        return {
            idx: ++idx,
            client_name: clients[client_id].client_name,
            client_type: clients[client_id].client_type.codeName,
            deal_title,
            deal_description,
            deal_status: deal_status.codeName,
            budget,
            createAt: convertTimestampToString(createAt),
        };
    });

    jsonExport(
        dealsForExport,
        {
            headers: [
                "idx",
                "client_name",
                "client_type",
                "deal_title",
                "deal_description",
                "deal_status",
                "budget",
                "createAt",
            ], // order fields in the export
            rename: ["번호", "거래처명", "거래처 유형", "거래명", "거래내용", "거래상태", "예산", "생성일"],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "거래목록"); // download as 'deals.csv` file
        },
    );
};

export const crmClientExporter = async (clients: any[]) => {
    let idx = 0;
    const clientsForExport = clients.map(client => {
        const { client_name, client_type, address, address_detail, tel_number, bigo_note, createAt } = client;
        return {
            idx: ++idx,
            client_name,
            client_type: client_type.codeName,
            address,
            address_detail,
            tel_number,
            bigo_note,
            createAt: convertTimestampToString(createAt),
        };
    });

    jsonExport(
        clientsForExport,
        {
            headers: [
                "idx",
                "client_name",
                "client_type",
                "address",
                "address_detail",
                "tel_number",
                "bigo_note",
                "createAt",
            ], // order fields in the export
            rename: ["번호", "거래처명", "거래처 유형", "주소", "상세주소", "전화번호", "비고", "생성일"],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "거래처목록"); // download as 'deals.csv` file
        },
    );
};

export const crmContactExporter = async (contacts: any[], fetchRelatedRecords: any) => {
    // const clients = await fetchRelatedRecords(contacts, "client_id", "CrmClient");
    // TODO Client값이 꼬여서 ㅠㅠ 나중에 한 번 확인해 볼것
    let idx = 0;
    const contactsForExport = contacts.map(contact => {
        const {
            client_id,
            contact_name,
            job_title,
            email,
            tel_number,
            individual_tel_number,
            fax_tel_number,
            bigo_note,
            createAt,
        } = contact;
        return {
            idx: ++idx,
            contact_name,
            job_title,
            email,
            tel_number,
            // client_name: clients[client_id].client_name,
            // client_type: clients[client_id].client_type.codeName,
            individual_tel_number,
            fax_tel_number,
            bigo_note,
            createAt: convertTimestampToString(createAt),
        };
    });

    jsonExport(
        contactsForExport,
        {
            headers: [
                "idx",
                "contact_name",
                "job_title",
                "email",
                "tel_number",
                // "client_name",
                // "client_type",
                "individual_tel_number",
                "fax_tel_number",
                "bigo_note",
                "createAt",
            ], // order fields in the export
            // rename: ["번호", "연락처명", "직급/직함", "이메일", "전화번호", "거래처명", "거래처유형", "생성일"],
            rename: [
                "번호",
                "연락처명",
                "직급/직함",
                "이메일",
                "전화번호",
                "개인전화번호",
                "팩스번호",
                "비고",
                "생성일",
            ],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "연락처목록"); // download as 'deals.csv` file
        },
    );
};

export const vocExporter = async (vocs: any[]) => {
    let idx = 0;
    const vocsForExport = vocs.map(voc => {
        const {
            inboundAt,
            inboundContact,
            inboundChannelCategory1,
            inboundChannelCategory2,
            requestTitle,
            customerType,
            customerInboundCount,
            customerName,
            requestCategory1,
            requestCategory2,
            requestCategory3,
            responseDepartment,
            lastResponseUserid,
            responseCompleteAt,
            responseTimeToComplete,
            requestDescription,
            createAt,
            createUserid,
        } = voc;
        return {
            idx: ++idx,
            inboundAt: convertTimestampToString(inboundAt),
            inboundContact,
            inboundChannelCategory1: inboundChannelCategory1.codeName,
            inboundChannelCategory2: inboundChannelCategory2.codeName,
            requestTitle,
            customerType: customerType.codeName,
            customerInboundCount,
            customerName,
            requestCategory1: requestCategory1.codeName,
            requestCategory2: requestCategory2.codeName,
            requestCategory3: requestCategory3.codeName,
            responseDepartment: responseDepartment.codeName,
            lastResponseUserid,
            responseCompleteAt: convertTimestampToString(responseCompleteAt),
            responseTimeToComplete: convertMsToProgressTime(+responseTimeToComplete),
            requestDescription: removeHtmlTags(requestDescription),
            createAt: convertTimestampToString(createAt),
            createUserid,
        };
    });

    jsonExport(
        vocsForExport,
        {
            headers: [
                "idx",
                "inboundAt",
                "inboundContact",
                "inboundChannelCategory1",
                "inboundChannelCategory2",
                "requestTitle",
                "customerType",
                "customerInboundCount",
                "customerName",
                "requestCategory1",
                "requestCategory2",
                "requestCategory3",
                "responseDepartment",
                "lastResponseUserid",
                "responseCompleteAt",
                "responseTimeToComplete",
                "requestDescription",
                "createAt",
                "createUserid",
            ], // order fields in the export
            rename: [
                "idx",
                "인바운드일자",
                "인바운드연락처",
                "인바운드인입경로",
                "인바운드인입채널",
                "문의제목",
                "고객유형",
                "고객문의횟수",
                "고객이름",
                "문의대분류",
                "문의중분류",
                "문의소분류",
                "응대담당부서",
                "응대담당부서 담당자ID",
                "응대완료일자",
                "응대완료까지걸린시간",
                "상세설명",
                "등록일자",
                "등록자ID",
            ],
        },
        (err, csv) => {
            downloadCSV("\ufeff" + csv, "VOC내역"); // download as 'deals.csv` file
        },
    );
};
