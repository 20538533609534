import * as React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HomeIcon from "@mui/icons-material/Home";
import { Title, useTranslate, Button } from "react-admin";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

export default () => {
    const translate = useTranslate();
    return (
        <>
            <Title title="404 Not Found" />
            <Box
                sx={{
                    textAlign: "center",
                    border: "none",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                }}
            >
                <div style={{ opacity: 0.5 }}>
                    <ErrorOutlineIcon sx={{ width: "150px", height: "150px" }} />
                    <h1>404: Page not found</h1>
                    {translate("ra.message.not_found")}
                </div>
                <div style={{ marginTop: "2em" }}>
                    <Button
                        component={Link}
                        to={{
                            pathname: "/",
                            state: { _scrollToTop: true },
                        }}
                        label="action.go_home"
                        variant="contained"
                        size="medium"
                        children={<HomeIcon />}
                    />
                </div>
            </Box>
        </>
    );
};
