import * as React from "react";
import { Box, Tooltip } from "@mui/material";
import { useTranslate } from "react-admin";

const getColorFromStatus = (status: string) => {
    switch (status) {
        case "STATUS_GOOD":
            return "#c1ff12";
        case "STATUS_DECENT":
            return "#ffd400";
        case "STATUS_BAD":
            return "#ff0000";
        default:
            return "#ffd400";
    }
};

export const Status = ({ status, statusTooltip }: { status: string; statusTooltip?: string }) => {
    const translate = useTranslate();
    return (
        <Tooltip title={status && statusTooltip ? translate(`commonCode.noteStatus.${statusTooltip}.${status}`) : ""}>
            <Box
                width={10}
                height={10}
                display="inline-block"
                borderRadius={5}
                bgcolor={getColorFromStatus(status)}
                component="span"
            />
        </Tooltip>
    );
};
