import React from "react";
import {
    Show,
    useTranslate,
    TopToolbar,
    ListButton,
    EditButton,
    useRecordContext,
    RichTextField,
    usePermissions,
} from "react-admin";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { getUserName } from "@src/utils/utils";

const ShowActions = () => {
    const { isLoading, permissions } = usePermissions();

    if (isLoading) return null;
    return (
        <TopToolbar>
            <ListButton />
            {permissions.corp_admin_mgmt?.update && <EditButton />}
        </TopToolbar>
    );
};

export const CorpAdminNoticeShow = () => {
    // TODO 첨부파일이 있는 경우만 첨부파일 필드 표시하도록 수정 필요
    return (
        <Show title={"title.customer_notice"} actions={<ShowActions />}>
            <CorpAdminQnaShowContent />
        </Show>
    );
};

const CorpAdminQnaShowContent = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    return (
        <Box display="flex" flexDirection="column">
            <Box sx={{ padding: "20px 24px" }}>
                <Box mb={3}>
                    <Typography variant="h3">{record.title}</Typography>
                </Box>
                <Box>
                    <Box display="flex" flexDirection="row" mb={2}>
                        <Box mr={2}>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.create_at")}
                            </Typography>
                            <Typography variant="subtitle2">
                                {format(new Date(+record.create_at), "yyyy-MM-dd")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography color="textSecondary" variant="body2">
                                {translate("common.create_userid")}
                            </Typography>
                            <Typography variant="subtitle2">{getUserName(record.create_userid)}</Typography>
                        </Box>
                    </Box>
                    <Box mb={2}>
                        <Typography color="textSecondary" variant="body2">
                            {translate("common.content")}
                        </Typography>
                        <RichTextField source="content" />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
