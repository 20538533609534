import {
    useMediaQuery,
    Theme,
    Dialog,
    DialogContent,
    Box,
    TextField,
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import {
    List,
    TextField as RaTextField,
    TopToolbar,
    Datagrid,
    useTranslate,
    FilterButton,
    SimpleList,
    TextInput,
    Button,
    useRedirect,
} from "react-admin";
import { useState } from "react";
import { TypographWithIcon } from "@src/components/TypographWithIcon";
import { ButtonField } from "@src/components/fields";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const noticeFilters = [<TextInput source="name" label={"이름"} />, <TextInput source="userid" label={"아이디"} />];

const ListActions = () => {
    const redirect = useRedirect();
    return (
        <TopToolbar>
            <Button
                label="승인요청회원"
                children={<PersonAddIcon />}
                onClick={() => {
                    redirect("/corp_admin/accounts/approval");
                }}
            />
            <FilterButton />
        </TopToolbar>
    );
};

export const CorpAdminAccountsList = () => {
    const redirect = useRedirect();
    const translate = useTranslate();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("xs"));
    const [openAccountEdit, setOpenAccountEdit] = useState(false);

    const handleCloseAccountEdit = () => {
        setOpenAccountEdit(false);
    };

    return (
        <>
            <List
                title={translate("title.corp_admin_accounts_list")}
                filters={noticeFilters}
                sort={{ field: "id", order: "DESC" }}
                actions={<ListActions />}
                empty={false}
            >
                {isXSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.writer}
                        tertiaryText={record => new Date(+record.create_at).toISOString()}
                        linkType="show"
                    />
                ) : (
                    <Datagrid
                        rowClick={() => {
                            // TODO, 회원 정보 수정 사용하는 경우 open 처리
                            setOpenAccountEdit(true);
                            return "";
                        }}
                        bulkActionButtons={false}
                    >
                        <RaTextField source="id" label={translate("common.idx")} sortable={false} textAlign="center" />
                        <RaTextField
                            source="create_userid"
                            label={translate("common.name")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.account_number")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.department")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField source="id" label={translate("common.id")} sortable={false} textAlign="center" />
                        <RaTextField
                            source="id"
                            label={translate("common.gender")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.tel_number")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.date_of_birth")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.employee_number")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.car_number")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("common.car_model")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("customer.accountLevel")}
                            sortable={false}
                            textAlign="center"
                        />
                        <RaTextField
                            source="id"
                            label={translate("corp_admin.approval_date")}
                            sortable={false}
                            textAlign="center"
                        />
                        <ButtonField
                            variant="contained"
                            source="id"
                            label={translate("corp_admin.payments_history")}
                            btnText={translate("common.show_details")}
                            sortable={false}
                            textAlign="center"
                            fullWidth={false}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                redirect("/corp_admin/accounts/payment");
                            }}
                        />
                    </Datagrid>
                )}
            </List>

            {/* 회원 정보 수정 */}
            <Dialog
                open={openAccountEdit}
                onClose={handleCloseAccountEdit}
                fullWidth
                maxWidth="sm"
                sx={{
                    ".MuiDialogContent-root": {
                        padding: 0,
                    },
                }}
            >
                <DialogContent>
                    <AccountEditPopup handleCloseAccountEdit={handleCloseAccountEdit} />
                </DialogContent>
            </Dialog>
        </>
    );
};

const AccountEditPopup = ({ handleCloseAccountEdit }: any) => {
    const [carModel, setCarModel] = useState();

    const handleChange = (e: any) => {
        setCarModel(e.target.value);
    };

    const translate = useTranslate();
    return (
        <Box sx={{ display: "flex", flexDirection: "column", padding: 4 }}>
            <TypographWithIcon variant="h3" icon={<PersonIcon color="disabled" />} text={"회원 정보 수정"} />
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                    <TextField fullWidth label={translate("common.name")} name="name" />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField fullWidth label={translate("common.department")} name="department" />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={6}>
                    <TextField fullWidth label={translate("common.employee_number")} name="employee_number" />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextField fullWidth label={translate("common.email")} name="email" />
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={6}>
                    <TextField fullWidth label={translate("common.car_number")} name="car_number" />
                </Grid>
                <Grid item xs={6} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="select-car-model">{translate("common.car_model")}</InputLabel>
                        <Select
                            variant="standard"
                            labelId="select-car-model"
                            value={carModel}
                            name="car_model"
                            label={translate("common.car_model")}
                            onChange={handleChange}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "end", margin: "1.5rem 0 0 0" }}>
                <Button
                    variant="contained"
                    label="수정"
                    children={<EditIcon />}
                    onClick={() => {
                        alert("Edit Password");
                        handleCloseAccountEdit(true);
                    }}
                />
            </Box>
        </Box>
    );
};
