import * as React from "react";
import GroupIcon from "@mui/icons-material/Group";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import GridViewIcon from "@mui/icons-material/GridView";
import EvStationIcon from "@mui/icons-material/EvStation";
import DomainIcon from "@mui/icons-material/Domain";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import GavelIcon from "@mui/icons-material/Gavel";
import { MenuItemLink, useTranslate } from "react-admin";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";

const CEOMenuItems = ({ dense }: { dense: boolean }) => {
    const translate = useTranslate();

    return (
        <>
            <MenuItemLink
                to="/ceo/dashboard"
                state={{ _scrollmonitorOverallToTop: true }}
                primaryText={translate("title.ceo_screen")}
                leftIcon={<ViewComfyIcon />}
            />
            {/* 
            <MenuItemLink
                to="/monitorAccounts"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ceo_monitorAccounts")}
                leftIcon={<GroupIcon />}
            />
            <MenuItemLink
                to="/monitorChargers"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ceo_monitorChargers")}
                leftIcon={<EvStationIcon />}
            />
            <MenuItemLink
                to="/monitorKepcoUsage"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ceo_monitorKepcoUsage")}
                leftIcon={<CompareArrowsIcon />}
            />
            <MenuItemLink
                to="/monitorOtherAccountUsage"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ceo_monitorOtherAccountUsage")}
                leftIcon={<CompareArrowsIcon />}
            />
            <MenuItemLink
                to="/monitorEveronAccountUsage"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ceo_monitorEveronAccountUsage")}
                leftIcon={<CompareArrowsIcon />}
            />
            <MenuItemLink
                to="/monitorCorpAccountUsage"
                state={{ _scrollToTop: true }}
                primaryText={translate("title.ceo_monitorCorpAccountUsage")}
                leftIcon={<DomainIcon />}
            /> */}
        </>
    );
};

export default CEOMenuItems;
