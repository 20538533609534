import React from "react";
import { List, useGetList, useTranslate } from "react-admin";
import { DealCardListContent } from "./DealCardListContent";

export const DealCardList = ({ filters, actions, filterDefaultValues, perPage, pagination, exporter }: any) => {
    const translate = useTranslate();
    const { data, isLoading } = useGetList("CommonCode", {
        sort: { field: "codeOrder", order: "ASC" },
        filter: { parentCode: "CRM_DEAL_STATUS" },
    });

    if (isLoading) return null;
    if (!data) return null;

    return (
        <List
            pagination={pagination}
            perPage={perPage}
            sort={{ field: "deal_status_order", order: "ASC" }}
            filterDefaultValues={filterDefaultValues}
            filters={filters}
            actions={actions}
            component="div"
            title={translate("title.crm_deal")}
            exporter={exporter}
            empty={false}
        >
            <DealCardListContent
                dealStatus={data.map(({ parentCode, code, codeDescription, codeName, codeOrder }) => ({
                    parentCode,
                    code,
                    codeDescription,
                    codeName,
                    codeOrder,
                }))}
            />
        </List>
    );
};
