import { Theme, useMediaQuery } from "@mui/material";
import {
    Datagrid,
    List,
    Pagination,
    useGetIdentity,
    FunctionField,
    SelectInput,
    SearchInput,
    useTranslate,
    TopToolbar,
    FilterButton,
} from "react-admin";
import { format } from "date-fns";
import MobileBookmarkGrid from "./MobileBookmarkGrid";
import { ExternalLinkField } from "@src/components/fields";
import { BookmarkField } from "@src/components/fields/BookmarkField";

const BookmarkActions = () => {
    return (
        <TopToolbar>
            <FilterButton />
        </TopToolbar>
    );
};
const choices = ["news", "blog", "cafe", "law", "admrul", "ordin", "prec", "decc", "detc"].map(type => ({
    id: type,
    name: `info_retrieval.types.${type}`,
}));
const defaultFilters = [
    <SearchInput source="title" alwaysOn />,
    <SelectInput label="common.type" source="type" translateChoice={true} choices={choices} />,
];

export const BookmarkList = () => {
    const translate = useTranslate();
    const { identity } = useGetIdentity();
    const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

    if (!identity) return null;
    return (
        <List
            title="title.info_retrieval_bookmark"
            actions={<BookmarkActions />}
            filters={defaultFilters}
            sort={{ field: "create_at", order: "DESC" }}
            perPage={10}
            pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
            filterDefaultValues={{ create_userid: `${identity?.id}(${identity?.fullName})` }}
            empty={false}
        >
            {isXSmall ? (
                <MobileBookmarkGrid />
            ) : (
                <>
                    <Datagrid rowClick="" bulkActionButtons={false}>
                        <BookmarkField source="bookmarkId" label="" sortable={false} textAlign="center" />
                        <FunctionField
                            label="common.type"
                            sortable={false}
                            textAlign="center"
                            render={({ type }: { type: string }) => {
                                return translate(`info_retrieval.types.${type}`);
                            }}
                        />
                        <ExternalLinkField label="common.title" sortable={false} source="title" textAlign="center" />
                        <FunctionField
                            label="common.date"
                            sortable={false}
                            textAlign="center"
                            render={(record: any) => {
                                return format(new Date(+record.create_at), "yyyy-MM-dd");
                            }}
                        />
                    </Datagrid>
                </>
            )}
        </List>
    );
};
