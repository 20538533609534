import Keycloak from "keycloak-js";
import jwt_decode from "jwt-decode";
import { ___debug } from "@src/utils/utils";

const keycloakInitOptions = {
    url: process.env.REACT_APP_KEYCLOAK_URL as string,
    realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
};

export const keycloak = new Keycloak(keycloakInitOptions);

keycloak.onTokenExpired = () => {
    keycloak
        .updateToken(30)
        .then(() => {
            // TODO, 토큰 재발급 시 로그 표시되는지 확인
            ___debug("successfully get a new token", keycloak.token);
        })
        .catch(() => {
            console.error("fail to get a new token");
        });
};

export const onTokens = () => {
    if (keycloak.token && keycloak.refreshToken) {
        const decoded: any = jwt_decode(keycloak.token);
        const id = decoded.preferred_username.split("@")[0];
        const fullName = decoded.name;
        localStorage.setItem("token", keycloak.token);
        localStorage.setItem("refresh-token", keycloak.refreshToken);
        localStorage.setItem("userid", `${id}(${fullName})`);
    }
};
