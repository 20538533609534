import { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useCreate, useDelete, useGetIdentity, useRecordContext, useRefresh } from "react-admin";
import StarIcon from "@mui/icons-material/Star";

const grey = "#cecece";
const yellow = "#ffe180";

type BookmarkFieldProps = { type: string | null; source: string; textAlign: string; label: string; sortable: boolean };

const BookmarkField = ({ type }: Partial<BookmarkFieldProps>) => {
    const refresh = useRefresh();
    const record: any = useRecordContext();
    const { identity } = useGetIdentity();
    const [create] = useCreate();
    const [deleteOne] = useDelete();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bookmarkId, setBookmarkId] = useState<null | string>(null);

    useEffect(() => {
        if (type) {
            setBookmarkId(record.bookmarkId);
        } else {
            setBookmarkId(record.id);
        }
        setIsLoading(false);
    }, [record, type]);

    const handleClick = useCallback(
        (e: any) => {
            e.stopPropagation();

            // 인터넷 정보 북마크 게시판에 표시되는 BookmarkField의 type prop값은 null
            if (!bookmarkId && type) {
                create(
                    "info_retrieval/bookmark",
                    {
                        data: {
                            bookmarkInput: {
                                type,
                                title: record.title,
                                link: record.link,
                                create_userid: `${identity?.id}(${identity?.fullName})`,
                            },
                        },
                    },
                    {
                        onSuccess: ({ id }: any) => {
                            setBookmarkId(id);
                            refresh();
                        },
                    },
                );
            } else {
                deleteOne(
                    "info_retrieval/bookmark",
                    {
                        id: bookmarkId as string,
                    },
                    {
                        onSuccess: () => {
                            setBookmarkId(null);
                            refresh();
                        },
                    },
                );
            }
        },
        [bookmarkId, record, type, identity],
    );

    if (isLoading) return null;

    return (
        <Box sx={{ height: 30 }}>
            <Typography variant="button" onClick={handleClick}>
                <StarIcon fontSize="medium" sx={{ color: bookmarkId ? yellow : grey }} />
            </Typography>
        </Box>
    );
};

export { BookmarkField };
