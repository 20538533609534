import { Box, Grid } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import { TypographWithIcon } from "@src/components/TypographWithIcon";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import EvStationIcon from "@mui/icons-material/EvStation";

const CorpAdminDashboard = () => {
    return (
        <Grid container spacing={4} mt={1}>
            <Grid item xs={12} sm={12} md={12}>
                {/* 충전 현황 */}
                <TypographWithIcon
                    sx={{ padding: "0 10px" }}
                    variant="h3"
                    icon={<ChargingStationIcon color="disabled" fontSize="large" />}
                    text={"충전 현황"}
                />
                <Box sx={{ backgroundColor: "#dfdfdf", borderRadius: "10px", minHeight: "250px" }}></Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                {/* 지역별 현황 */}
                <TypographWithIcon
                    sx={{ padding: "0 10px" }}
                    variant="h3"
                    icon={<MapIcon color="disabled" fontSize="large" />}
                    text={"지역별 현황"}
                />
                <Box sx={{ backgroundColor: "#dfdfdf", borderRadius: "10px", minHeight: "250px" }}></Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                {/* 충전속도 및 CPO별 현황 */}
                <TypographWithIcon
                    sx={{ padding: "0 10px" }}
                    variant="h3"
                    icon={<EvStationIcon color="disabled" fontSize="large" />}
                    text={"충전속도 및 CPO별 현황"}
                />
                <Box sx={{ backgroundColor: "#dfdfdf", borderRadius: "10px", minHeight: "250px" }}></Box>
            </Grid>
        </Grid>
    );
};

export default CorpAdminDashboard;
