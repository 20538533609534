// TODO, API로 date 정보 저장 시 formatting, parsing이 필요하면 사용예정
// https://marmelab.com/react-admin/Inputs.html#transforming-input-value-tofrom-record
// parse(): input -> record
// format(): record -> input

import format from "date-fns/format";

const convertDateToString = (value: Date | string) => {
    // value is a `Date` object
    if (!(value instanceof Date) || isNaN(value.getDate())) return "";
    const pad = "00";
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

export const dateFormatter = (value: Date | string) => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === "") return "";
    if (value instanceof Date) return convertDateToString(value);
    // Valid dates should not be converted
    if (dateFormatRegex.test(value)) return value;

    return convertDateToString(new Date(value));
};

export const dateParser = (value: string) => {
    //value is a string of "YYYY-MM-DD" format
    const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;
    const match = dateParseRegex.exec(value);
    if (match === null) return;
    const d = new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
    if (isNaN(d.getDate())) return;
    return d;
};

export const isValidDate = (date: Date) => {
    return date instanceof Date && !isNaN(date.getTime());
};

export const convertTimestampToString = (timestampNumber: string) => {
    if (timestampNumber == "" || timestampNumber == null) {
        return;
    } else {
        return format(new Date(timestampNumber), "yyyy-MM-dd hh:mm:ss");
    }
};

export const convertMsToProgressTime = (ms: number) => {
    const secs = Math.floor(ms / 1000);
    let mins = Math.floor(secs / 60);
    let hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);

    mins = mins % 60;
    hours = hours % 24;

    let ret = "";
    if (days > 0) {
        ret = `${days}일` + ret;
    }
    if (hours > 0) {
        ret = ret + ` ${hours}시간`;
    }
    if (mins > 0) {
        ret = ret + ` ${mins}분`;
    }
    return ret;
};
