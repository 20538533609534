import { CommonCodeDesc } from "@src/types";

export const getCommonCode = (c: string | CommonCodeDesc) => {
    return typeof c === "string" ? c : c.code;
};

/*
    create_userid, update_userid, delete_userid 는 "id(name)"" 형태로 저장됨
    name값만 반환하는 유틸 함수
*/
export const getUserName = (userid: string) => {
    if (userid.indexOf("(") === -1) return userid;

    return userid.substring(userid.indexOf("(") + 1, userid.length - 1);
};

export const ___debug = (...logs: any) => {
    if (process.env.NODE_ENV !== "production") {
        logs.forEach((log: any) => {
            console.log(`[DEBUG] `, log);
        });
    }
};

export const removeHtmlTags = (description: string) => {
    const reg = /<[^>]*>?/g;
    return description.replace(reg, "");
};
