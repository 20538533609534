import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSidebarState, useTranslate } from "react-admin";
import { SubMenuProps } from "./types";

const SubMenu = ({ handleMenuToggle, isOpen, name, icon, children, dense }: SubMenuProps) => {
    const translate = useTranslate();
    const [sidebarIsOpen] = useSidebarState();

    const header = (
        <MenuItem dense={dense} onClick={() => handleMenuToggle(name)}>
            <ListItemIcon>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {translate(`title.${name}`)}
            </Typography>
        </MenuItem>
    );

    return (
        <>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={translate(`title.${name}`)} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        "& a": {
                            transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                        "& li": {
                            transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                            paddingLeft: sidebarIsOpen ? "36px" : 2,
                        },
                    }}
                >
                    {children}
                </List>
            </Collapse>
        </>
    );
};

export default SubMenu;
