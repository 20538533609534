import * as React from "react";
import { SimplePaletteColorOptions, Typography } from "@mui/material";
import { Link, RichTextField, useRecordContext, useTheme } from "react-admin";
import LaunchIcon from "@mui/icons-material/Launch";

const LinkField = ({ source, link = "/" }: any) => {
    // TODO, prop으로 to 추가, Link to로 전달
    return (
        <Link to={link}>
            <Typography variant="button">
                <RichTextField source={source} />
                <LaunchIcon fontSize="inherit" />
            </Typography>
        </Link>
    );
};

type ExternalLinkFieldProps = { source: string; textAlign: string; label: string; sortable: boolean };

const ExternalLinkField = ({ source, textAlign, label, sortable }: Partial<ExternalLinkFieldProps>) => {
    const record = useRecordContext();
    const [theme] = useTheme();

    const linkColor = theme ? (theme?.palette?.primary as SimplePaletteColorOptions).main : "#115CA0";

    return (
        <a target="_blank" href={record.link} style={{ textDecoration: "none", color: linkColor }}>
            <Typography variant="button">
                <RichTextField source={source} />
                <LaunchIcon fontSize="inherit" />
            </Typography>
        </a>
    );
};

export { LinkField, ExternalLinkField };
