import { Create, ListButton, TopToolbar, useGetIdentity } from "react-admin";
import { CorpAdminQnaInputs } from "./CorpAdminQnaInputs";

const CreateActions = ({ basePath, data }: any) => (
    <TopToolbar>
        <ListButton />
        {/* <ShowButton basePath={basePath} record={data} label={'ra.action.show'} /> */}
    </TopToolbar>
);

export const CorpAdminQnaCreate = () => {
    const { isLoading, identity } = useGetIdentity();

    if (isLoading) return null;

    return (
        <Create title={"title.customer_qna"} actions={<CreateActions />}>
            <CorpAdminQnaInputs type="create" />
        </Create>
    );
};
