import { ChartBox } from "@src/components/charts/ChartBox";
import BuildIcon from "@mui/icons-material/Build";
import { PieChart } from "@src/components/charts/PieChart";
import { ChartTooltip } from "@src/components/charts/ChartTooltip";
import { useEffect, useRef, useState } from "react";
import { useDataProvider } from "react-admin";
import { CircularProgress } from "@mui/material";

export const ChargerStatusChart = () => {
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);
    const total = useRef<number>(0);

    useEffect(() => {
        const setChartData = async () => {
            const { data } = await dataProvider.callExternalApi("/analytics/chargerStatusChart", null, "GET");
            const items = data.map(({ charger_status, count }: any) => {
                total.current += count;
                return {
                    id: charger_status,
                    name: charger_status,
                    value: count,
                };
            });
            setData(items);
        };
        setChartData();
    }, []);

    return (
        <ChartBox
            titleIcon={<BuildIcon color="disabled" fontSize="large" />}
            titleText="ceo.charger_status"
            chart={
                data.length > 0 ? (
                    <PieChart
                        data={data}
                        keys={Object.keys(data)}
                        colors={["#97e3d5", "#f1e15b", "#f47560"]}
                        margin={{ top: 50, right: 80, bottom: 50, left: 80 }}
                        axisRight={{
                            format: (v: any) => `${Math.abs(v / 100000000)}억`,
                            tickValues: 8,
                        }}
                        tooltip={({ datum: { id, value, color } }: any) => (
                            <ChartTooltip id={id} color={color} content={`${(+value).toLocaleString()}대`} />
                        )}
                        arcLabel={(d: any) => {
                            const percent = Math.round((d.value / total.current) * 100);
                            return `${d.value.toLocaleString()} (${percent}%)`;
                        }}
                    />
                ) : (
                    <CircularProgress />
                )
            }
        />
    );
};
