import { ResponsivePie } from "@nivo/pie";
import { getNivoTheme } from "@src/layout/themes";
import { useTheme } from "react-admin";

export const PieChart = ({
    data,
    keys,
    colors = { scheme: "nivo" },
    margin = { top: 0, right: 20, bottom: 0, left: 20 },
    ...commonChartProps
}: any) => {
    const [theme] = useTheme();
    return (
        <ResponsivePie
            {...commonChartProps}
            data={data}
            theme={getNivoTheme(theme)}
            keys={keys}
            colors={colors}
            margin={margin}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
            }}
            sortByValue
            fit
            arcLinkLabelsDiagonalLength={12}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            legends={[
                {
                    anchor: "bottom",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: 30,
                    itemsSpacing: 0,
                    itemWidth: 90,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 18,
                    symbolShape: "circle",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemTextColor: theme?.palette?.mode === "dark" ? "#fff" : "#000",
                            },
                        },
                    ],
                },
            ]}
        />
    );
};
