import { TranslationMessages } from "react-admin";

// ko.ts 작성 후 en.ts 포팅 예정
interface TipTapEditorMessages {
    ra: {
        tiptap: {
            select_level: string;
            bold: string;
            Italic: string;
            underline: string;
            strike: string;
            code: string;
            align_left: string;
            align_right: string;
            align_center: string;
            align_justify: string;
            list_bulleted: string;
            list_numbered: string;
            link: string;
            blockquote: string;
            clear_format: string;
        };
    };
}

type KrTranslationMessages = TranslationMessages | TipTapEditorMessages;

// message 리소스 key값은 snake_case를 사용(CamelCase는 수정 예정)
const customKoreanMessages: KrTranslationMessages = {
    ra: {
        action: {
            add_filter: "필터 추가",
            add: "추가",
            back: "뒤로가기",
            bulk_actions: "%{smart_count}개가 선택됨",
            cancel: "취소",
            clear_input_value: "값 초기화",
            clone: "복사",
            confirm: "확인",
            create: "등록",
            create_item: "등록 %{item}",
            delete: "삭제",
            edit: "편집",
            export: "저장",
            list: "목록",
            refresh: "새로고침",
            remove_filter: "필터 제거",
            remove_all_filters: "모든 필터 제거",
            remove: "삭제",
            save: "저장",
            search: "검색",
            select_all: "모두 선택",
            select_row: "행 선택",
            show: "보기",
            sort: "정렬",
            undo: "이전으로",
            unselect: "선택해제",
            expand: "펼치기",
            close: "닫기",
            open_menu: "메뉴 열기",
            close_menu: "메뉴 닫기",
            update: "수정",
            move_up: "위로",
            move_down: "아래로",
            open: "열기",
            toggle_theme: "테마 변경",
        },
        boolean: {
            true: "네",
            false: "아니오",
            null: " ",
        },
        page: {
            create: "등록 %{name}",
            dashboard: "대시보드",
            edit: "%{name} #%{id}",
            error: "Something went wrong",
            list: "%{name}",
            loading: "로딩중",
            not_found: "Not Found",
            show: "%{name} #%{id}",
            empty: "%{name} 데이터가 아직 없습니다.",
            invite: "추가하시겠습니까?",
        },
        input: {
            file: {
                upload_several: "파일 업로드를 위해 클릭하거나 파일을 올려주세요",
                upload_single: "파일 업로드를 위해 클릭하거나 파일을 올려주세요",
            },
            image: {
                upload_several: "이미지 업로드를 위해 클릭하거나 이미지을 올려주세요",
                upload_single: "이미지 업로드를 위해 클릭하거나 이미지을 올려주세요",
            },
            references: {
                all_missing: "Unable to find references data.",
                many_missing: "At least one of the associated references no longer appears to be available.",
                single_missing: "Associated reference no longer appears to be available.",
            },
            password: {
                toggle_visible: "Hide password",
                toggle_hidden: "Show password",
            },
        },
        message: {
            about: "About",
            are_you_sure: "Are you sure?",
            bulk_delete_content: "%{smart_count}개의 항목을 삭제하시겠습니까?",
            bulk_delete_title: "%{smart_count}개 %{name} 삭제",
            bulk_update_content: "%{smart_count}개의 항목을 수정하시겠습니까?",
            bulk_update_title: "%{smart_count}개 %{name} 수정",
            delete_content: "해당 항목을 삭제하시겠습니까?",
            delete_title: "삭제",
            details: "Details",
            error: "A client error occurred and your request couldn't be completed.",
            invalid_form: "필수 입력 사항이 모두 입력되지 않았습니다",
            loading: "로딩중입니다. 잠시만 기다려주세요",
            no: "아니오",
            not_found: "페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.",
            yes: "네",
            unsaved_changes: "Some of your changes weren't saved. Are you sure you want to ignore them?",
        },
        navigation: {
            no_results: "조회된 결과가 없습니다",
            no_more_results: "The page number %{page} is out of boundaries. Try the previous page.",
            page_out_of_boundaries: "Page number %{page} out of boundaries",
            page_out_from_end: "Cannot go after last page",
            page_out_from_begin: "Cannot go before page 1",
            page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
            partial_page_range_info: "특정 페이지 부분 정보",
            page_rows_per_page: "페이지별 행 수:",
            current_page: "현재 페이지",
            page: "페이지",
            first: "첫 번째",
            last: "마지막",
            next: "다음",
            previous: "이전",
            skip_nav: "Skip to content",
        },
        sort: {
            sort_by: "%{order}으로 %{field} 정렬",
            ASC: "오름차순",
            DESC: "내림차순",
        },
        auth: {
            auth_check_error: "계속하시려면 로그인을 해주세요",
            user_menu: "프로파일",
            username: "아이디",
            password: "비밀번호",
            sign_in: "로그인",
            sign_in_error: "인증 실패, 다시 시도해주세요",
            logout: "로그아웃",
        },
        notification: {
            updated: "수정 완료",
            created: "등록 완료",
            deleted: "삭제 완료",
            bad_item: "잘못된 내용입니다",
            item_doesnt_exist: "해당 내용이 존재하지 않습니다",
            http_error: "Server communication error",
            data_provider_error: "dataProvider error. 자세한 내용은 콘솔창을 확인해주세요.",
            i18n_error: "Cannot load the translations for the specified language",
            canceled: "취소 완료",
            logged_out: "세션이 종료됐습니다. 재접속해주세요.",
            not_authorized: "접근 권한이 없습니다",
        },
        validation: {
            required: "필수 입력",
            minLength: "%{min}자 보다 많이 입력해야 합니다",
            maxLength: "%{max}자 보다 작게 입력해야 합니다",
            minValue: "최소값 %{min} 보다 큰 수여야 합니다",
            maxValue: "최대값 %{max} 보다 작은 수여야 합니다",
            number: "숫자만 입력 가능합니다",
            email: "이메일 형식이 올바르지 않습니다",
            oneOf: "Must be one of: %{options}",
            regex: "Must match a specific format (regexp): %{pattern}",
        },
        saved_queries: {
            label: "사용자지정 필터",
            query_name: "새 사용자지정 필터명",
            new_label: "사용자지정 필터 저장",
            new_dialog_title: "현재 사용자지정 필터 저장",
            remove_label: "사용자지정 필터 삭제",
            remove_label_with_name: "사용자지정 필터 삭제",
            remove_dialog_title: "사용자지정 필터 삭제",
            remove_message: "저장된 사용자 지정 필터를 삭제하시겠습니까?",
            help: "도움말",
        },
        tiptap: {
            select_level: "스타일",
            bold: "굵게",
            Italic: "기울임꼴",
            underline: "밑줄",
            strike: "취소선",
            code: "코드",
            align_left: "왼쪽 정렬",
            align_right: "오른쪽 정렬",
            align_center: "가운데 정렬",
            align_justify: "양쪽 정렬",
            list_bulleted: "글머리 목록",
            list_numbered: "번호 목록",
            link: "링크",
            blockquote: "인용 블록",
            clear_format: "포맷 초기화",
        },
    },
    authProvider: {
        check_auth: "인증에 실패했습니다. 유효한 인증 토큰이 아닙니다", // "Failed to obtain access token",
        getIdentity: "회원 정보 조회에 실패했습니다", //"Failed to get identity",
    },
    action: {
        go_home: "그룹웨어 홈으로 이동",
        go_voc: "VOC로 이동",
    },
    theme: {
        name: "Theme",
        light: "Light",
        dark: "Dark",
    },
    common: {
        idx: "번호",
        id: "아이디",
        password: "비밀번호",
        new_password: "새 비밀번호",
        name: "이름",
        account_number: "회원번호",
        employee_number: "사원번호",
        car_number: "차량번호",
        car_model: "차량모델",
        url: "URL",
        address: "주소",
        addressDetail: "상세주소",
        index: "번호",
        viewmore: "더보기",
        csvsave: "CSV 저장",
        writer: "작성자",
        content: "내용",
        title: "제목",
        contact: "연락처",
        date: "날짜",
        location: "지역",
        configuration: "설정",
        category: "분류",
        question: "질문",
        answer: "답변",
        attachments: "첨부 파일",
        contacts: "연락처",
        createAt: "등록일자",
        createUserId: "등록자",
        updateAt: "수정일자",
        updateUserId: "수정자",
        deleteAt: "삭제일자",
        deleteUserId: "삭제자",
        department: "부서",
        email: "이메일",
        voc: "VOC",
        deal: "최근거래내역",
        everon_id: "에버온 ID",
        external_email: "외부 이메일",
        jobRank: "직위",
        phone: "휴대 전화번호",
        tel_number: "전화번호",
        office_tel_number: "사무실 전화번호",
        individual_tel_number: "개인 전화번호",
        individual_home_tel_number: "개인 집 전화번호",
        fax_tel_number: "팩스번호",
        bigo_note: "비고",
        due: "마감기한",
        ago: "전 ",
        description: "설명",
        type: "구분",
        gender: "성별",
        date_of_birth: "생년월일",
        content_info: "작성 정보",
        attachImage: "이미지 첨부",
        customerInfo: "고객 정보",
        stash: "보관",
        unstash: "보관 해제",
        stashList: "보관 목록",
        fast_charger: "급속 충전기",
        slow_charger: "완속 충전기",
        manufacturer: "제조사",
        sum: "합계",
        show_details: "상세보기",
        claim_date: "청구년월",
        bills_address: "청구지주소",
        bills_detail_address: "청구지 상세주소",
        bills_email: "청구 이메일",
        usage_kw: "사용량(kW)",
        supply_price_won: "공급가액(원)",
        claim_fee_won: "청구요금(원)",
        charge_fee: "충전금액",
        charge_fee_won: "충전금액(원)",
        usage_fee: "이용요금",
        usage_fee_won: "이용요금(원)",
        real_fee: "실부과금액",
        vat_won: "부가세(원)",
        bigo: "비고",
        pay: "결제하기",
        payment_type: "결제유형",
        payment_method: "결제방법",
        print_recipt: "영수증 출력",
        roaming_corp: "로밍사",
        account_card: "회원 카드",
        reservation_number: "예약 번호",
        station_name: "충전소명",
        station_id: "충전소 ID",
        payment_date_or_deposit_date: "결제일/입금일",
        payment_date: "결제일시",
        charge_date: "충전일시",
        usage_date: "사용일시",
        reservation_date: "예약일시",
        charge_amount: "충전량",
        charge_amount_kw: "충전량(kW)",
        charge_time: "충전 시간",
        charge_time_total: "총 충전시간",
        usage_time_total: "충 사용시간",
        reservation_time_total: "충 사용시간",
        status: "상태",
        cancellation_fee: "취소 수수료",
        payment_card: "결제 카드",
        create_at: "등록일",
        create_userid: "등록자",
        basic_info: "기본 정보",
        corp_name: "법인명",
        corp_ceo_name: "법인명",
        corp_number: "사업자등록번호",
        corp_sector: "업종",
        corp_sector2: "업태",
        manager_name: "담당자명",
        manager_tel_number: "담당자 연락처",
        manager_phone: "담당자 핸드폰",
        manager_email: "담당자 이메일",
        sms_yn: "SMS 수신여부",
        email_yn: "이메일 수신여부",
        payment_yn: "결제여부",
        cancellation_fee_free_yn: "회원번호 수정가능여부",
        account_number_edit_yn: "회원번호 수정가능여부",
        edit_password: "비밀번호 수정",
        payment_info: "결제 정보",
        card_info: "카드 정보",
        separate_adjust_payment_method: "별도정산 납부방법",
    },
    commonCode: {
        noteStatus: {
            crmContact: {
                STATUS_GOOD: "우호적",
                STATUS_BAD: "냉소적",
                STATUS_DECENT: "보통",
                NONE: "",
            },
        },
    },
    validation: {
        tel_number: "올바른 전화번호 형태가 아닙니다.",
        office_tel_number: "올바른 전화번호 형태가 아닙니다.",
        fax_tel_number: "올바른 팩스번호 형태가 아닙니다.",
    },
    helper_text: {
        kakao_address: "주소를 클릭해서 설정해주세요",
        enter_new_password: "변경할 새 비밀번호를 입력해주세요",
    },
    warning: {
        checkFileSize: "파일의 크기를 확인해주세요(최대 %{smart_count}MB)",
    },
    filter: {
        lastSeen: "마지막 응대일",
        today: "오늘",
        thisWeek: "이번 주",
        beforeThisWeek: "이번 주 이전",
        beforeThisMonth: "이번 달 이전",
        beforeLastMonth: "지난 달 이전",
    },
    notification: {
        auth_failed: "유저 인증에 실패했습니다",
        stash: "선택한 항목을 보관했습니다.",
        unstash: "선택한 항목을 보관 해제했습니다.",
        unstashFail: "보관 해제를 실패했습니다",
        multiUpdated: "%{smart_count}개 수정 완료",
        multiDeleted: "%{smart_count}개 삭제 완료",
        account_mgmt: {
            succeed_in_adding_member: "새로운 멤버로 등록됐습니다",
            succeed_in_deleting_member: "멤버가 삭제됐습니다",
            everonId_has_at: "도메인(@everon.co.kr)을 제외한 ID만 입력해주세요",
            empty_id: "ID를 입력해주세요",
            id_exists: "이미 등록된 ID입니다",
            id_maxLength: "ID는 20자를 넘을 수 없습니다",
            not_added_id: "등록이 안된 ID입니다",

            fail_to_select_dooray_ids: "Dooray 계정 정보 조회에 실패했습니다",
            fail_to_select_dooray_depts: "Dooray 부서 정보 조회에 실패했습니다",
            fail_to_select_dooray_jobranks: "Dooray 직위 정보 조회에 실패했습니다",

            fail_to_add_keycloak_id: "Keycloak 계정 등록에 실패했습니다. IT센터에 문의해주세요",
            fail_to_add_dooray_id: "Dooray 계정 등록에 실패했습니다. IT센터에 문의해주세요",
            fail_to_add_dooray_dept: "Dooray 부서 등록에 실패했습니다. IT센터에 문의해주세요",

            fail_to_delete_keycloak_id: "Keycloak 계정 삭제에 실패했습니다. IT센터에 문의해주세요",
            fail_to_delete_dooray_id: "Dooray 계정 삭제에 실패했습니다. IT센터에 문의해주세요",
        },
        dooray: {
            fail_to_select_emails: "이메일 조회에 실패했습니다",
            empty_mails: "메일이 없습니다",
        },
    },
    title: {
        sites: "관련 사이트",
        groupware: "그룹웨어",
        groupware_approval: "전자결재",
        groupware_notice_board: "공지사항",
        groupware_email: "이메일",
        groupware_calendar: "캘린더",
        groupware_meeting: "화상회의",
        groupware_chatting: "채팅",
        groupware_contact: "주소록",
        groupware_register_month: "근무관리",
        crm: "CRM",
        crm_client: "거래처 관리",
        crm_contact: "연락처 관리",
        crm_deal: "거래 관리",
        customer: "회원정보",
        customer_account: "회원 목록",
        customer_accountDetail: "회원 상세정보",
        customer_corporation: "법인 목록",
        customer_card: "카드 관리",
        customer_notice: "공지사항",
        customer_qna: "QnA",
        customer_faq: "FAQ",
        customer_oneOnOne: "1:1 문의",
        repair: "고장처리",
        call_center: "콜센터",
        commission: "위탁관리",
        calculation: "정산관리",
        info_retrieval: "인터넷 정보",
        info_retrieval_bookmark: "인터넷 정보 북마크",
        ceo: "경영 정보",
        ceo_screen: "CEO 대시보드",
        ceo_news: "인터넷 정보",
        ceo_monitorOverall: "운영 실적",
        ceo_monitorAccounts: "회원 증감현황",
        ceo_monitorChargers: "충전기 주간별 통계",
        ceo_monitorKepcoUsage: "한국전력 월별 통계",
        ceo_monitorOtherAccountUsage: "타사회원 에버온 충전기 월별 사용량",
        ceo_monitorEveronAccountUsage: "에버온회원 타사 충전기 월별 사용량",
        ceo_monitorCorpAccountUsage: "법인회원 월별 사용량",
        law: "법령 정보",
        control_cp: "관제관리",
        control_cp_status: "충전기 상태",
        control_cp_reservation: "예약 현황",
        control_cp_cp: "충전소 관리",
        control_cp_charger: "충전기 관리",
        control_cp_chargerCorp: "충전기 소유회사 관리",
        control_cp_roamingCharger: "로밍 충전기 관리",
        control_cp_kepcoCharger: "한전 충전기 관리",
        control_cp_close: "마감 관리",
        system: "시스템 관리",
        system_account_mgmt: "에버온 멤버 관리",
        voc: "VOC",
        voc_list: "VOC 관리",
        vocDoneList: "VOC 완료 목록",
        dooray_add_member: "멤버 등록",
        dooray_delete_member: "멤버 제거",
        service_mgmt: "운영 관리",
        corp_admin_mgmt_notice: "법인관리자 공지사항",
        corp_admin_mgmt_qna: "법인관리자 Q&A",

        corp_admin_notice: "공지사항",
        corp_admin_qna: "Q&A",
        corp_admin_accounts: "회원관리",
        corp_admin_approval: "승인요청회원",
        corp_admin_corp_info: "법인 정보",
        corp_admin_accounts_list: "회원 목록",
        corp_admin_cards_list: "회원카드 목록",
        corp_admin_bills: "정산관리",
        corp_admin_bills_now: "청구현황",
        corp_admin_bills_detail: "청구현황 상세이용내역",

        corp_admin_account_payment: "%{name}님의 이용/결제내역",
    },
    dashboard: {
        new_vocs: "새로 등록된 VOC",
        new_contacts: "새로 등록된 연락처",
        new_deals: "새로 등록된 거래",
        deal_statistic: "월별 거래 현황",
        charge_amounts_statistic: "월별 충전량",
    },
    info_retrieval: {
        bookmark: "인터넷 정보 북마크",
        types: {
            news: "뉴스",
            blog: "블로그",
            cafe: "카페",
            law: "법령",
            admrul: "행정규칙",
            ordin: "자치법규",
            prec: "판례",
            decc: "행정심판례",
            detc: "헌재결정례",
        },
    },
    service_mgmt: {
        corp_admin_mgmt: {
            add_notice: "공지사항 등록",
            edit_notice: "공지사항 수정",
        },
    },
    system: {},
    corp_admin: {
        approval_date: "승인일",
        approval_request_date: "회원요청일",
        payments_history: "이용/결제내역",
        approve: "승인",
        reject: "거부",
        charge_history_details: "상세 충전 내역",
    },
    ceo: {
        monthly_charge_amount: "월별 충전량",
        income_chart: "월별 충전 금액",
        account_chart: "월별 회원 현황",
        charger_manufacturer_list: "제조사별 충전기",
        charger_quantity: "월별 충전기 수",
        charger_status: "충전기 상태",
        corp_charge_amount: "월별 법인별 충전량",
    },
    voc: {
        addVoc: "VOC 등록",
        editVoc: "VOC 수정",
        deleteVoc: "VOC 삭제",
        inboundAt: "문의일자",
        inboundContact: "인바운드연락처",
        vocStatus: "처리단계",
        requestTitle: "요청명",
        customerType: "고객 유형",
        customerName: "고객 이름",
        inboundChannel1: "인입 경로",
        inboundChannel2: "인입 채널",
        customerInboundCnt: "문의 횟수",
        questionContent: "문의정보",
        requestCategory1: "문의 대분류",
        requestCategory2: "문의 중분류",
        requestCategory3: "문의 소분류",
        requestDescription: "상세문의내용",
        responseDepartment: "담당부서",
        vocCardList: "처리중 목록",
        responseCompleteAt: "처리 완료일자",
        responseTimeToComplete: "처리 시간",
    },
    crm: {
        addContact: "연락처 등록",
        addDeal: "거래 등록",
        background: "배경 정보",
        deals: "거래",
        lastActivity: "마지막 활동 ",
        editContact: "연락처 수정",
        showContact: "연락처 보기",
        stashDeal: "거래 보관",
        unstashDeal: "거래 보관 해제",
        editDeal: "거래 수정",
        deleteDeal: "거래 삭제",
        personalInfo: "개인 정보",
        job_title: "직급/직함",
        clientName: "거래처명",
        clientType: "거래처유형",
        clientInfo: "거래처 정보",
        addClient: "거래처 등록",
        editClient: "거래처 수정",
        showClient: "거래처 보기",
        addLogo: "로고 첨부",
        addProfile: "프로필 첨부",
        seeOnlyMine: "내가 등록한 거래만 보기",
        start: "시작",
        budget: "예산",
        crmStatus: "단계",
        dealName: "거래명",
        dealCardList: "거래중 목록",
        addNote: "노트 등록",
        editNote: "노트 수정",
        deleteNote: "노트 삭제",
        emptyContacts: "등록된 연락처가 없습니다.",
        last_emotion_status: "마지막 노트 상태",
    },
    news: {
        news_feed: "뉴스",
        blog_feed: "블로그",
        cafe_feed: "카페",
        source: "출처",
        originallink: "원본 URL",
    },
    law: {
        law: {
            name: "법령",
            col1: "구분",
            col2: "소관부처",
            title: "법령",
        },
        admrul: {
            name: "행정규칙",
            col1: "행정규칙일련번호",
            col2: "소관부처",
            title: "행정규칙",
        },
        ordin: {
            name: "자치법규",
            col1: "구분",
            col2: "지자체기관",
            title: "자치법규",
        },
        prec: {
            name: "판례",
            col1: "사건종류",
            col2: "법원명",
            title: "사건명",
        },
        detc: {
            name: "헌재결정례",
            col1: "종국일자",
            col2: "사건번호",
            title: "사건명",
        },
        decc: {
            name: "행정심판례",
            col1: "재결청",
            col2: "재결구분",
            title: "사건명",
        },
    },
    controlCp: {
        locationInfo: "위치 정보",
        operationInfo: "운영 정보",
        cpName: "충전소명",
        chargerID: "충전기 ID",
        terminalNumber: "단말 번호",
        chargerStatus: "충전기이상상태",
        channel: "통신 채널",
        cableStatus: "케이블 상태",
        btnStatus: "버튼 상태",
        cardStatus: "카드 상태",
        receivingDate: "수집 날짜",
        manufacturer: "제조사",
        useYN: "사용여부",
        contractYN: "계약여부",
        kind: "구분",
        sido: "지역(시, 도)",
        gugun: "지역(구, 군)",
        dongeup: "지역(동, 면)",
        reservationStatus: "예약상태",
        reservationNumber: "예약번호",
        reservationDateFrom: "예약 일(From)",
        reservationDateTo: "예약 일(To)",
    },
    customer: {
        vehicleInfo: "차량정보",
        paymentInfo: "결제정보",
        cardNumber: "카드번호",
        accountLevel: "회원등급",
        usageLevel: "이용등급",
        accountType: "회원구분",
        dateOfSignUp: "가입일",
        answerYN: "답변여부",
        answered: "답변 완료",
        waiting: "답변 대기중",
        carModel: "차량 종류",
        carNumber: "차량 번호",
        paymentCard: "이용 정보",
        paymentCorp: "결제카드 회사명",
        dateOfRegistCard: "카드 등록일",
        dateOfAuthenticated: "본인 인증일",
        smsYN: "SMS 수신",
        emailYN: "이메일 수신",
        accountMemo: "회원 비고",
        accountMemoType: "회원 비고 등급",
        relegationYN: "관리자 강등여부",
        dept: "부서명",
    },
};

export default customKoreanMessages;
