import * as React from "react";
import { Link, Box, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useRecordContext, useGetList, useTranslate } from "react-admin";

export const ContactList = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    const { data, isLoading } = useGetList("crm/contact", {
        filter: { client_id: record.client_id },
    });

    if (isLoading || !data) return null;

    if (data.length === 0) {
        return (
            <Typography color="textSecondary" variant="subtitle1">
                {translate("crm.emptyContacts")}
            </Typography>
        );
    }

    return (
        <Box
            component="ul"
            sx={{
                listStyle: "none",
                padding: 0,
                margin: 0,
                display: "inline-block",
            }}
        >
            {data.map(contact => (
                <Box
                    component="li"
                    key={contact.id}
                    sx={{
                        display: "inline",
                        "&:after": {
                            content: '", "',
                        },
                        "&:last-child:after": {
                            content: '""',
                        },
                    }}
                >
                    <Link component={RouterLink} to={`/crm/contact/${contact.id}/show`} variant="subtitle1">
                        {contact.contact_name}
                    </Link>
                </Box>
            ))}
        </Box>
    );
};
