import React from "react";
import { FunctionField, ReferenceField, TextField, useRecordContext, useRedirect, useTranslate } from "react-admin";
import { Card, Box, Typography, useMediaQuery, Theme } from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { ClientAvatar } from "../clients/ClientAvatar";
import { CommonCodeDesc } from "@src/types";

export const DealCard = ({ index = 0 }: { index?: number }) => {
    const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
    const record = useRecordContext();
    const redirect = useRedirect();

    if (!record) return null;

    const handleClick = () => {
        redirect(`/crm/deal/${record.id}/show`);
    };
    return (
        <Draggable draggableId={String(record.id)} index={index}>
            {(provided, snapshot) => (
                <Box
                    sx={{ marginBottom: 1 }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    onClick={handleClick}
                >
                    <Card
                        style={{
                            opacity: snapshot.isDragging ? 0.9 : 1,
                            transform: snapshot.isDragging ? "rotate(-2deg)" : "",
                        }}
                        elevation={snapshot.isDragging ? 3 : 1}
                    >
                        <Box
                            padding={1}
                            display="flex"
                            flexDirection={isDesktop ? "row" : "column"}
                            alignItems={isDesktop ? "flex-start" : "center"}
                        >
                            <ReferenceField source="client_id" reference="crm/client" link={false}>
                                <ClientAvatar />
                            </ReferenceField>
                            <Box sx={{ marginLeft: 1 }}>
                                <Box>
                                    <Typography variant="body2" gutterBottom>
                                        <TextField source="deal_title" />
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="textSecondary">
                                        <ReferenceField source="client_id" reference="crm/client" link={false}>
                                            <TextField source="client_name" />
                                        </ReferenceField>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="textSecondary">
                                        <ReferenceField source="client_id" reference="crm/client" link={false}>
                                            <FunctionField
                                                source="client_type"
                                                render={({ client_type }: { client_type: CommonCodeDesc }) => {
                                                    return client_type.codeName;
                                                }}
                                            />
                                        </ReferenceField>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption" color="textSecondary">
                                        {Number(record.budget).toLocaleString("ko-KR", {
                                            notation: "compact",
                                            style: "currency",
                                            currency: "KRW",
                                            currencyDisplay: "narrowSymbol",
                                            minimumSignificantDigits: 3,
                                        })}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            )}
        </Draggable>
    );
};
