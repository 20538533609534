import React from "react";
import { Box, Typography } from "@mui/material";
import { Droppable } from "react-beautiful-dnd";
import { Identifier, RecordContextProvider, useTheme } from "react-admin";
import { RecordMap } from "./types";

export const CardColumn = ({
    title,
    status,
    ids,
    data,
    card,
    setShowInfo,
}: {
    title: string;
    status: string;
    ids: Identifier[];
    data: RecordMap;
    card: JSX.Element;
    setShowInfo?: any;
}) => {
    const [theme] = useTheme();

    return (
        <Box
            sx={{
                flex: 1,
                paddingTop: "8px",
                paddingBottom: "16px",
                bgcolor: theme?.palette?.mode === "dark" ? "#373737" : "#eaeaee",
                padding: "5px",
                borderRadius: 1,
                minHeight: "400px",
                marginRight: "10px",
                "&:last-child": {
                    marginRight: "0",
                },
            }}
        >
            <Typography align="center" variant="subtitle1">
                {`${title.substring(title.lastIndexOf(".") + 1)}`}
            </Typography>
            <Droppable droppableId={status}>
                {(droppableProvided, snapshot) => (
                    <Box
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                        className={snapshot.isDraggingOver ? " isDraggingOver" : ""}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: 1.5,
                            padding: "5px",
                            "&.isDraggingOver": {
                                bgcolor: "#dadadf",
                            },
                            height: "90%",
                        }}
                    >
                        {ids.map((id, index) => (
                            <RecordContextProvider key={id} value={data[id]}>
                                {React.cloneElement(card, { index, setShowInfo })}
                            </RecordContextProvider>
                        ))}
                        {droppableProvided.placeholder}
                    </Box>
                )}
            </Droppable>
        </Box>
    );
};
