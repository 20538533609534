import { Datagrid, ListContextProvider, TextField, useDataProvider, useList, useTranslate } from "react-admin";
import { Box, Card, CardContent, Link } from "@mui/material";
import FactoryIcon from "@mui/icons-material/Factory";
import { useEffect, useState } from "react";
import { SouthKoreaMap } from "@src/components/map/SVGSouthKoreaMap";

const sidoCodeMap: any = {
    "11": "서울특별시",
    "26": "부산광역시",
    "27": "대구광역시",
    "28": "인천광역시",
    "29": "광주광역시",
    "30": "대전광역시",
    "31": "울산광역시",
    "36": "세종특별자치시",
    "41": "경기도",
    "42": "강원도",
    "43": "충청북도",
    "44": "충청남도",
    "45": "전라북도",
    "46": "전라남도",
    "47": "경상북도",
    "48": "경상남도",
    "50": "제주특별자치도",
};

const ManufacturerLocaleSummaryPanel = ({ record, data }: any) => {
    const filtered = data.filter((item: any) => record.manufacturer === item.manufacturer);
    const sorted = filtered.sort((a: any, b: any) => {
        if (a.count > b.count) {
            return -1;
        }
        if (a.count < b.count) {
            return 1;
        }
        return 0;
    });
    const listContext = useList({ data: sorted });

    return (
        <Box display="flex">
            <ListContextProvider value={listContext}>
                <Datagrid
                    sx={{
                        padding: "0",
                        minWidth: "250px",
                        // backgroundColor: "orange",
                        // "& .RaDatagrid-headerCell": {
                        //     backgroundColor: "#f2f2f4",
                        // },
                    }}
                    rowClick=""
                    bulkActionButtons={false}
                >
                    <TextField label="common.location" source="locale" sortable={false} textAlign="center" />
                    <TextField label="common.sum" source="count" sortable={false} textAlign="center" />
                </Datagrid>
            </ListContextProvider>
            <SouthKoreaMap data={sorted} />
        </Box>
    );
};

export const ChargerManufacturerList = () => {
    const translate = useTranslate();

    const dataProvider = useDataProvider();
    const [data, setData] = useState<any[]>([]);
    const [childData, setChildData] = useState<any[]>([]);

    useEffect(() => {
        const setChartData = async () => {
            const { data } = await dataProvider.callExternalApi("/analytics/chargerManufacturerList", null, "GET");
            const accByLocale = data.reduce(
                (acc: any, item: any) => {
                    const locale = sidoCodeMap[item.adminCode] ? sidoCodeMap[item.adminCode] : "알 수 없음";
                    return {
                        ...acc,
                        ["모든 제조사"]: {
                            ...acc["모든 제조사"],
                            [locale]: acc["모든 제조사"][locale] ? acc["모든 제조사"][locale] + item.count : item.count,
                        },
                        [item.maker]: {
                            ...acc[item.maker],
                            [locale]: item.count,
                        },
                    };
                },
                { "모든 제조사": {} },
            );
            const parentData = Object.keys(accByLocale)
                .map((manufacturer: string) => {
                    return Object.keys(accByLocale[manufacturer]).reduce((acc: any, locale) => {
                        let count: any = accByLocale[manufacturer][locale];
                        if (acc.count) {
                            count = acc.count + count;
                        }
                        return {
                            ...acc,
                            manufacturer,
                            count,
                        };
                    }, {});
                })
                .sort((a: any, b: any) => {
                    if (a.count > b.count) {
                        return -1;
                    }
                    if (a.count < b.count) {
                        return 1;
                    }
                    return 0;
                });
            setData(parentData);

            const childItems: any = [];
            Object.keys(accByLocale).forEach((manufacturer: any) => {
                Object.keys(accByLocale[manufacturer]).forEach(locale => {
                    childItems.push({
                        manufacturer,
                        locale,
                        count: accByLocale[manufacturer][locale],
                    });
                });
            });
            setChildData(childItems);
        };
        setChartData();
    }, []);
    const listContext = useList({ data });

    return (
        <>
            <Box display="flex" alignItems="center">
                <Box ml={2} mr={1} display="flex">
                    <FactoryIcon color="disabled" fontSize="large" />
                </Box>
                <Link underline="none" variant="h5" color="textPrimary">
                    {translate("ceo.charger_manufacturer_list")}
                </Link>
            </Box>
            <Box mt={2} height="auto" width="99%">
                <Card>
                    <CardContent>
                        <ListContextProvider value={listContext}>
                            <Datagrid
                                // sx={{
                                //     // backgroundColor: "#f2f2f4",
                                //     "& .RaDatagrid-expandedPanel": {
                                //         backgroundColor: "#f2f2f4",
                                //     },
                                // }}
                                rowClick="expand"
                                bulkActionButtons={false}
                                expand={<ManufacturerLocaleSummaryPanel data={childData} />}
                            >
                                {/* <TextField label="common.index" source="id" sortable={false} textAlign="center" /> */}
                                <TextField
                                    label="common.manufacturer"
                                    source="manufacturer"
                                    sortable={false}
                                    textAlign="center"
                                />
                                {/* <TextField
                                    label="common.slow_charger"
                                    source="slow_charger"
                                    sortable={false}
                                    textAlign="center"
                                />
                                <TextField
                                    label="common.fast_charger"
                                    source="fast_charger"
                                    sortable={false}
                                    textAlign="center"
                                />
                                */}
                                <TextField label="common.sum" source="count" sortable={false} textAlign="center" />
                            </Datagrid>
                        </ListContextProvider>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};
